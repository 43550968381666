<template>
  <div class="baseContainer"></div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { message, notification } from "ant-design-vue";
import * as utils from "@/utils";

export default {
  name: "SandboxLoginView",
  data() {
    return {
      loadingKey: "loggingIn",
    };
  },
  computed: {
    ...mapGetters(["appToken", "userToken"]),
  },
  methods: {
    ...mapActions([
      "setUserToken",
      "setUserTokenExpireDate",
      "setUserTokenWithExpireDate",
      "setLastLoginEmail",
      "setLoginEmail",
      "setAppTokens",
      "setLastApp",
    ]),
    sandboxlogin: function () {
      const url = process.env.VUE_APP_API + "/sandboxlogin";
      const hide = message.loading({
        content: "Signing in...",
        key: this.loadingKey,
      });
      axios
        .post(url)
        .then((res) => {
          hide();
          let error = res.data.error;
          let result = res.data.result;
          console.log("login result");
          if (error) {
            console.log("error: " + error);
            notification.error({
              message: "Login Failed",
              description: error,
            });
            // show error!!
          } else if (result) {
            console.log("email: " + result.email);
            console.log("user_token: " + result.user_token);
            const ms = new Number(result.expireDateMS);
            const date = new Date(ms);
            console.log("expireDateMS: " + date.toString());
            if (utils.isEmpty(result.apps)) {
              console.log("no apps!!");
              // show error!!
              notification.error({
                message: "Login Failed",
                description: "no apps!!",
              });
            } else {
              for (let app in result.apps) {
                console.log(app + ": " + result.apps[app]);
              }
              this.setUserTokenWithExpireDate({
                token: result.user_token,
                expireDate: date,
              });
              this.setLoginEmail(result.email);
              if (this.rememberEmail) {
                this.setLastLoginEmail(result.email);
              }
              this.setAppTokens(result.apps);
              if (!this.appToken) {
                let app_name = Object.keys(result.apps)[0];
                this.setLastApp(app_name);
              }
              this.$router.push({
                path: "/",
                name: "BackupView",
              });
            }
          }
        })
        .catch((err) => {
          hide();
          console.error(err);
        });
    },
  },
  mounted: function () {
    message.config({
      top: "40px",
      maxCount: 3,
    });
    this.sandboxlogin();
  },
};
</script>

<style lang="scss" scoped>
.baseContainer {
  background-color: #f8f8f8;
  width: 100vw;
  height: 100vh;
}
</style>
