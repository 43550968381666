import * as utils from "@/utils";
import axios from "axios";
import $root from "./backup";

// export function test_protobuf(url, callback) {
//   fetch(url).then((response) => {
//     response.arrayBuffer().then((arrayBuffer) => {
//       var buffer = new Uint8Array(arrayBuffer);
//       try {
//         var message = $root.BackupEditingData.decode(buffer);
//         var error = $root.BackupDrawData.verify(message);
//         if (error) console.log(error);
//         var object = $root.BackupEditingData.toObject(message);
//         console.log(utils.my_dump(object, "object", false));
//         callback(object, null);
//       } catch (e) {
//         // wire format is invalid
//         console.log("decode object -> e: " + e);
//         callback(null, err);
//       }
//     });
//   });
// }

export async function fetch_editing(url) {
  const debug = false;
  const result = await axios.get(url, { responseType: "arraybuffer" });
  const buffer = new Uint8Array(result.data);
  const message = $root.BackupEditingData.decode(buffer);
  var error = $root.BackupDrawData.verify(message);
  if (error) {
    throw error;
  }
  const object = $root.BackupEditingData.toObject(message);
  if (debug) {
    if (object.pathLineDataArray) {
      console.log(
        utils.my_dump(
          object.pathLineDataArray,
          "object.pathLineDataArray",
          false
        )
      );
    }
    if (object.texts) {
      console.log(utils.my_dump(object.texts, "object.texts", false));
    }
    if (object.signatures) {
      console.log(utils.my_dump(object.signatures, "object.signatures", false));
    }
  }
  return object;
}
