<template>
  <div v-if="backupId" class="base-container" ref="baseContainer">
    <section class="navSection">
      <template v-if="folderName">
        <a-button
          type="text"
          size="large"
          style="color: #959595; padding: 0; margin: 0; border: none"
          @click="gotoRoot"
          >{{ backupTitle }}</a-button
        >
        &nbsp;&nbsp;/&nbsp;&nbsp;<b>{{ folderName }}</b>
      </template>
      <template v-else>
        <b>{{ backupTitle }}</b>
      </template>
    </section>
    <div class="columns is-mobile is-multiline">
      <template v-for="doc in docList" :key="doc.uuid">
        <a-card hoverable class="docCard" @click="selectDoc(doc)">
          <template #cover>
            <v-lazy-image
              :src="doc.type ? docThumbnail(doc) : folderIcon(doc)"
              :src-placeholder="docLoadingImage"
              loading="lazy"
              class="docImage"
            />
          </template>
          <a-card-meta :title="doc.name">
            <template v-if="doc.type" #description
              >{{ docLastModifiedDate(doc) }}<br />{{
                doc.page_count
              }}&nbsp;pages</template
            >
            <template v-else #description
              >{{ doc.docs_count }}&nbsp;items</template
            >
          </a-card-meta>
        </a-card>
      </template>
    </div>
    <a-modal
      v-model:visible="folderPasswordState.visible"
      centered
      width="320px"
      :ok-button-props="{ disabled: !folderPasswordValidated }"
      @ok="okFolderPasswordModal"
      @cancel="cancelFolderPasswordModal"
    >
      <template #title>
        <p style="text-align: center">
          <LockOutlined />&nbsp;&nbsp;{{
            folderPasswordState.folderName
          }}&nbsp;&nbsp;&nbsp;&nbsp;
        </p>
      </template>
      <a-form :model="folderPasswordState" :rules="folderPasswordState.rules">
        <a-form-item has-feedback name="pass">
          <a-input
            v-model:value="folderPasswordState.password"
            type="password"
            placeholder="Input password"
          >
            <template #prefix
              ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
            /></template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button
            class="fpwd-forgot-btn"
            type="text"
            @click="showForgetPasswordModal"
          >
            Forgot Password?
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="forgetPasswordState.visible"
      width="320px"
      :ok-button-props="{ disabled: !forgetPasswordValidated }"
      @ok="okForgetPasswordModal"
      @cancel="cancelForgetPasswordModal"
    >
      <template #title>
        <p style="text-align: center">Forget Folder Password?</p>
      </template>
      <a-form :model="forgetPasswordState" :rules="forgetPasswordState.rules">
        <a-form-item class="fpwd-forgot-text"
          ><a-typography-text
            >Please log in again to unlock your folder "{{
              folderPasswordState.folderName
            }}".</a-typography-text
          ></a-form-item
        >
        <a-form-item has-feedback name="pass">
          <a-input
            v-model:value="forgetPasswordState.password"
            type="password"
            placeholder="Password"
          >
            <template #prefix
              ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
            /></template>
          </a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { message } from "ant-design-vue";
import { LockOutlined } from "@ant-design/icons-vue";
import * as utils from "@/utils";
import VLazyImage from "v-lazy-image";

export default {
  name: "DocCollectionView",
  props: {
    backupId: String,
    backupTitle: String,
    folderName: String,
  },
  components: {
    LockOutlined,
    VLazyImage,
  },
  emits: ["update:folderName"],
  data() {
    return {
      isMobileUserAgent: utils.isMobileUserAgent(),
      loading: false,
      loadingKey: "loadingDocList",
      unlockingKey: "unlockingFolder",
      ignoreFolderNameChange: false,
      docList: [],
      folderPasswordState: {
        folderName: "",
        password: "",
        visible: false,
        rules: {
          pass: [
            {
              required: true,
              validator: this.validateFolderPassword,
              trigger: "change",
            },
          ],
        },
      },
      forgetPasswordState: {
        password: "",
        visible: false,
        rules: {
          pass: [
            {
              required: true,
              validator: this.validateForgetPassword,
              trigger: "change",
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters(["appToken", "userToken"]),
    folderPassword: {
      get: function () {
        let password = this.folderPasswordState.password;
        return password ? password.trim() : "";
      },
      set: function (newVal) {
        this.folderPasswordState.password = newVal;
      },
    },
    forgetPassword: {
      get: function () {
        let password = this.forgetPasswordState.password;
        return password ? password.trim() : "";
      },
      set: function (newVal) {
        this.forgetPasswordState.password = newVal;
      },
    },
    folderPasswordValidated: function () {
      return this.folderPassword;
    },
    forgetPasswordValidated: function () {
      return this.forgetPassword;
    },
    docLoadingImage: function () {
      return require("@/assets/loading_doc.png");
    },
    isSmallScreen: () => {
      return Math.min(window.screen.width, window.screen.height) < 770;
    },
  },
  methods: {
    // folderPasswordState stuffs
    validateFolderPassword: async function () {
      if (!this.folderPassword) {
        return Promise.reject("Please enter the password");
      }
      return Promise.resolve();
    },
    showFolderPasswordModal: function (name) {
      this.folderPasswordState.folderName = name;
      this.folderPasswordState.visible = true;
    },
    closeFolderPasswordModal: function () {
      this.folderPasswordState.visible = false;
      this.folderPassword = "";
    },
    okFolderPasswordModal: function () {
      console.log("okFolderPasswordModal: " + this.folderPassword);
      const url = process.env.VUE_APP_API + "/unlock_folder";
      const params = {
        backupUUID: this.backupId,
        user_token: this.userToken,
        folderName: this.folderPasswordState.folderName,
        password: this.folderPassword,
      };
      console.log("unlock_folder: " + url);
      console.log("backupUUID: " + params.backupUUID);
      console.log("user_token: " + params.user_token);
      console.log("folderName: " + params.folderName);
      console.log("password: " + params.password);
      let hide = message.loading({
        content: "Unlocking folder...",
        key: this.unlockingKey,
      });
      axios
        .post(url, params)
        .then((res) => {
          if (res.data.error) {
            let error = res.data.error;
            console.log("error = " + error);
            if (error === "invalid_session") {
              hide();
              this.closeFolderPasswordModal();
              this.$router.push({
                path: "/login",
                name: "LoginView",
              });
            } else {
              message.error({
                content: error,
                key: this.unlockingKey,
                duration: 3,
              });
              if (error != "password_not_matched") {
                this.closeFolderPasswordModal();
              }
            }
          } else if (res.data.result) {
            console.log("folder unlocked!");
            hide();
            this.closeFolderPasswordModal();
            this.$emit("update:folderName", params.folderName);
          }
        })
        .catch((err) => {
          console.log("unlock folder failed: " + err);
          message.error({
            content: err,
            key: this.unlockingKey,
            duration: 3,
          });
          this.closeFolderPasswordModal();
        });
    },
    cancelFolderPasswordModal: function () {
      console.log("cancelFolderPasswordModal");
      this.folderPassword = "";
    },
    // forgetPasswordState stuffs
    validateForgetPassword: async function () {
      if (!this.forgetPassword) {
        return Promise.reject("Please enter the password");
      }
      return Promise.resolve();
    },
    showForgetPasswordModal: function () {
      this.forgetPasswordState.visible = true;
    },
    closeForgetPasswordModal: function () {
      this.forgetPasswordState.visible = false;
      this.forgetPassword = "";
    },
    okForgetPasswordModal: function () {
      console.log("okForgetPasswordModal: " + this.forgetPassword);
      const url = process.env.VUE_APP_API + "/unlock_folder";
      const params = {
        backupUUID: this.backupId,
        user_token: this.userToken,
        folderName: this.folderPasswordState.folderName,
        user_password: this.forgetPassword,
      };
      console.log("unlock_folder: " + url);
      console.log("backupUUID: " + params.backupUUID);
      console.log("user_token: " + params.user_token);
      console.log("folderName: " + params.folderName);
      console.log("user_password: " + params.user_password);
      let hide = message.loading({
        content: "Unlocking folder...",
        key: this.unlockingKey,
      });
      axios
        .post(url, params)
        .then((res) => {
          if (res.data.error) {
            let error = res.data.error;
            console.log("error = " + error);
            if (error === "invalid_session") {
              hide();
              this.closeForgetPasswordModal();
              this.closeFolderPasswordModal();
              this.$router.push({
                path: "/login",
                name: "LoginView",
              });
            } else {
              message.error({
                content: error,
                key: this.unlockingKey,
                duration: 3,
              });
              if (error != "password_not_matched") {
                this.closeForgetPasswordModal();
              }
            }
          } else if (res.data.result) {
            console.log("folder unlocked!");
            hide();
            this.closeForgetPasswordModal();
            this.closeFolderPasswordModal();
            this.$emit("update:folderName", params.folderName);
          }
        })
        .catch((err) => {
          console.log("unlock folder failed: " + err);
          message.error({
            content: err,
            key: this.unlockingKey,
            duration: 3,
          });
          this.closeForgetPasswordModal();
        });
    },
    cancelForgetPasswordModal: function () {
      console.log("cancelForgetPasswordModal");
      this.forgetPassword = "";
    },
    docThumbnail(doc) {
      return doc.page_count > 0
        ? doc.preview_url
        : require("@/assets/empty_doc.png");
    },
    folderIcon: function (doc) {
      let icon = doc.password
        ? doc.isLocked
          ? "folder_lock.png"
          : "folder_unlock.png"
        : "folder.png";
      return require("@/assets/" + icon);
    },
    gotoRoot: function () {
      this.$emit("update:folderName", null);
    },
    selectDoc: function (doc) {
      if (doc.type) {
        if (doc.page_count > 0) {
          let routeData = this.$router.resolve({
            name: this.isMobileUserAgent
              ? "PageGalleryView2"
              : "PageGalleryView",
            params: {
              backupId: this.backupId,
              docId: doc.uuid,
            },
          });
          window.open(routeData.href, "_blank");
          // this.$router.push({
          //   name: this.isMobileUserAgent
          //     ? "PageGalleryView2"
          //     : "PageGalleryView",
          //   params: {
          //     backupId: this.backupId,
          //     docId: doc.uuid,
          //   },
          // });
        }
      } else if (doc.isLocked) {
        this.showFolderPasswordModal(doc.name);
      } else {
        this.$emit("update:folderName", doc.name);
      }
    },
    docSubtitle: function (doc) {
      return doc.type
        ? this.docLastModifiedDate(doc) + " " + doc.page_count + " pages"
        : doc.docs_count + " items";
    },
    docLastModifiedDate: (doc) => utils.dateStringFromMS(doc.dateMS),
    loadDocList: function () {
      if (this.backupId) {
        let hide = message.loading({
          content: "Loading...",
          key: this.loadingKey,
        });
        this.loading = true;
        const url = process.env.VUE_APP_API + "/show_backup_contents";
        const params = {
          backupUUID: this.backupId,
          user_token: this.userToken,
        };
        if (this.folderName) {
          params.folderName = this.folderName;
        }
        console.log("show_backup_contents: " + url);
        console.log("backupUUID: " + params.backupUUID);
        console.log("user_token: " + params.user_token);
        if (params.folderName) {
          console.log("folderName: " + params.folderName);
        }
        axios
          .post(url, params)
          .then((res) => {
            hide();
            if (res.data.error) {
              console.log("error = " + res.data.error);
              if (res.data.error === "invalid_session") {
                this.$router.push({
                  path: "/login",
                  name: "LoginView",
                });
              }
              this.loading = false;
            } else if (res.data.result) {
              console.log("docList loaded!");
              this.docList = res.data.result;
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log("docList failed to load!: " + err);
            this.docList = [];
            message.error({
              content: "Failed to load documents!",
              key: this.loadingKey,
              duration: 3,
            });
            this.loading = false;
          });
      } else {
        this.docList = [];
      }
    },
  },
  watch: {
    loading: function (newVal) {
      let container = this.$refs.baseContainer;
      if (container) {
        let style = {};
        style.opacity = newVal ? 0.4 : 1;
        container.style = style;
      }
    },
    backupId: function () {
      this.loadDocList();
      this.ignoreFolderNameChange = this.loading;
      if (this.ignoreFolderNameChange) {
        this.$nextTick(() => {
          this.ignoreFolderNameChange = false;
        });
      }
    },
    folderName: function () {
      if (this.backupId && !this.ignoreFolderNameChange) {
        this.loadDocList();
      }
    },
  },
  mounted: function () {
    message.config({
      top: "40px",
      maxCount: 3,
    });
    if (this.backupId) {
      this.loadDocList();
    }
  },
};
</script>

<style lang="scss" scoped>
.base-container {
  padding-top: 12px;
}

.navSection {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 12px;
  font-size: 14pt;
  height: 40px;
}

.fpwd-forgot-btn {
  color: gray;
  // horizontally center
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.fpwd-forgot-text {
  text-align: left;
  font-size: 16px;
  color: #5c5c5c;
}

/* mobile */
@media screen and (max-width: 540px) {
  .base-container {
    padding-left: 4px;
  }
  .docCard {
    width: 33vw;
    min-width: 100px;
    margin: 2px;
  }
}

/* tablets */
@media screen and (min-width: 540px) and (max-device-width: 768px) {
  .base-container {
    padding-left: 16px;
  }
  .docCard {
    width: calc((100vw - 240px) * 0.15);
    min-width: 140px;
    max-width: 280px;
    margin: 8px;
  }
}

/* desktop */
@media screen and (min-device-width: 769px) {
  .base-container {
    padding-left: 20px;
  }
  .docCard {
    width: calc((100vw - 240px) * 0.15);
    min-width: 160px;
    max-width: 300px;
    margin: 10px;
  }
}

.docImage {
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
}
</style>

<style lang="scss">
/* mobile */
@media screen and (max-width: 540px) {
  .ant-card-meta-title {
    font-size: 13px;
  }
  .ant-card-meta-description {
    font-size: 12px;
  }
}

/* tablets */
@media screen and (min-width: 540px) and (max-device-width: 768px) {
  .ant-card-meta-title {
    font-size: 14px;
  }
  .ant-card-meta-description {
    font-size: 12px;
  }
}

/* desktop */
@media screen and (min-device-width: 769px) {
  .ant-card-meta-title {
    font-size: 15px;
  }
  .ant-card-meta-description {
    font-size: 13px;
  }
}

.ant-card-body {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 2px;
  padding-right: 2px;
}

.ant-card-meta-title {
  white-space: normal;
}
</style>
