//import { createRouter, createWebHistory } from "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";
import BackupView from "../views/BackupView.vue";
import PageGalleryView from "../views/PageGalleryView.vue";
import PageGalleryView2 from "../views/PageGalleryView2.vue";
import LoginView from "../views/LoginView.vue";
import SandboxLoginView from "../views/SandboxLoginView.vue";
import ForgotPasswordView from "../views/ForgotPasswordView.vue";

const routes = [
  {
    path: "/",
    name: "BackupView",
    component: BackupView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pageGalleryView/:backupId/:docId",
    name: "PageGalleryView",
    component: PageGalleryView,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pageGalleryView2/:backupId/:docId",
    name: "PageGalleryView2",
    component: PageGalleryView2,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
    meta: {
      guest: true,
    },
  },
  {
    path: "/sandboxlogin",
    name: "SandboxLoginView",
    component: SandboxLoginView,
    meta: {
      guest: true,
    },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPasswordView",
    component: ForgotPasswordView,
    meta: {
      guest: true,
    },
  },
];

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

function isAuthenticated() {
  let isLogin =
    localStorage.getItem("loginEmail") && localStorage.getItem("userToken");
  if (isLogin) {
    let dateMS = JSON.parse(localStorage.getItem("userTokenExpireDateMS"));
    let date = new Date(dateMS);
    isLogin = date > new Date().getTime();
    if (isLogin) {
      let appTokens = JSON.parse(localStorage.getItem("appTokens"));
      if (!appTokens || appTokens.length == 0) {
        isLogin = false;
      } else {
        let lastApp = localStorage.getItem("lastApp");
        isLogin = !!lastApp && !!appTokens[lastApp];
      }
    }
  }
  return isLogin;
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log("check isAuthenticated = " + isAuthenticated());
    if (isAuthenticated()) {
      next();
    } else {
      next({
        path: "/login",
        name: "LoginView",
        params: { nextUrl: to.fullPath },
      });
    }
  } else {
    next();
  }
});

export default router;
