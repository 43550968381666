<template>
  <div class="baseContainer">
    <a-form
      class="login-form"
      layout="vertical"
      :model="formState"
      :rules="rules"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"
    >
      <a-form-item
        ><a-typography-title :level="4"
          >Sign In</a-typography-title
        ></a-form-item
      >
      <a-form-item has-feedback name="user">
        <a-input v-model:value="formState.user" placeholder="Email">
          <template #prefix
            ><MailOutlined style="color: rgba(0, 0, 0, 0.25)"
          /></template>
        </a-input>
      </a-form-item>
      <a-form-item has-feedback name="pass">
        <a-input
          v-model:value="formState.password"
          type="password"
          placeholder="Password"
        >
          <template #prefix
            ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
          /></template>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-checkbox
          class="login-form-remeber-email"
          v-model:checked="formState.rememberEmail"
          >Remember Email</a-checkbox
        >
        <router-link to="/forgotpassword" class="login-form-forgot"
          >Forgot password</router-link
        >
        <a-button
          class="login-form-button"
          type="primary"
          html-type="submit"
          :disabled="!validated"
        >
          Log in
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { MailOutlined, LockOutlined } from "@ant-design/icons-vue";
import { message, notification } from "ant-design-vue";
import * as utils from "@/utils";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default {
  name: "LoginView",
  components: {
    MailOutlined,
    LockOutlined,
  },
  data() {
    return {
      formState: {
        user: this.$store.state.lastLoginEmail || "",
        password: "",
        rememberEmail: this.$store.state.loginRememberEmail,
      },
      loadingKey: "loggingIn",
      rules: {
        user: [
          {
            required: true,
            validator: this.validateUser,
            trigger: "change",
          },
        ],
        pass: [
          {
            required: true,
            validator: this.validatePass,
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["appToken", "userToken"]),
    rememberEmail: function () {
      return this.formState.rememberEmail;
    },
    email: function () {
      let email = this.formState.user;
      return email ? email.trim() : "";
    },
    password: function () {
      let password = this.formState.password;
      return password ? password.trim() : "";
    },
    validated: function () {
      return (
        this.email &&
        this.password &&
        validateEmail(this.email) &&
        this.password.length >= 6
      );
    },
  },
  watch: {
    rememberEmail: function (newVal) {
      this.setLoginRememberEmail(newVal);
    },
  },
  methods: {
    ...mapActions([
      "setUserToken",
      "setUserTokenExpireDate",
      "setUserTokenWithExpireDate",
      "setLoginRememberEmail",
      "setLastLoginEmail",
      "setLoginEmail",
      "setAppTokens",
      "setLastApp",
    ]),
    validateUser: async function () {
      if (!this.email) {
        return Promise.reject("Please enter the email");
      } else if (!validateEmail(this.email)) {
        return Promise.reject("Invalid email address");
      }
      return Promise.resolve();
    },
    validatePass: async function () {
      if (!this.password) {
        return Promise.reject("Please enter the password");
      } else if (this.password.length < 6) {
        return Promise.reject("At least 6 characters");
      }
      return Promise.resolve();
    },
    handleFinish: function () {
      if (!this.rememberEmail) {
        this.setLastLoginEmail(null);
      }
      this.setUserTokenWithExpireDate({ token: null, expireDate: null });
      const url = process.env.VUE_APP_API + "/login";
      const params = {
        username: this.email,
        password: this.password,
      };
      const hide = message.loading({
        content: "Signing in...",
        key: this.loadingKey,
      });
      axios
        .post(url, params)
        .then((res) => {
          hide();
          let error = res.data.error;
          let result = res.data.result;
          console.log("login result");
          if (error) {
            console.log("error: " + error);
            notification.error({
              message: "Login Failed",
              description: "Incorrect username or password.",
            });
            // show error!!
          } else if (result) {
            console.log("email: " + result.email);
            console.log("user_token: " + result.user_token);
            const ms = new Number(result.expireDateMS);
            const date = new Date(ms);
            console.log("expireDateMS: " + date.toString());
            if (utils.isEmpty(result.apps)) {
              console.log("no apps!!");
              // show error!!
              notification.error({
                message: "Login Failed",
                description: "The subscription is expired.",
              });
            } else {
              for (let app in result.apps) {
                console.log(app + ": " + result.apps[app]);
              }
              this.setUserTokenWithExpireDate({
                token: result.user_token,
                expireDate: date,
              });
              this.setLoginEmail(result.email);
              if (this.rememberEmail) {
                this.setLastLoginEmail(result.email);
              }
              this.setAppTokens(result.apps);
              if (!this.appToken) {
                let app_name = Object.keys(result.apps)[0];
                this.setLastApp(app_name);
              }
              this.$router.push({
                path: "/",
                name: "BackupView",
              });
            }
          }
        })
        .catch((err) => {
          hide();
          console.error(err);
        });
    },
    handleFinishFailed: function (e) {
      console.log("handleFinishFailed: " + e);
    },
  },
  mounted: function () {
    message.config({
      top: "40px",
      maxCount: 3,
    });
    if (this.$route.params.nextUrl) {
      console.log("nextUrl = " + this.$route.params.nextUrl);
    }
  },
};
</script>

<style lang="scss" scoped>
.baseContainer {
  background-color: #f8f8f8;
  width: 100vw;
  height: 100vh;
}
.login-form {
  max-width: 464px;
  margin: 0;
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 40px clamp(20px, calc((100vw - 344px) / 2), 60px) 30px;
}
.login-form-remeber-email {
  float: left;
}
.login-form-forgot {
  float: right;
  color: #1890ff;
}
.login-form-button {
  width: 100%;
  margin-top: 8px;
}
</style>
