<template>
  <div class="root-container">
    <nav class="level is-mobile top-container">
      <div class="level-left">
        <div class="level-item">
          <a-dropdown v-if="apps.length > 1">
            <div class="appBlock">
              <img :src="appIcon" />
              <div>
                <h2>
                  {{ appTitle }}<CaretDownOutlined class="appSelDownArrow" />
                </h2>
                <p>Cloud Backup</p>
              </div>
            </div>
            <template #overlay>
              <a-menu @click="clickAppMenuItem">
                <template v-for="app in apps" :key="app">
                  <a-menu-item>
                    <div class="appSelBlock">
                      <img :src="appIconWithName(app)" />
                      <div>
                        <h4>{{ appTitleWithName(app) }}</h4>
                      </div>
                    </div>
                  </a-menu-item>
                </template>
              </a-menu>
            </template>
          </a-dropdown>
          <div class="appBlock" v-else>
            <img :src="appIcon" />
            <div>
              <h2>
                {{ appTitle }}
              </h2>
              <p>Cloud Backup</p>
            </div>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item vert-container">
          <div style="display: flex">
            <a-tag style="border-radius: 10px">{{ plan.toUpperCase() }}</a-tag>
          </div>
          <a-dropdown>
            <template #overlay>
              <a-menu @click="clickSettingMenuItem">
                <a-menu-item key="logout">
                  <LogoutOutlined />
                  Log out
                </a-menu-item>
              </a-menu>
            </template>
            <a-button type="text" size="small">
              {{ loginEmail }}
              <DownOutlined />
            </a-button>
          </a-dropdown>
        </div>
      </div>
    </nav>
    <div class="bottom-container">
      <div class="left-container">
        <a-dropdown>
          <template #overlay>
            <a-menu @click="clickDeviceMenuItem">
              <template v-for="device in devices" :key="device">
                <a-menu-item>
                  <MobileOutlined />
                  {{ device }}
                </a-menu-item>
              </template>
            </a-menu>
          </template>
          <a-button>
            {{ selectedDeviceId }}
            <MobileOutlined />
          </a-button>
        </a-dropdown>
        <a-menu
          v-if="selectedDeviceId && data"
          mode="inline"
          :inlineIndent="18"
          style="margin-top: 5px"
          :selectedKeys="selectedBackupIds"
          @select="selectBackupId"
          @click="clickBackupMenuItem"
        >
          <template v-for="(backups, year) in backupGroups" :key="year">
            <a-menu-item-group>
              <template #title>&nbsp;{{ year }}</template>
              <template v-for="backup in backups" :key="backup.backupUUID">
                <a-menu-item>
                  <template #icon>
                    <ClockCircleOutlined />
                  </template>
                  {{
                    dateStringFromMS(backup.dateMS, "#YYYY#-#MM#-#DD#")
                  }}&nbsp;&nbsp;&nbsp;
                  {{
                    dateStringFromMS(backup.dateMS, "#hhhh#:#mm#")
                  }}&nbsp;&nbsp;&nbsp;
                  <a-tag>{{ backup.docs }}</a-tag>
                </a-menu-item>
              </template>
            </a-menu-item-group>
          </template>
        </a-menu>
      </div>
      <div class="right-container" :style="rightContainerStyle">
        <DocCollectionView
          :backupId="selectedBackupId"
          :backupTitle="selectedBackupTitle"
          :folderName="selectedFolderName"
          @update:folderName="updateFolderName"
        ></DocCollectionView>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import DocCollectionView from "@/views/DocCollectionView.vue";
import {
  CaretDownOutlined,
  ClockCircleOutlined,
  DownOutlined,
  LogoutOutlined,
  MobileOutlined,
} from "@ant-design/icons-vue";
import * as utils from "@/utils";

const screenWidthBreakPoint = 600;

const appTitleDict = {
  docscan: "Doc Scan",
  docscanpro: "Doc Scan Pro",
  fasterscan: "Faster Scan",
  scannerapp: "Scanner App",
  scannerapppro: "Scanner App Pro",
};

export default {
  name: "BackupView",
  components: {
    DocCollectionView,
    CaretDownOutlined,
    ClockCircleOutlined,
    DownOutlined,
    LogoutOutlined,
    MobileOutlined,
  },
  data() {
    return {
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      selectedBackupId: this.$store.state.selectedBackupId,
      selectedDeviceId: this.$store.state.selectedDeviceId,
      selectedFolderName: this.$store.state.selectedFolderName,
      plan: "",
      data: [],
    };
  },
  computed: {
    ...mapGetters([
      "appToken",
      "userToken",
      "loginEmail",
      "lastApp",
      "appTokens",
    ]),
    apps: function () {
      return Object.keys(this.appTokens);
    },
    devices: function () {
      return Object.keys(this.data);
    },
    appIcon: function () {
      console.log("lastApp = " + this.lastApp);
      return require("@/assets/" + this.lastApp + ".png");
    },
    appTitle: function () {
      return this.lastApp ? appTitleDict[this.lastApp] : "";
    },
    selectedBackupTitle: function () {
      const backup = this.selectedBackup;
      return backup ? utils.dateStringFromMS(backup.dateMS) : null;
    },
    selectedBackupIds: {
      get: function () {
        return this.selectedBackupId == null ? [] : [this.selectedBackupId];
      },
      set: function (ids) {
        if (ids && ids.length > 0) {
          this.selectedBackupId = ids[0];
        }
      },
    },
    selectedBackup: {
      get: function () {
        if (this.selectedBackupId) {
          const backups = this.data[this.selectedDeviceId];
          if (backups) {
            const backup = backups.find(
              (element) => element.backupUUID == this.selectedBackupId
            );
            if (backup) {
              return backup;
            }
          }
        }
        return null;
      },
      set: function (backup) {
        this.selectedBackupId = backup ? backup.backupUUID : null;
      },
    },
    backupItems: function () {
      return this.data[this.selectedDeviceId];
    },
    backupGroups: function () {
      const backups = this.backupItems;
      return backups
        ? backups.reduce((groups, item) => {
            const val = utils.dateStringFromMS(item["dateMS"], "#YYYY#");
            groups[val] = groups[val] || [];
            groups[val].push(item);
            return groups;
          }, {})
        : {};
    },
    rightContainerStyle: function () {
      let style = {};
      style["min-width"] =
        this.screenWidth < screenWidthBreakPoint
          ? "100vw"
          : screenWidthBreakPoint - 240 + "px";
      return style;
    },
  },
  watch: {
    selectedBackupId: function (newValue) {
      this.setSelectedBackupId(newValue);
      this.setSelectedBackupTitle(this.selectedBackupTitle);
    },
    selectedDeviceId: function (newValue) {
      this.setSelectedDeviceId(newValue);
    },
    selectedFolderName: function (newValue) {
      this.setSelectedFolderName(newValue);
    },
    appToken: function (newAppToken) {
      if (this.userToken && newAppToken) {
        this.selectDeviceId(null);
        this.loadData();
      }
    },
  },
  methods: {
    ...mapActions([
      "setSelectedBackupId",
      "setSelectedBackupTitle",
      "setSelectedDeviceId",
      "setSelectedFolderName",
      "setLastApp",
      "clearSessionData",
    ]),
    screenResized: function () {
      this.screenWidth = window.screen.width;
      this.screenHeight = window.screen.height;
    },
    selectDeviceId: function (device) {
      this.selectedBackup = null;
      this.selectedFolderName = null;
      this.selectedDeviceId = device;
    },
    selectBackupId: function ({ key }) {
      this.selectedBackupId = key;
    },
    updateFolderName: function (folderName) {
      this.selectedFolderName = folderName;
    },
    clickBackupMenuItem: function () {
      this.selectedFolderName = null;
    },
    dateStringFromMS(dateMS, format) {
      return utils.dateStringFromMS(dateMS, format);
    },
    clickAppMenuItem: function (item) {
      if (item.key !== this.lastApp) {
        this.setLastApp(item.key);
      }
    },
    clickDeviceMenuItem: function (item) {
      this.selectDeviceId(item.key);
    },
    clickSettingMenuItem: function (item) {
      if (item.key === "logout") {
        this.logout();
      }
    },
    appIconWithName: function (appname) {
      return require("@/assets/" + appname + ".png");
    },
    appTitleWithName: function (appname) {
      return appTitleDict[appname];
    },
    logout: function () {
      const url = process.env.VUE_APP_API + "/logout";
      const params = {
        user_token: this.userToken,
      };
      console.log("logout: " + url);
      console.log("user_token: " + params.user_token);
      axios
        .post(url, params)
        .then((res) => {
          console.log("result = " + res.data.result);
          this.selectDeviceId(null);
          this.clearSessionData();
          this.$router.push({
            path: "/login",
            name: "LoginView",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    loadData: function () {
      const url = process.env.VUE_APP_API + "/list_backup";
      const params = {
        app_token: this.appToken,
        user_token: this.userToken,
      };
      console.log("list_backup: " + url);
      console.log("app_token: " + params.app_token);
      console.log("user_token: " + params.user_token);
      axios
        .post(url, params)
        .then((res) => {
          if (res.data.error) {
            console.log("error = " + res.data.error);
            if (res.data.error === "invalid_session") {
              this.$router.push({
                path: "/login",
                name: "LoginView",
              });
            }
          } else if (res.data.result) {
            console.log("plan = " + res.data.plan);
            this.plan = res.data.plan;
            this.data = res.data.result.reduce((groups, item) => {
              const key = "device_name";
              const val = item[key];
              groups[val] = groups[val] || [];
              groups[val].push(item);
              return groups;
            }, {});
            if (this.devices.length > 0) {
              if (this.selectedDeviceId === null) {
                this.selectDeviceId(this.devices[0]);
              }
            } else {
              this.selectDeviceId(null);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.data = [];
        });
    },
  },
  mounted: function () {
    this.loadData();
  },
  created() {
    window.addEventListener("resize", this.screenResized);
  },
  unmounted() {
    window.removeEventListener("resize", this.screenResized);
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  width: 100%;
  height: 100%;
}
.appBlock {
  overflow: hidden;
  padding: 8px;
  max-width: 280px;
  height: 68px;
  border-radius: 10px;
}
.appBlock:hover {
  background-color: #eaeaea;
}
.appBlock img {
  width: 52px;
  height: 52px;
  float: left;
  margin: 0 8px 0 0;
}
.appBlock > div {
  padding-left: calc(52px + 8px);
  text-align: left;
}
.appBlock > div > h2 {
  font-size: 20px;
  font-weight: 700;
}
.appBlock > div > p {
  font-size: 16px;
}
.appSelDownArrow {
  transform: translateY(2px) scale(0.8);
}
.appSelBlock {
  overflow: hidden;
  padding: 8px;
  max-width: 200px;
  height: 40px;
}
.appSelBlock img {
  width: 32px;
  height: 32px;
  float: left;
  margin: 0 8px 0 0;
}
.appSelBlock > div {
  padding-left: calc(32px + 8px);
  text-align: left;
}
.appSelBlock > div > h4 {
  font-size: 16px;
  font-weight: 300;
}
.vert-container {
  display: block;
}
.top-container {
  height: 88px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.bottom-container {
  display: flex;
  width: 100%;
  height: calc(100% - 88px);
}
.left-container {
  flex: 0 0 240px;
  width: 240px;
  height: 100%;
}
.right-container {
  flex: 1 1 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
}
</style>
