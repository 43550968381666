import "@/assets/main.scss";
//import "bulma/css/bulma.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Modal,
  Spin,
  Tag,
  Typography,
  Menu,
} from "ant-design-vue";

createApp(App)
  .use(store)
  .use(router)
  .use(Button)
  .use(Card)
  .use(Checkbox)
  .use(Dropdown)
  .use(Form)
  .use(Input)
  .use(Modal)
  .use(Spin)
  .use(Tag)
  .use(Typography)
  .use(Menu)
  .mount("#app");
