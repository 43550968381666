import { createStore } from "vuex";

console.log("initialiseStore");
try {
  window.localStorage;
} catch {
  alert("Please do NOT block all cookies!!");
}

let rememberEmail = JSON.parse(localStorage.getItem("loginRememberEmail"));
if (rememberEmail === null) {
  rememberEmail = true;
}
let userTokenExpire = JSON.parse(localStorage.getItem("userTokenExpireDateMS"));
if (userTokenExpire) {
  userTokenExpire = new Date(userTokenExpire);
}
let appTokens = JSON.parse(localStorage.getItem("appTokens"));
if (appTokens === null) {
  appTokens = {};
}

export default createStore({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || "0",
    selectedBackupId: null,
    selectedBackupTitle: null,
    selectedDeviceId: null,
    selectedFolderName: null,
    userToken: localStorage.getItem("userToken"),
    userTokenExpireDate: userTokenExpire,
    loginRememberEmail: rememberEmail,
    lastLoginEmail: localStorage.getItem("lastLoginEmail"),
    loginEmail: localStorage.getItem("loginEmail"),
    appTokens: appTokens,
    lastApp: localStorage.getItem("lastApp"),
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
    isUserTokenExpired: (state) => {
      return (
        state.userToken &&
        state.userTokenExpireDate &&
        state.userTokenExpireDate > new Date().getTime()
      );
    },
    isLogin: (state, getters) => {
      return state.loginEmail && getters.isUserTokenExpired;
    },
    appToken: (state) => {
      if (state.appTokens && state.lastApp) {
        let token = state.appTokens[state.lastApp];
        if (token) {
          return token;
        }
      }
      return null;
    },
    userToken: (state) => {
      return state.userToken;
    },
    loginEmail: (state) => {
      return state.loginEmail;
    },
    lastApp: (state) => {
      return state.lastApp;
    },
    appTokens: (state) => {
      return state.appTokens;
    },
  },
  mutations: {
    setSelectedBackupId(state, newSelectedBackupId) {
      state.selectedBackupId = newSelectedBackupId;
    },
    setSelectedBackupTitle(state, newSelectedBackupTitle) {
      state.selectedBackupTitle = newSelectedBackupTitle;
    },
    setSelectedDeviceId(state, newSelectedDeviceId) {
      state.selectedDeviceId = newSelectedDeviceId;
    },
    setSelectedFolderName(state, newSelectedFolderName) {
      state.selectedFolderName = newSelectedFolderName;
    },
    setUserToken(state, newUserToken) {
      state.userToken = newUserToken;
      if (newUserToken) {
        localStorage.setItem("userToken", newUserToken);
      } else {
        localStorage.removeItem("userToken");
      }
    },
    setUserTokenExpireDate(state, newUserTokenExpireDate) {
      state.userTokenExpireDate = newUserTokenExpireDate;
      if (newUserTokenExpireDate) {
        localStorage.setItem(
          "userTokenExpireDateMS",
          JSON.stringify(newUserTokenExpireDate.getTime())
        );
      } else {
        localStorage.removeItem("userTokenExpireDateMS");
      }
    },
    setUserTokenWithExpireDate(state, payload) {
      state.userToken = payload.token;
      state.userTokenExpireDate = payload.expireDate;
      if (payload.token) {
        localStorage.setItem("userToken", payload.token);
      } else {
        localStorage.removeItem("userToken");
      }
      if (payload.expireDate) {
        localStorage.setItem(
          "userTokenExpireDateMS",
          JSON.stringify(payload.expireDate.getTime())
        );
      } else {
        localStorage.removeItem("userTokenExpireDateMS");
      }
    },
    setLoginRememberEmail(state, newLoginRememberEmail) {
      state.loginRememberEmail = newLoginRememberEmail;
      localStorage.setItem(
        "loginRememberEmail",
        JSON.stringify(!!newLoginRememberEmail)
      );
    },
    setLastLoginEmail(state, newLastLoginEmail) {
      state.lastLoginEmail = newLastLoginEmail;
      if (newLastLoginEmail) {
        localStorage.setItem("lastLoginEmail", newLastLoginEmail);
      } else {
        localStorage.removeItem("lastLoginEmail");
      }
    },
    setLoginEmail(state, newLoginEmail) {
      state.loginEmail = newLoginEmail;
      if (newLoginEmail) {
        localStorage.setItem("loginEmail", newLoginEmail);
      } else {
        localStorage.removeItem("loginEmail");
      }
    },
    setAppTokens(state, newAppTokens) {
      state.appTokens = newAppTokens;
      if (newAppTokens) {
        localStorage.setItem("appTokens", JSON.stringify(newAppTokens));
      } else {
        localStorage.removeItem("appTokens");
      }
    },
    setLastApp(state, newLastApp) {
      state.lastApp = newLastApp;
      if (newLastApp) {
        localStorage.setItem("lastApp", newLastApp);
      } else {
        localStorage.removeItem("lastApp");
      }
    },
    clearSessionData(state) {
      state.userToken = null;
      state.userTokenExpireDate = null;
      state.loginEmail = null;
      state.appTokens = {};
      localStorage.removeItem("loginEmail");
      localStorage.removeItem("appTokens");
      localStorage.removeItem("userToken");
      localStorage.removeItem("userTokenExpireDateMS");
    },
  },
  actions: {
    setSelectedBackupId({ commit }, newSelectedBackupId) {
      commit("setSelectedBackupId", newSelectedBackupId);
    },
    setSelectedBackupTitle({ commit }, newSelectedBackupTitle) {
      commit("setSelectedBackupTitle", newSelectedBackupTitle);
    },
    setSelectedDeviceId({ commit }, newSelectedDeviceId) {
      commit("setSelectedDeviceId", newSelectedDeviceId);
    },
    setSelectedFolderName({ commit }, newSelectedFolderName) {
      commit("setSelectedFolderName", newSelectedFolderName);
    },
    setUserToken({ commit }, newUserToken) {
      commit("setUserToken", newUserToken);
    },
    setUserTokenExpireDate({ commit }, newUserTokenExpireDate) {
      commit("setUserTokenExpireDate", newUserTokenExpireDate);
    },
    setUserTokenWithExpireDate({ commit }, payload) {
      commit("setUserTokenWithExpireDate", payload);
    },
    setLoginRememberEmail({ commit }, newLoginRememberEmail) {
      commit("setLoginRememberEmail", newLoginRememberEmail);
    },
    setLastLoginEmail({ commit }, newLastLoginEmail) {
      commit("setLastLoginEmail", newLastLoginEmail);
    },
    setLoginEmail({ commit }, newLoginEmail) {
      commit("setLoginEmail", newLoginEmail);
    },
    setAppTokens({ commit }, newAppTokens) {
      commit("setAppTokens", newAppTokens);
    },
    setLastApp({ commit }, newLastApp) {
      commit("setLastApp", newLastApp);
    },
    clearSessionData({ commit }) {
      commit("clearSessionData");
    },
  },
  modules: {},
});
