<template>
  <div class="baseContainer">
    <a-form
      class="fg-pwd-form"
      layout="vertical"
      :model="formState"
      :rules="rules"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"
    >
      <a-form-item
        ><a-typography-title :level="4"
          >Forgot Password</a-typography-title
        ></a-form-item
      >
      <a-form-item class="fg-pwd-form-text"
        ><a-typography-text
          >Enter the email address you used when you joined and we’ll send you
          instructions to reset your password.</a-typography-text
        ></a-form-item
      >
      <a-form-item has-feedback name="email">
        <a-input v-model:value="formState.email" placeholder="Email">
          <template #prefix
            ><MailOutlined style="color: rgba(0, 0, 0, 0.25)"
          /></template>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button
          class="fg-pwd-form-button"
          type="primary"
          html-type="submit"
          :disabled="!validated || hasEmailSent"
        >
          Send Reset Instructions
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-button class="fg-pwd-form-back" type="link" @click="goBack">
          Back to Sign In
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import axios from "axios";
import { MailOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default {
  name: "ForgotPasswordView",
  components: {
    MailOutlined,
  },
  data() {
    return {
      formState: {
        email: "",
      },
      emailsSent: new Set(),
      loadingKey: "sendingReset",
      rules: {
        email: [
          {
            required: true,
            validator: this.validateEmail,
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    email: function () {
      let email = this.formState.email;
      return email ? email.trim() : "";
    },
    validated: function () {
      return this.email && validateEmail(this.email);
    },
    hasEmailSent: function () {
      return this.emailsSent.has(this.email);
    },
  },
  methods: {
    validateEmail: async function () {
      if (!this.email) {
        return Promise.reject("Please enter the email");
      } else if (!validateEmail(this.email)) {
        return Promise.reject("Invalid email address");
      }
      return Promise.resolve();
    },
    goBack: function () {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          path: "/login",
          name: "LoginView",
        });
      }
    },
    handleFinish: function () {
      const url = process.env.VUE_APP_API + "/forget_password";
      const params = {
        email: this.email,
      };
      message.loading({
        content: "Sending...",
        key: this.loadingKey,
      });
      axios
        .post(url, params)
        .then((res) => {
          console.log("reset password: result = " + res.data.result);
          this.emailsSent.add(this.email);
          message.success({
            content: "Reset Instructions Sent!",
            key: this.loadingKey,
            duration: 3,
          });
          setTimeout(this.goBack, 4000);
        })
        .catch((err) => {
          console.error(err);
          message.error({
            content: "Network error!",
            key: this.loadingKey,
            duration: 3,
          });
        });
    },
    handleFinishFailed: function (e) {
      console.log("handleFinishFailed: " + e);
    },
  },
  mounted: function () {
    message.config({
      top: "40px",
      maxCount: 3,
    });
  },
};
</script>

<style lang="scss" scoped>
.baseContainer {
  background-color: #f8f8f8;
  width: 100vw;
  height: 100vh;
}
.fg-pwd-form {
  max-width: 464px;
  margin: 0;
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 40px clamp(20px, calc((100vw - 344px) / 2), 60px) 20px;
}
.fg-pwd-form-text {
  text-align: left;
  font-size: 16px;
}
.fg-pwd-form-button {
  width: 100%;
  margin-top: 8px;
}
.fg-pwd-form-back {
  width: 100%;
  color: #1890ff;
}
</style>
