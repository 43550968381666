import * as fonts from "@/fonts";
import { fetch_editing } from "@/adapters/editing";
import { PDFDocument } from "pdf-lib";
import "blob-polyfill";
import "blueimp-canvas-to-blob";

const ImageOrientation = {
  Up: 0, // default orientation
  Down: 1, // 180 deg rotation
  Left: 2, // 90 deg CCW
  Right: 3, // 90 deg CW
  UpMirrored: 4, // as above but image mirrored along other axis. horizontal flip
  DownMirrored: 5, // horizontal flip
  LeftMirrored: 6, // vertical flip
  RightMirrored: 7, // vertical flip
};

const TextAlignment = {
  Left: 0,
  Center: 1,
  Right: 2,
};

const PageSizeEnum = {
  Letter: 0,
  A4: 1,
  BusinessCard: 2,
  Legal: 3,
  Ledger: 4,
  A3: 5,
  A5: 6,
  B4: 7,
  Custom: 8,
  Total: 8,
};

const PageSizeAsPx = [
  { width: 612, height: 792 },
  { width: 595, height: 842 },
  { width: 252, height: 144 },
  { width: 612, height: 1008 },
  { width: 1224, height: 792 },
  { width: 842, height: 1191 },
  { width: 420, height: 595 },
  { width: 708, height: 1001 },
];

function isMobileUserAgent() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return (
      navigator.userAgent.match(toMatchItem) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    );
  });
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

// rot = {-90, 0, 90, 180} + {-90, 0, 90, 180}
//     = {-180, -90, 0, 90, 180, 270, 360}
function isRot90(rot) {
  let r = Math.abs(rot) % 180;
  return r == 90;
}

function isRot180(rot) {
  let r = Math.abs(rot) % 180;
  return r == 0;
}

function rotateOrient(orient, rotate_count) {
  const orients = [
    ImageOrientation.Up,
    ImageOrientation.Right,
    ImageOrientation.Down,
    ImageOrientation.Left,
  ];
  const r = orients.findIndex((val) => val == orient);
  return orients[(r + rotate_count) % 4];
}

// rotate degree clockwise
function rotateToChangeOrient(srcOrient, dstOrient) {
  var rot = 0;
  if (srcOrient != dstOrient) {
    switch (srcOrient) {
      case ImageOrientation.Up:
        rot =
          dstOrient == ImageOrientation.Down
            ? 180
            : dstOrient == ImageOrientation.Left
            ? -90
            : 90;
        break;
      case ImageOrientation.Down:
        rot =
          dstOrient == ImageOrientation.Up
            ? 180
            : dstOrient == ImageOrientation.Left
            ? 90
            : -90;
        break;
      case ImageOrientation.Left:
        rot =
          dstOrient == ImageOrientation.Up
            ? 90
            : dstOrient == ImageOrientation.Down
            ? -90
            : 180;
        break;
      case ImageOrientation.Right:
        rot =
          dstOrient == ImageOrientation.Up
            ? -90
            : dstOrient == ImageOrientation.Down
            ? 90
            : 180;
        break;
    }
  }
  return rot;
}

function aspectFitNoScaleUp(size, boundSize) {
  let r_w = size.width;
  let r_h = size.height;
  let ratio = size.width / size.height;
  let boundR = boundSize.width / boundSize.height;
  if (ratio < boundR) {
    if (size.height > boundSize.height) {
      r_h = boundSize.height;
      r_w = boundSize.height * ratio;
    }
  } else if (size.width > boundSize.width) {
    r_w = boundSize.width;
    r_h = boundSize.width / ratio;
  }
  return { width: Math.round(r_w), height: Math.round(r_h) };
}
/**
 * Does a PHP var_dump'ish behavior.  It only dumps one variable per call.  The
 * first parameter is the variable, and the second parameter is an optional
 * name.  This can be the variable name [makes it easier to distinguish between
 * numerious calls to this function], but any string value can be passed.
 *
 * @param mixed var_value - the variable to be dumped
 * @param string var_name - ideally the name of the variable, which will be used
 *       to label the dump.  If this argumment is omitted, then the dump will
 *       display without a label.
 * @param boolean - annonymous third parameter.
 *       On TRUE publishes the result to the DOM document body.
 *       On FALSE a string is returned.
 *       Default is TRUE.
 * @returns string|inserts Dom Object in the BODY element.
 */
function my_dump(var_value, var_name) {
  // Check for a third argument and if one exists, capture it's value, else
  // default to TRUE.  When the third argument is true, this function
  // publishes the result to the document body, else, it outputs a string.
  // The third argument is intend for use by recursive calls within this
  // function, but there is no reason why it couldn't be used in other ways.
  var is_publish_to_body =
    typeof arguments[2] === "undefined" ? true : arguments[2];

  // Check for a fourth argument and if one exists, add three to it and
  // use it to indent the out block by that many characters.  This argument is
  // not intended to be used by any other than the recursive call.
  var indent_by = typeof arguments[3] === "undefined" ? 0 : arguments[3] + 3;

  var do_boolean = function (v) {
    return "Boolean(1) " + (v ? "TRUE" : "FALSE");
  };

  var do_number = function (v) {
    var num_digits = ("" + v).length;
    return "Number(" + num_digits + ") " + v;
  };

  var do_string = function (v) {
    var num_chars = v.length;
    return "String(" + num_chars + ') "' + v + '"';
  };

  var do_object = function (v) {
    if (v === null) {
      return "NULL(0)";
    }

    var out = "";
    var num_elem = 0;
    var indent = "";

    if (v instanceof Array) {
      num_elem = v.length;
      for (var d = 0; d < indent_by; ++d) {
        indent += " ";
      }
      out =
        "Array(" +
        num_elem +
        ") \n" +
        (indent.length === 0 ? "" : "|" + indent + "") +
        "(";
      for (var i = 0; i < num_elem; ++i) {
        out +=
          "\n" +
          (indent.length === 0 ? "" : "|" + indent) +
          "|   [" +
          i +
          "] = " +
          my_dump(v[i], "", false, indent_by);
      }
      out += "\n" + (indent.length === 0 ? "" : "|" + indent + "") + ")";
      return out;
    } else if (v instanceof Object) {
      for (var dd = 0; dd < indent_by; ++dd) {
        indent += " ";
      }
      out = "Object \n" + (indent.length === 0 ? "" : "|" + indent + "") + "(";
      for (var p in v) {
        out +=
          "\n" +
          (indent.length === 0 ? "" : "|" + indent) +
          "|   [" +
          p +
          "] = " +
          my_dump(v[p], "", false, indent_by);
      }
      out += "\n" + (indent.length === 0 ? "" : "|" + indent + "") + ")";
      return out;
    } else {
      return "Unknown Object Type!";
    }
  };

  // Makes it easier, later on, to switch behaviors based on existance or
  // absence of a var_name parameter.  By converting 'undefined' to 'empty
  // string', the length greater than zero test can be applied in all cases.
  var_name = typeof var_name === "undefined" ? "" : var_name;
  var out = "";
  var v_name = "";
  switch (typeof var_value) {
    case "boolean":
      v_name = var_name.length > 0 ? var_name + " = " : ""; // Turns labeling on if var_name present, else no label
      out += v_name + do_boolean(var_value);
      break;
    case "number":
      v_name = var_name.length > 0 ? var_name + " = " : "";
      out += v_name + do_number(var_value);
      break;
    case "string":
      v_name = var_name.length > 0 ? var_name + " = " : "";
      out += v_name + do_string(var_value);
      break;
    case "object":
      v_name = var_name.length > 0 ? var_name + " => " : "";
      out += v_name + do_object(var_value);
      break;
    case "function":
      v_name = var_name.length > 0 ? var_name + " = " : "";
      out += v_name + "Function";
      break;
    case "undefined":
      v_name = var_name.length > 0 ? var_name + " = " : "";
      out += v_name + "Undefined";
      break;
    default:
      out += v_name + " is unknown type!";
  }

  // Using indent_by to filter out recursive calls, so this only happens on the
  // primary call [i.e. at the end of the algorithm]
  if (is_publish_to_body && indent_by === 0) {
    var div_dump = document.getElementById("div_dump");
    if (!div_dump) {
      div_dump = document.createElement("div");
      div_dump.id = "div_dump";

      var style_dump = document.getElementsByTagName("style")[0];
      if (!style_dump) {
        var head = document.getElementsByTagName("head")[0];
        style_dump = document.createElement("style");
        head.appendChild(style_dump);
      }
      // Thank you Tim Down [http://stackoverflow.com/users/96100/tim-down]
      // for the following addRule function
      var addRule;
      if (typeof document.styleSheets != "undefined" && document.styleSheets) {
        addRule = function (selector, rule) {
          var styleSheets = document.styleSheets,
            styleSheet;
          if (styleSheets && styleSheets.length) {
            styleSheet = styleSheets[styleSheets.length - 1];
            if (styleSheet.addRule) {
              styleSheet.addRule(selector, rule);
            } else if (typeof styleSheet.cssText == "string") {
              styleSheet.cssText = selector + " {" + rule + "}";
            } else if (styleSheet.insertRule && styleSheet.cssRules) {
              styleSheet.insertRule(
                selector + " {" + rule + "}",
                styleSheet.cssRules.length
              );
            }
          }
        };
      } else {
        addRule = function (selector, rule, el, doc) {
          el.appendChild(doc.createTextNode(selector + " {" + rule + "}"));
        };
      }

      // Ensure the dump text will be visible under all conditions [i.e. always
      // black text against a white background].
      addRule("#div_dump", "background-color:white", style_dump, document);
      addRule("#div_dump", "color:black", style_dump, document);
      addRule("#div_dump", "padding:15px", style_dump, document);

      style_dump = null;
    }

    var pre_dump = document.getElementById("pre_dump");
    if (!pre_dump) {
      pre_dump = document.createElement("pre");
      pre_dump.id = "pre_dump";
      pre_dump.innerHTML = out + "\n";
      div_dump.appendChild(pre_dump);
      document.body.appendChild(div_dump);
    } else {
      pre_dump.innerHTML += out + "\n";
    }
  } else {
    return out;
  }
}

//*** This code is copyright 2002-2016 by Gavin Kistner, !@phrogz.net
//*** It is covered under the license viewable at http://phrogz.net/JS/_ReuseLicense.txt
Date.prototype.customFormat = function (formatString) {
  var YYYY,
    YY,
    MMMM,
    MMM,
    MM,
    M,
    DDDD,
    DDD,
    DD,
    D,
    hhhh,
    hhh,
    hh,
    h,
    mm,
    m,
    ss,
    s,
    ampm,
    AMPM,
    dMod,
    th;
  YY = ((YYYY = this.getFullYear()) + "").slice(-2);
  MM = (M = this.getMonth() + 1) < 10 ? "0" + M : M;
  MMM = (MMMM = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][M - 1]).substring(0, 3);
  DD = (D = this.getDate()) < 10 ? "0" + D : D;
  DDD = (DDDD = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][this.getDay()]).substring(0, 3);
  th =
    D >= 10 && D <= 20
      ? "th"
      : (dMod = D % 10) == 1
      ? "st"
      : dMod == 2
      ? "nd"
      : dMod == 3
      ? "rd"
      : "th";
  formatString = formatString
    .replace("#YYYY#", YYYY)
    .replace("#YY#", YY)
    .replace("#MMMM#", MMMM)
    .replace("#MMM#", MMM)
    .replace("#MM#", MM)
    .replace("#M#", M)
    .replace("#DDDD#", DDDD)
    .replace("#DDD#", DDD)
    .replace("#DD#", DD)
    .replace("#D#", D)
    .replace("#th#", th);
  h = hhh = this.getHours();
  if (h == 0) h = 24;
  if (h > 12) h -= 12;
  hh = h < 10 ? "0" + h : h;
  hhhh = hhh < 10 ? "0" + hhh : hhh;
  AMPM = (ampm = hhh < 12 ? "am" : "pm").toUpperCase();
  mm = (m = this.getMinutes()) < 10 ? "0" + m : m;
  ss = (s = this.getSeconds()) < 10 ? "0" + s : s;
  return formatString
    .replace("#hhhh#", hhhh)
    .replace("#hhh#", hhh)
    .replace("#hh#", hh)
    .replace("#h#", h)
    .replace("#mm#", mm)
    .replace("#m#", m)
    .replace("#ss#", ss)
    .replace("#s#", s)
    .replace("#ampm#", ampm)
    .replace("#AMPM#", AMPM);
};

function dateStringFromMS(dateMS, format = "#YYYY#-#MM#-#DD# #hhhh#:#mm#") {
  const ms = new Number(dateMS);
  const date = new Date(ms);
  return date.customFormat(format);
}

function measureTextlineHeight(ctx, textline) {
  const { fontBoundingBoxAscent: ascent, fontBoundingBoxDescent: descent } =
    ctx.measureText(textline);
  return ascent + descent;
}

function measureTextlineWidth(ctx, textline) {
  const { width: w } = ctx.measureText(textline);
  return Math.ceil(w);
}

function drawPaint(ctx, vertexBuffer, canvasSize, isEraser, color) {
  ctx.save();
  ctx.globalCompositeOperation = isEraser ? "destination-out" : "source-over";
  ctx.fillStyle =
    "rgba(" + color.r + "," + color.g + "," + color.b + "," + color.a + ")";
  ctx.beginPath();
  ctx.moveTo(vertexBuffer[0].x, canvasSize.height - vertexBuffer[0].y);
  for (let i = 2, len = vertexBuffer.length; i < len; i += 2) {
    ctx.lineTo(vertexBuffer[i].x, canvasSize.height - vertexBuffer[i].y);
  }
  for (let i = vertexBuffer.length - 1; i > 0; i -= 2) {
    ctx.lineTo(vertexBuffer[i].x, canvasSize.height - vertexBuffer[i].y);
  }
  ctx.fill();
  ctx.restore();
}

function drawText(
  ctx,
  texts,
  textAlign,
  textColor,
  bgColor,
  fontName,
  fontSize,
  center, // center.x, center.y
  scale = 1,
  rotate = 0,
  padding = { h: 0, v: 0 }
) {
  var textlines = texts.split("\n");
  ctx.save();
  var y = padding.v;
  var width = 0;
  var lines = [];
  ctx.font = fontSize + "px " + fontName;
  textlines.forEach((textline) => {
    const {
      fontBoundingBoxAscent: ascent,
      fontBoundingBoxDescent: descent,
      width: lineWidth,
    } = ctx.measureText(textline);
    const at = Math.ceil(ascent);
    const w = Math.ceil(lineWidth);
    const h = at + Math.ceil(descent);
    lines.push({ x: padding.h, y: y + at, w: w, h: h });
    width = Math.max(width, w);
    y += h;
  });
  width += padding.h * 2;
  const height = y + padding.v;
  switch (textAlign) {
    case TextAlignment.Center:
      lines.forEach((line) => {
        line.x = (width - line.w) * 0.5;
      });
      break;
    case TextAlignment.Right:
      lines.forEach((line) => {
        line.x = width - line.w - padding.h;
      });
      break;
  }
  ctx.translate(center.x, center.y);
  ctx.scale(scale, scale);
  if (rotate) {
    ctx.rotate(Math.PI * (rotate * 0.5));
  }
  ctx.translate(width * -0.5, height * -0.5);
  if (bgColor && bgColor.a) {
    ctx.fillStyle =
      "rgba(" +
      bgColor.r +
      "," +
      bgColor.g +
      "," +
      bgColor.b +
      "," +
      bgColor.a +
      ")";
    ctx.fillRect(0, 0, width, height);
  }
  ctx.fillStyle =
    "rgba(" +
    textColor.r +
    "," +
    textColor.g +
    "," +
    textColor.b +
    "," +
    textColor.a +
    ")";
  lines.forEach((line, i) => {
    ctx.fillText(textlines[i], line.x, line.y);
  });
  ctx.restore();
}

function drawSignature(
  ctx,
  pathLineDataArray,
  strokeWidth,
  color,
  origin,
  scale
) {
  ctx.save();
  ctx.lineWidth = strokeWidth;
  ctx.lineCap = "round";
  ctx.lineJoin = "round";
  ctx.translate(origin.x, origin.y);
  ctx.scale(scale, scale);
  ctx.strokeStyle = ctx.fillStyle =
    "rgba(" + color.r + "," + color.g + "," + color.b + "," + color.a + ")";
  for (let pathLineData of pathLineDataArray) {
    const pathLinePoints = pathLineData.points || [];
    const count = pathLinePoints.length;
    switch (count) {
      case 0:
        break;
      case 1:
        ctx.beginPath();
        ctx.arc(
          pathLinePoints[0].x,
          pathLinePoints[0].y,
          strokeWidth * 0.5,
          0,
          Math.PI * 2
        );
        ctx.fill();
        break;
      case 2:
        ctx.beginPath();
        ctx.moveTo(pathLinePoints[0].x, pathLinePoints[0].y);
        ctx.lineTo(pathLinePoints[1].x, pathLinePoints[1].y);
        ctx.stroke();
        break;
      case 3:
        ctx.beginPath();
        ctx.moveTo(pathLinePoints[0].x, pathLinePoints[0].y);
        ctx.quadraticCurveTo(
          pathLinePoints[1].x,
          pathLinePoints[1].y,
          pathLinePoints[2].x,
          pathLinePoints[2].y
        );
        ctx.stroke();
        break;
      case 4:
        ctx.beginPath();
        ctx.moveTo(pathLinePoints[0].x, pathLinePoints[0].y);
        ctx.bezierCurveTo(
          pathLinePoints[1].x,
          pathLinePoints[1].y,
          pathLinePoints[2].x,
          pathLinePoints[2].y,
          pathLinePoints[3].x,
          pathLinePoints[3].y
        );
        ctx.stroke();
        break;
      default: {
        // count > 4
        ctx.beginPath();
        ctx.moveTo(pathLinePoints[0].x, pathLinePoints[0].y);
        let i = 1;
        for (const segCount = count - 2; i < segCount; i += 3) {
          ctx.bezierCurveTo(
            pathLinePoints[i].x,
            pathLinePoints[i].y,
            pathLinePoints[i + 1].x,
            pathLinePoints[i + 1].y,
            pathLinePoints[i + 2].x,
            pathLinePoints[i + 2].y
          );
        }
        if (i < count - 1) {
          ctx.quadraticCurveTo(
            pathLinePoints[i].x,
            pathLinePoints[i].y,
            pathLinePoints[i + 1].x,
            pathLinePoints[i + 1].y
          );
        }
        ctx.stroke();
        break;
      }
    }
  }
  ctx.restore();
}

function baseImageSize(page) {
  let size = { width: page.image.width, height: page.image.height };
  const srcOrient = page.image.orientation % 4; // 0, 1, 2, 3
  const dstOrient = page.properties.image_orientation % 4; // 0, 1, 2, 3
  if (srcOrient != dstOrient) {
    let rot = rotateToChangeOrient(srcOrient, dstOrient);
    if (rot == 90 || rot == -90) {
      size = { width: size.height, height: size.width };
    }
  }
  return size;
}

function rotatedImageSize(page) {
  let size = baseImageSize(page);
  let rot = rotateBaseImageDegree(page);
  return rot == 0 || rot == 180
    ? size
    : { width: size.height, height: size.width };
}

function rotateBaseImageDegree(page) {
  const rotate_count = page.properties.rotate_count;
  if (rotate_count) {
    const srcOrient = page.properties.image_orientation % 4; // 0, 1, 2, 3
    const dstOrient = rotateOrient(srcOrient, rotate_count); // 0, 1, 2, 3
    return rotateToChangeOrient(srcOrient, dstOrient);
  }
  return 0;
}

function rotateSourceImageDegree(page) {
  const srcOrient = page.image.orientation % 4; // 0, 1, 2, 3
  const dstOrient = page.properties.image_orientation % 4; // 0, 1, 2, 3
  if (srcOrient != dstOrient) {
    return rotateToChangeOrient(srcOrient, dstOrient);
  }
  return 0;
}

async function loadRotatedImage(page) {
  let image = new Image();
  image.crossOrigin = "anonymous";
  image.src = page.image.download_url;
  await image.decode();
  let rot = rotateSourceImageDegree(page);
  if (rot) {
    let tmpCanvas = document.createElement("canvas");
    let size = baseImageSize(page);
    tmpCanvas.width = size.width;
    tmpCanvas.height = size.height;
    let tmpCtx = tmpCanvas.getContext("2d");
    tmpCtx.translate(size.width * 0.5, size.height * 0.5);
    tmpCtx.rotate(Math.PI * (rot / 180));
    tmpCtx.drawImage(image, page.image.width * -0.5, page.image.height * -0.5);
    const blob = await new Promise((resolve) =>
      tmpCanvas.toBlob(resolve, "image/jpeg", 0.5)
    );
    image.src = URL.createObjectURL(blob);
  }
  return image;
}

async function createJPEG_toBlob(page, mimeType, qualityArgument) {
  let image = await loadRotatedImage(page);
  let tmpCanvas = document.createElement("canvas");
  let size = rotatedImageSize(page);
  console.log("size: img: w = " + size.width + ", h = " + size.height);
  tmpCanvas.width = size.width;
  tmpCanvas.height = size.height;
  let tmpCtx = tmpCanvas.getContext("2d");
  let rot = rotateBaseImageDegree(page);
  if (rot) {
    tmpCtx.translate(size.width * 0.5, size.height * 0.5);
    if (rot) {
      tmpCtx.rotate(Math.PI * (rot / 180));
    }
  }
  let offset = rot
    ? [image.naturalWidth * -0.5, image.naturalHeight * -0.5]
    : [0, 0];
  console.log("offset: x = " + offset[0] + ", y = " + offset[1]);
  tmpCtx.drawImage(image, offset[0], offset[1]);
  if (page.properties.editing) {
    let canvas = document.createElement("canvas");
    await loadEditingToCanvas(page, canvas);
    let offset = rot ? [canvas.width * -0.5, canvas.height * -0.5] : [0, 0];
    console.log("canvas: w = " + canvas.width + ", h = " + canvas.height);
    tmpCtx.drawImage(canvas, offset[0], offset[1]);
  }
  const blob = await new Promise((resolve) =>
    tmpCanvas.toBlob(resolve, mimeType, qualityArgument)
  );
  return blob;
}

async function createJPEG_toArrayBuffer(
  page,
  mimeType,
  qualityArgument,
  image
) {
  const blob = await createJPEG_toBlob(page, mimeType, qualityArgument, image);
  const arrayBuffer = await blob.arrayBuffer();
  return arrayBuffer;
}

async function createPDF(pages, pageSize) {
  // create a new PDFDocument
  const pdfDoc = await PDFDocument.create();
  for (let i = 0; i < pages.length; ++i) {
    let page = pages[i];
    let jpegBuffer = await createJPEG_toArrayBuffer(page, "image/jpeg", 0.5);
    let jpgImage = await pdfDoc.embedJpg(jpegBuffer);

    // find best fit orientation
    let size = rotatedImageSize(page);
    let pSize =
      (pageSize.width > pageSize.height && size.width < size.height) ||
      (pageSize.width < pageSize.height && size.width > size.height)
        ? { width: pageSize.height, height: pageSize.width }
        : pageSize;
    pSize = aspectFitNoScaleUp(size, pSize);

    // add a blank page to the document
    let pdfPage = pdfDoc.addPage([pSize.width, pSize.height]);
    // render image on the page
    const scaled = jpgImage.scaleToFit(pSize.width, pSize.height);
    pdfPage.drawImage(jpgImage, {
      x: (pSize.width - scaled.width) * 0.5,
      y: (pSize.height - scaled.height) * 0.5,
      width: scaled.width,
      height: scaled.height,
    });
  }

  // serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
}

async function loadEditingToCanvas(page, canvas) {
  let size = baseImageSize(page);
  canvas.width = size.width;
  canvas.height = size.height;
  const object = await fetch_editing(page.properties.editing.download_url);
  let ctx = canvas.getContext("2d");
  if (object.pathLineDataArray) {
    for (let pathLineData of object.pathLineDataArray) {
      const vertexBuffer = pathLineData.vertexBuffer;
      if (vertexBuffer.length > 3) {
        const isEraser = pathLineData.isEraser;
        const colorRGBA = pathLineData.colorRGBA;
        const color =
          colorRGBA[3] > 0 && colorRGBA[3] < 1
            ? {
                r: Math.round((colorRGBA[0] / colorRGBA[3]) * 255),
                g: Math.round((colorRGBA[1] / colorRGBA[3]) * 255),
                b: Math.round((colorRGBA[2] / colorRGBA[3]) * 255),
                a: colorRGBA[3],
              }
            : {
                r: Math.round(colorRGBA[0] * 255),
                g: Math.round(colorRGBA[1] * 255),
                b: Math.round(colorRGBA[2] * 255),
                a: colorRGBA[3],
              };
        drawPaint(ctx, vertexBuffer, size, isEraser, color);
      }
    }
  }
  if (object.texts) {
    for (let text of object.texts) {
      const textStr = text.text || "";
      const scale = text.scale;
      const rotate = text.rotate || 0;
      const backgroundColorRGBA = text.backgroundColorRGBA;
      const textColorRGBA = text.textColorRGBA;
      const bgColor =
        backgroundColorRGBA && backgroundColorRGBA[3]
          ? {
              r: Math.round(backgroundColorRGBA[0] * 255),
              g: Math.round(backgroundColorRGBA[1] * 255),
              b: Math.round(backgroundColorRGBA[2] * 255),
              a: backgroundColorRGBA[3],
            }
          : null;
      const textColor = {
        r: Math.round(textColorRGBA[0] * 255),
        g: Math.round(textColorRGBA[1] * 255),
        b: Math.round(textColorRGBA[2] * 255),
        a: textColorRGBA[3],
      };
      const textAlign = text.textAlignment || TextAlignment.Left;
      const fontName = text.fontString;
      const fontFile = fonts.get_font_file(fontName);
      const font = new FontFace(
        fontName,
        "url(" + process.env.BASE_URL + "fonts/" + fontFile + ")"
      );
      // wait for font to be loaded
      await font.load();
      document.fonts.add(font);
      console.log("Font loaded: " + fontName);
      drawText(
        ctx,
        textStr,
        textAlign,
        textColor,
        bgColor,
        fontName,
        text.fontSize,
        text.center,
        scale,
        rotate,
        { h: 4, v: 8 }
      );
    }
  }
  if (object.signatures) {
    for (let signature of object.signatures) {
      const origin = signature.origin;
      const scale = signature.scale;
      const strokeColorRGBA = signature.strokeColorRGBA;
      const color = {
        r: Math.round(strokeColorRGBA[0] * 255),
        g: Math.round(strokeColorRGBA[1] * 255),
        b: Math.round(strokeColorRGBA[2] * 255),
        a: strokeColorRGBA[3],
      };
      drawSignature(
        ctx,
        signature.pathLineDataArray,
        signature.strokeWidth,
        color,
        origin,
        scale
      );
    }
  }
}

export {
  isMobileUserAgent,
  isEmpty,
  isRot90,
  isRot180,
  rotateOrient,
  rotateToChangeOrient,
  aspectFitNoScaleUp,
  my_dump,
  dateStringFromMS,
  measureTextlineHeight,
  measureTextlineWidth,
  TextAlignment,
  drawPaint,
  drawText,
  drawSignature,
  baseImageSize,
  rotatedImageSize,
  rotateBaseImageDegree,
  rotateSourceImageDegree,
  loadEditingToCanvas,
  createPDF,
  createJPEG_toBlob,
  createJPEG_toArrayBuffer,
  loadRotatedImage,
  PageSizeEnum,
  PageSizeAsPx,
};
