function verifiedFont() {
  return {
    "Menlo-Bold": "verified/Menlo-Bold.ttf",
    "PingFangHK-Semibold": "verified/PingFangHK-Semibold.ttf",
    "TimesNewRomanPS-BoldMT": "verified/TimesNewRomanPS-BoldMT.ttf",
    "NotoSansMyanmar-Bold": "verified/NotoSansMyanmar-Bold.ttf",
    "Arial-BoldMT": "verified/Arial-BoldMT.ttf",
    "Baskerville-SemiBoldItalic": "verified/Baskerville-SemiBoldItalic.ttf",
    Farah: "verified/Farah.ttf",
    Copperplate: "verified/Copperplate.ttf",
    DamascusBold: "verified/DamascusBold.ttf",
    "NotoSansMyanmar-Regular": "verified/NotoSansMyanmar-Regular.ttf",
    "DINAlternate-Bold": "verified/DINAlternate-Bold.ttf",
    "AlNile-Bold": "verified/AlNile-Bold.ttf",
    CourierNewPSMT: "verified/CourierNewPSMT.ttf",
    "PingFangSC-Light": "verified/PingFangSC-Light.ttf",
    "AppleSDGothicNeo-Light": "verified/AppleSDGothicNeo-Light.ttf",
    "AvenirNextCondensed-UltraLight":
      "verified/AvenirNextCondensed-UltraLight.ttf",
    "Baskerville-SemiBold": "verified/Baskerville-SemiBold.ttf",
    "Baskerville-BoldItalic": "verified/Baskerville-BoldItalic.ttf",
    "Courier-Bold": "verified/Courier-Bold.ttf",
    "BodoniSvtyTwoOSITCTT-BookIt": "verified/BodoniSvtyTwoOSITCTT-BookIt.ttf",
    "Menlo-Italic": "verified/Menlo-Italic.ttf",
    "AppleSDGothicNeo-Regular": "verified/AppleSDGothicNeo-Regular.ttf",
    "KohinoorDevanagari-Light": "verified/KohinoorDevanagari-Light.ttf",
    TamilSangamMN: "verified/TamilSangamMN.ttf",
    "AppleSDGothicNeo-Medium": "verified/AppleSDGothicNeo-Medium.ttf",
    Galvji: "verified/Galvji.ttf",
    "Noteworthy-Light": "verified/Noteworthy-Light.ttf",
    EuphemiaUCAS: "verified/EuphemiaUCAS.ttf",
    "ArialHebrew-Bold": "verified/ArialHebrew-Bold.ttf",
    "MarkerFelt-Wide": "verified/MarkerFelt-Wide.ttf",
    "HelveticaNeue-Italic": "verified/HelveticaNeue-Italic.ttf",
    "NotoNastaliqUrdu-Bold": "verified/NotoNastaliqUrdu-Bold.ttf",
    Courier: "verified/Courier.ttf",
    "GillSans-SemiBoldItalic": "verified/GillSans-SemiBoldItalic.ttf",
    "HelveticaNeue-MediumItalic": "verified/HelveticaNeue-MediumItalic.ttf",
    "HoeflerText-Black": "verified/HoeflerText-Black.ttf",
    "PingFangSC-Thin": "verified/PingFangSC-Thin.ttf",
    "PingFangHK-Ultralight": "verified/PingFangHK-Ultralight.ttf",
    "MarkerFelt-Thin": "verified/MarkerFelt-Thin.ttf",
    "CourierNewPS-BoldMT": "verified/CourierNewPS-BoldMT.ttf",
    ZapfDingbatsITC: "verified/ZapfDingbatsITC.ttf",
    "AvenirNextCondensed-Heavy": "verified/AvenirNextCondensed-Heavy.ttf",
    Verdana: "verified/Verdana.ttf",
    "SFProText-Light": "verified/SFProText-Light.otf",
    SinhalaSangamMN: "verified/SinhalaSangamMN.ttf",
    DamascusMedium: "verified/DamascusMedium.ttf",
    "Palatino-Italic": "verified/Palatino-Italic.ttf",
    KhmerSangamMN: "verified/KhmerSangamMN.ttf",
    "AvenirNextCondensed-UltraLightItalic":
      "verified/AvenirNextCondensed-UltraLightItalic.ttf",
    "Menlo-BoldItalic": "verified/Menlo-BoldItalic.ttf",
    "Avenir-Oblique": "verified/Avenir-Oblique.ttf",
    NotoNastaliqUrdu: "verified/NotoNastaliqUrdu.ttf",
    "SnellRoundhand-Bold": "verified/SnellRoundhand-Bold.ttf",
    "SFProText-MediumItalic": "verified/SFProText-MediumItalic.otf",
    Damascus: "verified/Damascus.ttf",
    "Georgia-BoldItalic": "verified/Georgia-BoldItalic.ttf",
    "Rockwell-Italic": "verified/Rockwell-Italic.ttf",
    "Futura-CondensedExtraBold": "verified/Futura-CondensedExtraBold.ttf",
    "BradleyHandITCTT-Bold": "verified/BradleyHandITCTT-Bold.ttf",
    "Rockwell-BoldItalic": "verified/Rockwell-BoldItalic.ttf",
    "Helvetica-Bold": "verified/Helvetica-Bold.ttf",
    "AvenirNext-UltraLight": "verified/AvenirNext-UltraLight.ttf",
    "Futura-CondensedMedium": "verified/Futura-CondensedMedium.ttf",
    "BodoniSvtyTwoITCTT-Book": "verified/BodoniSvtyTwoITCTT-Book.ttf",
    "PingFangTC-Medium": "verified/PingFangTC-Medium.ttf",
    "Cochin-BoldItalic": "verified/Cochin-BoldItalic.ttf",
    "AppleSDGothicNeo-UltraLight": "verified/AppleSDGothicNeo-UltraLight.ttf",
    "NotoSansKannada-Light": "verified/NotoSansKannada-Light.ttf",
    "SinhalaSangamMN-Bold": "verified/SinhalaSangamMN-Bold.ttf",
    "Optima-BoldItalic": "verified/Optima-BoldItalic.ttf",
    "Futura-Bold": "verified/Futura-Bold.ttf",
    "Galvji-Bold": "verified/Galvji-Bold.ttf",
    "GillSans-LightItalic": "verified/GillSans-LightItalic.ttf",
    "Arial-ItalicMT": "verified/Arial-ItalicMT.ttf",
    "AvenirNextCondensed-Regular": "verified/AvenirNextCondensed-Regular.ttf",
    "Georgia-Bold": "verified/Georgia-Bold.ttf",
    DevanagariSangamMN: "verified/DevanagariSangamMN.ttf",
    "GillSans-Bold": "verified/GillSans-Bold.ttf",
    "KohinoorDevanagari-Regular": "verified/KohinoorDevanagari-Regular.ttf",
    "Avenir-BlackOblique": "verified/Avenir-BlackOblique.ttf",
    "Avenir-Heavy": "verified/Avenir-Heavy.ttf",
    "BodoniSvtyTwoITCTT-Bold": "verified/BodoniSvtyTwoITCTT-Bold.ttf",
    DamascusSemiBold: "verified/DamascusSemiBold.ttf",
    DiwanMishafi: "verified/DiwanMishafi.ttf",
    "AvenirNext-Heavy": "verified/AvenirNext-Heavy.ttf",
    "AvenirNext-UltraLightItalic": "verified/AvenirNext-UltraLightItalic.ttf",
    PartyLetPlain: "verified/PartyLetPlain.ttf",
    "Baskerville-Bold": "verified/Baskerville-Bold.ttf",
    "ArialHebrew-Light": "verified/ArialHebrew-Light.ttf",
    "Kefa-Regular": "verified/Kefa-Regular.ttf",
    "AvenirNextCondensed-DemiBold": "verified/AvenirNextCondensed-DemiBold.ttf",
    SnellRoundhand: "verified/SnellRoundhand.ttf",
    "HelveticaNeue-UltraLight": "verified/HelveticaNeue-UltraLight.ttf",
    "ChalkboardSE-Light": "verified/ChalkboardSE-Light.ttf",
    Cochin: "verified/Cochin.ttf",
    "ChalkboardSE-Regular": "verified/ChalkboardSE-Regular.ttf",
    "HiraMinProN-W6": "verified/HiraMinProN-W6.ttf",
    "Avenir-Light": "verified/Avenir-Light.ttf",
    "HiraginoSans-W3": "verified/HiraginoSans-W3.ttf",
    "SFProText-Regular": "verified/SFProText-Regular.otf",
    "AppleSDGothicNeo-Bold": "verified/AppleSDGothicNeo-Bold.ttf",
    GillSans: "verified/GillSans.ttf",
    "PingFangTC-Ultralight": "verified/PingFangTC-Ultralight.ttf",
    "EuphemiaUCAS-Bold": "verified/EuphemiaUCAS-Bold.ttf",
    "GillSans-UltraBold": "verified/GillSans-UltraBold.ttf",
    Symbol: "verified/Symbol.ttf",
    SavoyeLetPlain: "verified/SavoyeLetPlain.ttf",
    "HoeflerText-Regular": "verified/HoeflerText-Regular.ttf",
    "GillSans-Light": "verified/GillSans-Light.ttf",
    "PingFangSC-Ultralight": "verified/PingFangSC-Ultralight.ttf",
    "KohinoorBangla-Regular": "verified/KohinoorBangla-Regular.ttf",
    Papyrus: "verified/Papyrus.ttf",
    "HiraginoSans-W7": "verified/HiraginoSans-W7.ttf",
    "AppleSDGothicNeo-Thin": "verified/AppleSDGothicNeo-Thin.ttf",
    "NotoSansMyanmar-Light": "verified/NotoSansMyanmar-Light.ttf",
    "PingFangHK-Medium": "verified/PingFangHK-Medium.ttf",
    "GillSans-Italic": "verified/GillSans-Italic.ttf",
    "HiraMinProN-W3": "verified/HiraMinProN-W3.ttf",
    Kailasa: "verified/Kailasa.ttf",
    "HiraginoSans-W6": "verified/HiraginoSans-W6.ttf",
    "Helvetica-Oblique": "verified/Helvetica-Oblique.ttf",
    "AvenirNext-Italic": "verified/AvenirNext-Italic.ttf",
    "Avenir-BookOblique": "verified/Avenir-BookOblique.ttf",
    "Charter-BlackItalic": "verified/Charter-BlackItalic.ttf",
    "PingFangHK-Thin": "verified/PingFangHK-Thin.ttf",
    "AvenirNextCondensed-Italic": "verified/AvenirNextCondensed-Italic.ttf",
    "ChalkboardSE-Bold": "verified/ChalkboardSE-Bold.ttf",
    "Charter-Italic": "verified/Charter-Italic.ttf",
    "Thonburi-Light": "verified/Thonburi-Light.ttf",
    "TamilSangamMN-Bold": "verified/TamilSangamMN-Bold.ttf",
    "PingFangTC-Thin": "verified/PingFangTC-Thin.ttf",
    "Cochin-Bold": "verified/Cochin-Bold.ttf",
    GeezaPro: "verified/GeezaPro.ttf",
    "MuktaMahee-Regular": "verified/MuktaMahee-Regular.ttf",
    "CourierNewPS-ItalicMT": "verified/CourierNewPS-ItalicMT.ttf",
    "Noteworthy-Bold": "verified/Noteworthy-Bold.ttf",
    "Trebuchet-BoldItalic": "verified/Trebuchet-BoldItalic.ttf",
    "PingFangTC-Light": "verified/PingFangTC-Light.ttf",
    "Palatino-Bold": "verified/Palatino-Bold.ttf",
    "HelveticaNeue-CondensedBold": "verified/HelveticaNeue-CondensedBold.ttf",
    "SFProText-Medium": "verified/SFProText-Medium.otf",
    AppleSymbols: "verified/AppleSymbols.ttf",
    ArialHebrew: "verified/ArialHebrew.ttf",
    "Optima-ExtraBlack": "verified/Optima-ExtraBlack.ttf",
    "GranthaSangamMN-Bold": "verified/GranthaSangamMN-Bold.ttf",
    AlNile: "verified/AlNile.ttf",
    "AvenirNext-DemiBold": "verified/AvenirNext-DemiBold.ttf",
    "Papyrus-Condensed": "verified/Papyrus-Condensed.ttf",
    "Verdana-Italic": "verified/Verdana-Italic.ttf",
    "TrebuchetMS-Bold": "verified/TrebuchetMS-Bold.ttf",
    "KohinoorDevanagari-Semibold": "verified/KohinoorDevanagari-Semibold.ttf",
    "HelveticaNeue-UltraLightItalic":
      "verified/HelveticaNeue-UltraLightItalic.ttf",
    "DevanagariSangamMN-Bold": "verified/DevanagariSangamMN-Bold.ttf",
    "KohinoorGujarati-Regular": "verified/KohinoorGujarati-Regular.ttf",
    "AvenirNext-HeavyItalic": "verified/AvenirNext-HeavyItalic.ttf",
    "PingFangHK-Regular": "verified/PingFangHK-Regular.ttf",
    "Courier-BoldOblique": "verified/Courier-BoldOblique.ttf",
    AcademyEngravedLetPlain: "verified/AcademyEngravedLetPlain.ttf",
    "GranthaSangamMN-Regular": "verified/GranthaSangamMN-Regular.ttf",
    "Rockwell-Bold": "verified/Rockwell-Bold.ttf",
    "Charter-BoldItalic": "verified/Charter-BoldItalic.ttf",
    "GeezaPro-Bold": "verified/GeezaPro-Bold.ttf",
    "CourierNewPS-BoldItalicMT": "verified/CourierNewPS-BoldItalicMT.ttf",
    AmericanTypewriter: "verified/AmericanTypewriter.ttf",
    "HelveticaNeue-CondensedBlack": "verified/HelveticaNeue-CondensedBlack.ttf",
    "Charter-Bold": "verified/Charter-Bold.ttf",
    "Charter-Black": "verified/Charter-Black.ttf",
    "Helvetica-LightOblique": "verified/Helvetica-LightOblique.ttf",
    TrebuchetMS: "verified/TrebuchetMS.ttf",
    MalayalamSangamMN: "verified/MalayalamSangamMN.ttf",
    "Copperplate-Light": "verified/Copperplate-Light.ttf",
    "HelveticaNeue-LightItalic": "verified/HelveticaNeue-LightItalic.ttf",
    "Avenir-Book": "verified/Avenir-Book.ttf",
    "MuktaMahee-Bold": "verified/MuktaMahee-Bold.ttf",
    "DINCondensed-Bold": "verified/DINCondensed-Bold.ttf",
    "SnellRoundhand-Black": "verified/SnellRoundhand-Black.ttf",
    "KohinoorTelugu-Light": "verified/KohinoorTelugu-Light.ttf",
    "MuktaMahee-Light": "verified/MuktaMahee-Light.ttf",
    "HelveticaNeue-BoldItalic": "verified/HelveticaNeue-BoldItalic.ttf",
    Thonburi: "verified/Thonburi.ttf",
    "AmericanTypewriter-Light": "verified/AmericanTypewriter-Light.ttf",
    AppleColorEmoji: "verified/AppleColorEmoji.ttf",
    "Avenir-MediumOblique": "verified/Avenir-MediumOblique.ttf",
    Didot: "verified/Didot.ttf",
    "Palatino-BoldItalic": "verified/Palatino-BoldItalic.ttf",
    "AvenirNextCondensed-Medium": "verified/AvenirNextCondensed-Medium.ttf",
    "Didot-Italic": "verified/Didot-Italic.ttf",
    "Optima-Bold": "verified/Optima-Bold.ttf",
    "Avenir-LightOblique": "verified/Avenir-LightOblique.ttf",
    Chalkduster: "verified/Chalkduster.ttf",
    "SFProText-Italic": "verified/SFProText-Italic.otf",
    NotoSansOriya: "verified/NotoSansOriya.ttf",
    "TrebuchetMS-Italic": "verified/TrebuchetMS-Italic.ttf",
    "NotoSansOriya-Bold": "verified/NotoSansOriya-Bold.ttf",
    "AvenirNext-MediumItalic": "verified/AvenirNext-MediumItalic.ttf",
    "Kailasa-Bold": "verified/Kailasa-Bold.ttf",
    "Avenir-Black": "verified/Avenir-Black.ttf",
    "AvenirNextCondensed-Bold": "verified/AvenirNextCondensed-Bold.ttf",
    "TimesNewRomanPS-BoldItalicMT": "verified/TimesNewRomanPS-BoldItalicMT.ttf",
    "KohinoorTelugu-Regular": "verified/KohinoorTelugu-Regular.ttf",
    "HiraMaruProN-W4": "verified/HiraMaruProN-W4.ttf",
    "SFProText-Bold": "verified/SFProText-Bold.otf",
    ArialMT: "verified/ArialMT.ttf",
    "KohinoorBangla-Semibold": "verified/KohinoorBangla-Semibold.ttf",
    BodoniOrnamentsITCTT: "verified/BodoniOrnamentsITCTT.ttf",
    "HelveticaNeue-Light": "verified/HelveticaNeue-Light.ttf",
    "AmericanTypewriter-Semibold": "verified/AmericanTypewriter-Semibold.ttf",
    "Copperplate-Bold": "verified/Copperplate-Bold.ttf",
    "HelveticaNeue-ThinItalic": "verified/HelveticaNeue-ThinItalic.ttf",
    "Courier-Oblique": "verified/Courier-Oblique.ttf",
    "AvenirNext-Medium": "verified/AvenirNext-Medium.ttf",
    HelveticaNeue: "verified/HelveticaNeue.ttf",
    "HoeflerText-BlackItalic": "verified/HoeflerText-BlackItalic.ttf",
    "PingFangSC-Medium": "verified/PingFangSC-Medium.ttf",
    "Charter-Roman": "verified/Charter-Roman.ttf",
    DamascusLight: "verified/DamascusLight.ttf",
    "Cochin-Italic": "verified/Cochin-Italic.ttf",
    "PingFangHK-Light": "verified/PingFangHK-Light.ttf",
    "GillSans-SemiBold": "verified/GillSans-SemiBold.ttf",
    "Georgia-Italic": "verified/Georgia-Italic.ttf",
    "AmericanTypewriter-Bold": "verified/AmericanTypewriter-Bold.ttf",
    Helvetica: "verified/Helvetica.ttf",
    "MalayalamSangamMN-Bold": "verified/MalayalamSangamMN-Bold.ttf",
    "SFProText-LightItalic": "verified/SFProText-LightItalic.otf",
    "TimesNewRomanPS-ItalicMT": "verified/TimesNewRomanPS-ItalicMT.ttf",
    "KohinoorGujarati-Light": "verified/KohinoorGujarati-Light.ttf",
    Baskerville: "verified/Baskerville.ttf",
    "AvenirNext-DemiBoldItalic": "verified/AvenirNext-DemiBoldItalic.ttf",
    LaoSangamMN: "verified/LaoSangamMN.ttf",
    "Optima-Regular": "verified/Optima-Regular.ttf",
    "HelveticaNeue-Medium": "verified/HelveticaNeue-Medium.ttf",
    "AmericanTypewriter-CondensedBold":
      "verified/AmericanTypewriter-CondensedBold.ttf",
    "Menlo-Regular": "verified/Menlo-Regular.ttf",
    "PingFangTC-Semibold": "verified/PingFangTC-Semibold.ttf",
    "KohinoorTelugu-Medium": "verified/KohinoorTelugu-Medium.ttf",
    "Verdana-BoldItalic": "verified/Verdana-BoldItalic.ttf",
    ArialRoundedMTBold: "verified/ArialRoundedMTBold.ttf",
    "EuphemiaUCAS-Italic": "verified/EuphemiaUCAS-Italic.ttf",
    "Baskerville-Italic": "verified/Baskerville-Italic.ttf",
    "Avenir-HeavyOblique": "verified/Avenir-HeavyOblique.ttf",
    "AvenirNextCondensed-MediumItalic":
      "verified/AvenirNextCondensed-MediumItalic.ttf",
    "Futura-Medium": "verified/Futura-Medium.ttf",
    "HelveticaNeue-Bold": "verified/HelveticaNeue-Bold.ttf",
    "Rockwell-Regular": "verified/Rockwell-Regular.ttf",
    "Helvetica-Light": "verified/Helvetica-Light.ttf",
    MyanmarSangamMN: "verified/MyanmarSangamMN.ttf",
    "Palatino-Roman": "verified/Palatino-Roman.ttf",
    Georgia: "verified/Georgia.ttf",
    "AvenirNext-BoldItalic": "verified/AvenirNext-BoldItalic.ttf",
    "KohinoorGujarati-Bold": "verified/KohinoorGujarati-Bold.ttf",
    Zapfino: "verified/Zapfino.ttf",
    "Didot-Bold": "verified/Didot-Bold.ttf",
    "AmericanTypewriter-Condensed": "verified/AmericanTypewriter-Condensed.ttf",
    "KohinoorBangla-Light": "verified/KohinoorBangla-Light.ttf",
    "AvenirNextCondensed-DemiBoldItalic":
      "verified/AvenirNextCondensed-DemiBoldItalic.ttf",
    "Avenir-Roman": "verified/Avenir-Roman.ttf",
    "GillSans-BoldItalic": "verified/GillSans-BoldItalic.ttf",
    "HelveticaNeue-Thin": "verified/HelveticaNeue-Thin.ttf",
    "AppleSDGothicNeo-SemiBold": "verified/AppleSDGothicNeo-SemiBold.ttf",
    "HoeflerText-Italic": "verified/HoeflerText-Italic.ttf",
    "SFProText-BoldItalic": "verified/SFProText-BoldItalic.otf",
    TimesNewRomanPSMT: "verified/TimesNewRomanPSMT.ttf",
    "Arial-BoldItalicMT": "verified/Arial-BoldItalicMT.ttf",
    "Futura-MediumItalic": "verified/Futura-MediumItalic.ttf",
    "BodoniSvtyTwoOSITCTT-Bold": "verified/BodoniSvtyTwoOSITCTT-Bold.ttf",
    "AvenirNextCondensed-HeavyItalic":
      "verified/AvenirNextCondensed-HeavyItalic.ttf",
    "AmericanTypewriter-CondensedLight":
      "verified/AmericanTypewriter-CondensedLight.ttf",
    "MyanmarSangamMN-Bold": "verified/MyanmarSangamMN-Bold.ttf",
    "Verdana-Bold": "verified/Verdana-Bold.ttf",
    "NotoSansKannada-Bold": "verified/NotoSansKannada-Bold.ttf",
    "PingFangSC-Semibold": "verified/PingFangSC-Semibold.ttf",
    "AvenirNext-Regular": "verified/AvenirNext-Regular.ttf",
    "Thonburi-Bold": "verified/Thonburi-Bold.ttf",
    "AvenirNext-Bold": "verified/AvenirNext-Bold.ttf",
    "PingFangTC-Regular": "verified/PingFangTC-Regular.ttf",
    "Optima-Italic": "verified/Optima-Italic.ttf",
    "PingFangSC-Regular": "verified/PingFangSC-Regular.ttf",
    "NotoSansKannada-Regular": "verified/NotoSansKannada-Regular.ttf",
    "Helvetica-BoldOblique": "verified/Helvetica-BoldOblique.ttf",
    "BodoniSvtyTwoOSITCTT-Book": "verified/BodoniSvtyTwoOSITCTT-Book.ttf",
    "BodoniSvtyTwoITCTT-BookIta": "verified/BodoniSvtyTwoITCTT-BookIta.ttf",
    "Avenir-Medium": "verified/Avenir-Medium.ttf",
    "AvenirNextCondensed-BoldItalic":
      "verified/AvenirNextCondensed-BoldItalic.ttf",
    "BodoniSvtyTwoSCITCTT-Book": "verified/BodoniSvtyTwoSCITCTT-Book.ttf",
  };
}

function otherFont() {
  return {
    SFUIMono: "other/SFUIMono.ttf",
    "NotoSansBatak-Regular": "other/NotoSansBatak-Regular.ttf",
    PhonepadTwo: "other/PhonepadTwo.ttf",
    "Seravek-Bold": "other/Seravek-Bold.ttf",
    "NotoSansGlagolitic-Regular": "other/NotoSansGlagolitic-Regular.ttf",
    "NotoSansHanifiRohingya-Regular":
      "other/NotoSansHanifiRohingya-Regular.ttf",
    "TheHandSerif-semibold": "other/TheHandSerif-semibold.otf",
    "DrukText-Bold": "other/DrukText-Bold.ttf",
    "NotoSansTirhuta-Regular": "other/NotoSansTirhuta-Regular.ttf",
    NewYorkItalic: "other/NewYorkItalic.ttf",
    STIXSizOneSymBol: "other/STIXSizOneSymBol.otf",
    "NotoSansTagbanwa-Regular": "other/NotoSansTagbanwa-Regular.ttf",
    "NotoSansWarangCiti-Regular": "other/NotoSansWarangCiti-Regular.ttf",
    "NotoSansTifinagh-Regular": "other/NotoSansTifinagh-Regular.ttf",
    "NotoSansKhojki-Regular": "other/NotoSansKhojki-Regular.ttf",
    SchemeBold: "other/SchemeBold.otf",
    "NotoSansCuneiform-Regular": "other/NotoSansCuneiform-Regular.ttf",
    STIXSizOneSymReg: "other/STIXSizOneSymReg.otf",
    "NotoSansYi-Regular": "other/NotoSansYi-Regular.ttf",
    "NotoSansTaiLe-Regular": "other/NotoSansTaiLe-Regular.ttf",
    "NotoSansBuginese-Regular": "other/NotoSansBuginese-Regular.ttf",
    "Seravek-Italic": "other/Seravek-Italic.ttf",
    "NotoSansCham-Regular": "other/NotoSansCham-Regular.ttf",
    "NotoSansMiao-Regular": "other/NotoSansMiao-Regular.ttf",
    "NotoSansMongolian-Regular": "other/NotoSansMongolian-Regular.ttf",
    "SFUISymbols-Regular": "other/SFUISymbols-Regular.otf",
    "NotoSansLinearA-Regular": "other/NotoSansLinearA-Regular.ttf",
    "NotoSansZawgyi-Light": "other/NotoSansZawgyi-Light.ttf",
    "NotoSansOldHungarian-Regular": "other/NotoSansOldHungarian-Regular.ttf",
    "NotoSansKaithi-Regular": "other/NotoSansKaithi-Regular.ttf",
    "SignPainter-Semibold": "other/SignPainter-Semibold.otf",
    "NotoSansCypriot-Regular": "other/NotoSansCypriot-Regular.ttf",
    STIXVarBol: "other/STIXVarBol.otf",
    STIXGeneral: "other/STIXGeneral.otf",
    STIXGeneralItalic: "other/STIXGeneralItalic.otf",
    "NotoSansPauCinHau-Regular": "other/NotoSansPauCinHau-Regular.ttf",
    "DrukWide-BoldItalic": "other/DrukWide-BoldItalic.ttf",
    Fallback: "other/Fallback.ttf",
    "DB_LCD_Temp-Black": "other/DB_LCD_Temp-Black.ttf",
    "NotoSansSamaritan-Regular": "other/NotoSansSamaritan-Regular.ttf",
    "NotoSansNewTaiLue-Regular": "other/NotoSansNewTaiLue-Regular.ttf",
    STIXVar: "other/STIXVar.otf",
    "NotoSansLisu-Regular": "other/NotoSansLisu-Regular.ttf",
    "Druk-Heavy": "other/Druk-Heavy.ttf",
    "Druk-SuperItalic": "other/Druk-SuperItalic.ttf",
    "NotoSansMultani-Regular": "other/NotoSansMultani-Regular.ttf",
    "NotoSansTakri-Regular": "other/NotoSansTakri-Regular.ttf",
    "NotoSansMandaic-Regular": "other/NotoSansMandaic-Regular.ttf",
    "Athelas-Regular": "other/Athelas-Regular.ttf",
    "Seravek-ExtraLight": "other/Seravek-ExtraLight.ttf",
    STIXSizFiveSymReg: "other/STIXSizFiveSymReg.otf",
    "NotoSansMendeKikakui-Regular": "other/NotoSansMendeKikakui-Regular.ttf",
    "NotoSansNKo-Regular": "other/NotoSansNKo-Regular.ttf",
    "NotoSansModi-Regular": "other/NotoSansModi-Regular.ttf",
    "NotoSansPahawhHmong-Regular": "other/NotoSansPahawhHmong-Regular.ttf",
    "NotoSansOldPersian-Regular": "other/NotoSansOldPersian-Regular.ttf",
    "NotoSansBrahmi-Regular": "other/NotoSansBrahmi-Regular.ttf",
    "NotoSansCoptic-Regular": "other/NotoSansCoptic-Regular.ttf",
    Carlito: "other/Carlito.ttf",
    "NotoSansOlChiki-Regular": "other/NotoSansOlChiki-Regular.ttf",
    "NotoSansAvestan-Regular": "other/NotoSansAvestan-Regular.ttf",
    "NotoSansLinearB-Regular": "other/NotoSansLinearB-Regular.ttf",
    "NotoSansSiddham-Regular": "other/NotoSansSiddham-Regular.ttf",
    "Marion-Regular": "other/Marion-Regular.ttf",
    "NotoSansHanunoo-Regular": "other/NotoSansHanunoo-Regular.ttf",
    "NotoSansPsalterPahlavi-Regular":
      "other/NotoSansPsalterPahlavi-Regular.ttf",
    "Athelas-BoldItalic": "other/Athelas-BoldItalic.ttf",
    STIXGeneralBol: "other/STIXGeneralBol.otf",
    "NotoSansGothic-Regular": "other/NotoSansGothic-Regular.ttf",
    "NotoSansBuhid-Regular": "other/NotoSansBuhid-Regular.ttf",
    "Seravek-LightItalic": "other/Seravek-LightItalic.ttf",
    Seravek: "other/Seravek.ttf",
    "NotoSansInscriptionalPahlavi-Regular":
      "other/NotoSansInscriptionalPahlavi-Regular.ttf",
    "NotoSansKhudawadi-Regular": "other/NotoSansKhudawadi-Regular.ttf",
    "NotoSerifBalinese-Regular": "other/NotoSerifBalinese-Regular.ttf",
    "NotoSansCaucasianAlbanian-Regular":
      "other/NotoSansCaucasianAlbanian-Regular.ttf",
    "NotoSansManichaean-Regular": "other/NotoSansManichaean-Regular.ttf",
    "NotoSansWancho-Regular": "other/NotoSansWancho-Regular.ttf",
    "NotoSansZawgyi-Bold": "other/NotoSansZawgyi-Bold.ttf",
    "Athelas-Italic": "other/Athelas-Italic.ttf",
    STIXSizFourSymReg: "other/STIXSizFourSymReg.otf",
    "Druk-HeavyItalic": "other/Druk-HeavyItalic.ttf",
    "Seravek-MediumItalic": "other/Seravek-MediumItalic.ttf",
    "NotoSansArmenian-Bold": "other/NotoSansArmenian-Bold.ttf",
    "NotoSansBassaVah-Regular": "other/NotoSansBassaVah-Regular.ttf",
    "Seravek-ExtraLightItalic": "other/Seravek-ExtraLightItalic.ttf",
    STIXNonUniIta: "other/STIXNonUniIta.otf",
    SFCompact: "other/SFCompact.ttf",
    STIXNonUni: "other/STIXNonUni.otf",
    "NotoSansSaurashtra-Regular": "other/NotoSansSaurashtra-Regular.ttf",
    PhoneKeyCaps: "other/PhoneKeyCaps.ttf",
    STIXSizFourSymBol: "other/STIXSizFourSymBol.otf",
    "NotoSansEgyptianHieroglyphs-Regular":
      "other/NotoSansEgyptianHieroglyphs-Regular.ttf",
    AsphaltMedium: "other/AsphaltMedium.otf",
    STIXIntUpReg: "other/STIXIntUpReg.otf",
    "NotoSansPhagsPa-Regular": "other/NotoSansPhagsPa-Regular.ttf",
    STIXSizTwoSymBol: "other/STIXSizTwoSymBol.otf",
    "NotoSansVai-Regular": "other/NotoSansVai-Regular.ttf",
    "DrukWide-Bold": "other/DrukWide-Bold.ttf",
    "NotoSansLycian-Regular": "other/NotoSansLycian-Regular.ttf",
    "NotoSansSundanese-Regular": "other/NotoSansSundanese-Regular.ttf",
    "NotoSansRejang-Regular": "other/NotoSansRejang-Regular.ttf",
    SFUI: "other/SFUI.ttf",
    STIXIntUpDBol: "other/STIXIntUpDBol.otf",
    "DrukWide-Medium": "other/DrukWide-Medium.ttf",
    "NotoSansNewa-Regular": "other/NotoSansNewa-Regular.ttf",
    "NotoSansSylotiNagri-Regular": "other/NotoSansSylotiNagri-Regular.ttf",
    "NotoSansMarchen-Regular": "other/NotoSansMarchen-Regular.ttf",
    SFCompactRounded: "other/SFCompactRounded.ttf",
    STIXIntUpBol: "other/STIXIntUpBol.otf",
    "NotoSansChakma-Regular": "other/NotoSansChakma-Regular.ttf",
    SFCompactItalic: "other/SFCompactItalic.ttf",
    STIXSizTwoSymReg: "other/STIXSizTwoSymReg.otf",
    "NotoSansLydian-Regular": "other/NotoSansLydian-Regular.ttf",
    BalegaRegular: "other/BalegaRegular.otf",
    STIXIntUpDReg: "other/STIXIntUpDReg.otf",
    LockClock: "other/LockClock.ttf",
    "NotoSansSyriac-Regular": "other/NotoSansSyriac-Regular.ttf",
    SFUIItalic: "other/SFUIItalic.ttf",
    "NotoSansDuployan-Regular": "other/NotoSansDuployan-Regular.ttf",
    "DrukWide-MediumItalic": "other/DrukWide-MediumItalic.ttf",
    BebasNeue: "other/BebasNeue.otf",
    STIXNonUniBolIta: "other/STIXNonUniBolIta.otf",
    "Carlito-BoldItalic": "other/Carlito-BoldItalic.ttf",
    "NotoSansZawgyi-Regular": "other/NotoSansZawgyi-Regular.ttf",
    "NotoSansUgaritic-Regular": "other/NotoSansUgaritic-Regular.ttf",
    "Carlito-Italic": "other/Carlito-Italic.ttf",
    "NotoSansShavian-Regular": "other/NotoSansShavian-Regular.ttf",
    "NotoSerifAhom-Regular": "other/NotoSerifAhom-Regular.ttf",
    "Druk-Medium": "other/Druk-Medium.ttf",
    "NotoSansOsmanya-Regular": "other/NotoSansOsmanya-Regular.ttf",
    "NotoSansHatran-Regular": "other/NotoSansHatran-Regular.ttf",
    "NotoSansMeeteiMayek-Regular": "other/NotoSansMeeteiMayek-Regular.ttf",
    "NotoSansArmenian-Regular": "other/NotoSansArmenian-Regular.ttf",
    SFUIRounded: "other/SFUIRounded.ttf",
    "NotoSansBamum-Regular": "other/NotoSansBamum-Regular.ttf",
    "NotoSansPalmyrene-Regular": "other/NotoSansPalmyrene-Regular.ttf",
    "NotoSansRunic-Regular": "other/NotoSansRunic-Regular.ttf",
    "NotoSansLimbu-Regular": "other/NotoSansLimbu-Regular.ttf",
    "NotoSansLepcha-Regular": "other/NotoSansLepcha-Regular.ttf",
    JournalSansInline: "other/JournalSansInline.otf",
    "NotoSansJavanese-Regular": "other/NotoSansJavanese-Regular.otf",
    "NotoSansOldItalic-Regular": "other/NotoSansOldItalic-Regular.ttf",
    "NotoSansBhaiksuki-Regular": "other/NotoSansBhaiksuki-Regular.ttf",
    SFUIMonoItalic: "other/SFUIMonoItalic.ttf",
    "Druk-BoldItalic": "other/Druk-BoldItalic.ttf",
    STIXSizThreeSymReg: "other/STIXSizThreeSymReg.otf",
    "Druk-MediumItalic": "other/Druk-MediumItalic.ttf",
    STIXIntSmBol: "other/STIXIntSmBol.otf",
    NewYork: "other/NewYork.ttf",
    "DearJoe-small-semibold": "other/DearJoe-small-semibold.ttf",
    "NotoSansImperialAramaic-Regular":
      "other/NotoSansImperialAramaic-Regular.ttf",
    "Marion-Bold": "other/Marion-Bold.ttf",
    STIXGeneralBolIta: "other/STIXGeneralBolIta.otf",
    "NotoSansTagalog-Regular": "other/NotoSansTagalog-Regular.ttf",
    STIXIntSmReg: "other/STIXIntSmReg.otf",
    "FaktSlabStencilPro-medium": "other/FaktSlabStencilPro-medium.otf",
    "Athelas-Bold": "other/Athelas-Bold.ttf",
    STIXSizThreeSymBol: "other/STIXSizThreeSymBol.otf",
    STIXNonUniBol: "other/STIXNonUniBol.otf",
    "NotoSansOldSouthArabian-Regular":
      "other/NotoSansOldSouthArabian-Regular.ttf",
    "NotoSansSoraSompeng-Regular": "other/NotoSansSoraSompeng-Regular.ttf",
    "Druk-Bold": "other/Druk-Bold.ttf",
    "NotoSansAdlam-Regular": "other/NotoSansAdlam-Regular.ttf",
    "NotoSansOsage-Regular": "other/NotoSansOsage-Regular.ttf",
    "NotoSansElbasan-Regular": "other/NotoSansElbasan-Regular.ttf",
    "NotoSansCarian-Regular": "other/NotoSansCarian-Regular.ttf",
    STIXIntUpSmBol: "other/STIXIntUpSmBol.otf",
    "NotoSansMro-Regular": "other/NotoSansMro-Regular.ttf",
    "NotoSansOgham-Regular": "other/NotoSansOgham-Regular.ttf",
    "NotoSansOldPermic-Regular": "other/NotoSansOldPermic-Regular.ttf",
    "NotoSansKharoshthi-Regular": "other/NotoSansKharoshthi-Regular.ttf",
    "NotoSansMahajani-Regular": "other/NotoSansMahajani-Regular.ttf",
    STIXIntDBol: "other/STIXIntDBol.otf",
    "Marion-Italic": "other/Marion-Italic.ttf",
    "NotoSansTaiTham-Regular": "other/NotoSansTaiTham-Regular.ttf",
    "NotoSansInscriptionalParthian-Regular":
      "other/NotoSansInscriptionalParthian-Regular.ttf",
    "Seravek-BoldItalic": "other/Seravek-BoldItalic.ttf",
    "NotoSansSharada-Regular": "other/NotoSansSharada-Regular.ttf",
    "Carlito-Bold": "other/Carlito-Bold.ttf",
    "NotoSansOldNorthArabian-Regular":
      "other/NotoSansOldNorthArabian-Regular.ttf",
    STIXIntUpSmReg: "other/STIXIntUpSmReg.otf",
    AppleGothic: "other/AppleGothic.otf",
    "Seravek-Medium": "other/Seravek-Medium.ttf",
    "NotoSansOldTurkic-Regular": "other/NotoSansOldTurkic-Regular.ttf",
    "Druk-Super": "other/Druk-Super.ttf",
    "NotoSansPhoenician-Regular": "other/NotoSansPhoenician-Regular.ttf",
    "NotoSansArmenian-Light": "other/NotoSansArmenian-Light.ttf",
    "NotoSansThaana-Regular": "other/NotoSansThaana-Regular.ttf",
    "NotoSansMeroitic-Regular": "other/NotoSansMeroitic-Regular.ttf",
    STIXIntDReg: "other/STIXIntDReg.otf",
    "NotoSansTaiViet-Regular": "other/NotoSansTaiViet-Regular.ttf",
    "Seravek-Light": "other/Seravek-Light.ttf",
    "NotoSansKayahLi-Regular": "other/NotoSansKayahLi-Regular.ttf",
    "NotoSansNabataean-Regular": "other/NotoSansNabataean-Regular.ttf",
  };
}

export function get_font_file(fontName) {
  var font = verifiedFont()[fontName];
  if (font == null) {
    font = otherFont()[fontName];
  }
  if (font == null) {
    font = verifiedFont()["HelveticaNeue"];
  }
  return font;
}
