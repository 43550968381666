<template>
  <div v-if="loaded">
    <div ref="galleryContainer" style="width: 100%; height: 100vh"></div>
    <Lightgallery
      v-if="galleryContainer && galleryItems.length"
      :settings="gallerySettings"
      :onInit="galleryInit"
      :onImageLoad="galleryImageLoaded"
    >
    </Lightgallery>
  </div>
  <div v-else></div>
</template>

<script>
import { mapGetters } from "vuex";
import * as utils from "@/utils";
import axios from "axios";
import { message } from "ant-design-vue";
import Lightgallery from "lightgallery/dist/vue";
import lgZoom from "lightgallery/dist/plugins/zoom/lg-zoom.min.js";
import lgRotate from "lightgallery/dist/plugins/rotate/lg-rotate.min.js";
import lgThumbnail from "lightgallery/dist/plugins/thumbnail/lg-thumbnail.min.js";
import "lightgallery/dist/css/lightgallery.css";
import "lightgallery/dist/css/lg-thumbnail.css";
import "lightgallery/dist/css/lg-rotate.css";
import "lightgallery/dist/css/lg-zoom.css";
//import "lightgallery/dist/css/lightgallery-bundle.min.css";

let download = require("downloadjs/download.min.js");
//import download from "downloadjs";

export default {
  name: "PageGalleryView",
  components: {
    Lightgallery,
  },
  props: {
    backupId: String,
    docId: String,
  },
  data() {
    return {
      loadingKey: "processing",
      galleryContainer: this.$refs.galleryContainer,
      galleryItems: [],
      pageList: [],
      thumbnails: [],
      docProps: {},
      imageLoadTimes: {},
    };
  },
  computed: {
    ...mapGetters(["appToken", "userToken"]),
    loaded: function () {
      return this.galleryItems && this.galleryItems.length > 0;
    },
    gallerySettings: function () {
      return {
        licenseKey: "18DB8682-EDB1445F-94C8BA78-7F43FB29",
        plugins: [lgZoom, lgThumbnail, lgRotate],
        container: this.galleryContainer,
        loop: false,
        closable: false,
        preload: 2,
        thumbWidth: 82,
        thumbHeight: "106px",
        dynamic: true,
        dynamicEl: this.galleryItems,
        thumbnail: true,
        swipeToClose: false,
        addClass: "lg-inline",
        hash: false,
        pager: false,
        rotateLeft: false,
        rotateRight: false,
        flipHorizontal: false,
        flipVertical: false,
        showMaximizeIcon: false,
        download: false,
        showZoomInOutIcons: false,
        appendSubHtmlTo: ".lg-item",
      };
    },
  },
  methods: {
    galleryInit: function (detail) {
      let lightGallery = detail.instance;
      lightGallery.openGallery(0);
      // const backButton =
      //   "<button type='button' aria-label='GoBack' class='lg-close lg-icon' id='lg-back'></button>";
      const downloadPDFButton =
        "<button type='button' aria-label='DownloadPDF' class='lg-download lg-icon' id='lg-download-pdf'></button>";
      // lightGallery.outer.find(".lg-toolbar").prepend(backButton);
      lightGallery.outer.find(".lg-toolbar").append(downloadPDFButton);
      // lightGallery.outer.find("#lg-back").on("click", this.goBack);
      lightGallery.outer.find("#lg-download-pdf").on("click", this.downloadPDF);
    },
    goBack: function () {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        window.close();
      }
    },
    downloadPDF: function () {
      console.log("downloadPDF");
      // utils
      //   .createJPEG_toArrayBuffer(this.pageList[0], "image/jpeg", 0.5)
      //   .then((jpgBuffer) => {
      //     download(new Uint8Array(jpgBuffer), "page.jpg", "image/jpeg");
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
      let hide = message.loading({
        content: "Preparing PDF...",
        key: this.loadingKey,
      });
      let pageSizeNum = this.docProps.pageSizeNum || 0;
      let pageSize =
        pageSizeNum == utils.PageSizeEnum.Custom
          ? {
              width: this.docProps.customPageSizeWidth * 72,
              height: this.docProps.customPageSizeHeight * 72,
            }
          : utils.PageSizeAsPx[pageSizeNum];
      let title = this.docProps.title;
      let filename = title
        ? title.replace(/[/\\?%*:|"<>]/g, "-") + ".pdf"
        : "download.pdf";
      utils
        .createPDF(this.pageList, pageSize)
        .then((pdfBytes) => {
          download(pdfBytes, filename, "application/pdf");
          hide();
        })
        .catch((err) => {
          console.error(err);
          message.error({
            content: err,
            key: this.loadingKey,
            duration: 3,
          });
        });
    },
    galleryImageLoaded: function (e) {
      const canvasId = e.canvasId;
      const index = e.index;
      let key = "" + index;
      let timeInMs = Date.now();
      let timestamp = this.imageLoadTimes[key];
      this.imageLoadTimes[key] = timeInMs;
      if (timestamp && timeInMs < timestamp + 500) {
        return;
      }
      let item = this.galleryItems[index];
      console.log(
        "galleryImageLoaded: canvasId = " +
          canvasId +
          ", index = " +
          index +
          ", imgRot = " +
          item.imgRotate +
          ", outRot = " +
          item.containerRotate
      );
      const page = this.pageList[index];
      if (page.properties.editing) {
        let canvas = document.getElementById("canvas-" + index);
        utils.loadEditingToCanvas(page, canvas);
      }
    },
  },
  watch: {
    loaded: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.$nextTick(() => {
          this.galleryContainer = this.$refs.galleryContainer;
        });
      }
    },
  },
  mounted: function () {
    message.config({
      top: "40px",
      maxCount: 3,
    });
    const url = process.env.VUE_APP_API + "/show_doc_contents";
    const params = {
      backupUUID: this.backupId,
      doc_uuid: this.docId,
      user_token: this.userToken,
    };
    console.log("show_doc_contents: " + url);
    console.log("backupUUID: " + params.backupUUID);
    console.log("doc_uuid: " + params.doc_uuid);
    console.log("user_token: " + params.user_token);
    axios
      .post(url, params)
      .then((res) => {
        if (res.data.error) {
          console.log("error = " + res.data.error);
          if (res.data.error === "invalid_session") {
            this.$router.push({
              path: "/login",
              name: "LoginView",
            });
          }
        } else if (res.data.result) {
          this.docProps = res.data.result.properties;
          this.pageList = res.data.result.pages;
          this.thumbnails = this.pageList.map(
            (element) => element.preview.download_url
          );
          // console.log(utils.my_dump(this.pageList, "this.pageList", false));
          this.galleryItems = this.pageList.map(function (el, idx) {
            return {
              src: el.image.download_url,
              thumb: el.preview.download_url,
              download_url: el.image.download_url,
              canvasId: "canvas-" + idx,
              imgRotate: utils
                .rotateSourceImageDegree(this.pageList[idx])
                .toString(),
              containerRotate: utils
                .rotateBaseImageDegree(this.pageList[idx])
                .toString(),
            };
          }, this);
          document.title = this.docProps.title;
        }
      })
      .catch((err) => {
        console.error(err);
        this.pageList = [];
        this.thumbnails = [];
      });
  },
};
</script>

<style lang="scss">
.lg-outer .lg-thumb-item {
  border-color: transparent;
}
</style>
