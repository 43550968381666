<template>
  <!-- Root element of PhotoSwipe. Must have class pswp. -->
  <div
    class="pswp rootdiv"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    ref="galleryContainer"
  >
    <!-- Background of PhotoSwipe. 
         It's a separate element as animating opacity is faster than rgba(). -->
    <div class="pswp__bg"></div>

    <!-- Slides wrapper with overflow:hidden. -->
    <div class="pswp__scroll-wrap">
      <!-- Container that holds slides. 
            PhotoSwipe keeps only 3 of them in the DOM to save memory.
            Don't modify these 3 pswp__item elements, data is added later on. -->
      <div class="pswp__container">
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
      </div>

      <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <!--  Controls are self-explanatory. Order can be changed. -->

          <div class="pswp__counter"></div>

          <!-- <button
            class="pswp__button pswp__button--close"
            title="Close (Esc)"
          ></button> -->

          <button
            class="pswp__button pswp__button--share"
            title="Share"
          ></button>

          <button
            class="pswp__button pswp__button--fs"
            title="Toggle fullscreen"
          ></button>

          <button
            class="pswp__button pswp__button--zoom"
            title="Zoom in/out"
          ></button>

          <button
            class="pswp__button pswp__button--download"
            title="Download"
            :onclick="downloadPDF"
          ></button>

          <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
          <!-- element will get class pswp__preloader--active when preloader is running -->
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"
        >
          <div class="pswp__share-tooltip"></div>
        </div>

        <button
          class="pswp__button pswp__button--arrow--left"
          title="Previous (arrow left)"
        ></button>

        <button
          class="pswp__button pswp__button--arrow--right"
          title="Next (arrow right)"
        ></button>

        <div class="pswp__caption">
          <div class="pswp__caption__center"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as utils from "@/utils";
import axios from "axios";
import { message } from "ant-design-vue";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import PhotoSwipe from "photoswipe/dist/photoswipe.js";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.js";

//let download = require("downloadjs/download.min.js");
import download from "downloadjs/download.min.js";

export default {
  name: "PageGalleryView2",
  props: {
    backupId: String,
    docId: String,
  },
  data() {
    return {
      loadingKey: "processing",
      galleryContainer: null,
      galleryItems: [],
      gallery: null,
      pageList: [],
      thumbnails: [],
      docProps: {},
      imageLoadTimes: {},
      galleryOptions: {
        index: 0, // start at first slide
        escKey: false,
        loop: false,
        history: false,
        fullscreenEl: false,
        pinchToClose: false,
        closeOnScroll: false,
        closeOnVerticalDrag: false,
        shareEl: false,
        tapToToggleControls: false,
        clickToCloseNonZoomable: false,
        closeElClasses: [],
      },
    };
  },
  computed: {
    ...mapGetters(["appToken", "userToken"]),
    loaded: function () {
      return this.galleryItems && this.galleryItems.length > 0;
    },
  },
  methods: {
    goBack: function () {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        window.close();
      }
    },
    downloadPDF: function () {
      console.log("downloadPDF");
      // utils
      //   .createJPEG_toArrayBuffer(this.pageList[0], "image/jpeg", 0.5)
      //   .then((jpgBuffer) => {
      //     download(new Uint8Array(jpgBuffer), "page.jpg", "image/jpeg");
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
      let hide = message.loading({
        content: "Preparing PDF...",
        key: this.loadingKey,
      });
      let pageSizeNum = this.docProps.pageSizeNum || 0;
      let pageSize =
        pageSizeNum == utils.PageSizeEnum.Custom
          ? {
              width: this.docProps.customPageSizeWidth * 72,
              height: this.docProps.customPageSizeHeight * 72,
            }
          : utils.PageSizeAsPx[pageSizeNum];
      let title = this.docProps.title;
      let filename = title
        ? title.replace(/[/\\?%*:|"<>]/g, "-") + ".pdf"
        : "download.pdf";
      utils
        .createPDF(this.pageList, pageSize)
        .then((pdfBytes) => {
          download(pdfBytes, filename, "application/pdf");
          hide();
        })
        .catch((err) => {
          console.error(err);
          message.error({
            content: err,
            key: this.loadingKey,
            duration: 3,
          });
        });
    },
    loadImage: async function (page, item) {
      let image = item.img;
      image.style.opacity = 0;
      let blob = await utils.createJPEG_toBlob(page, "image/jpeg", 0.5);
      item.src = image.src = URL.createObjectURL(blob);
      image.style.opacity = 1;
    },
    galleryImageLoaded: function (index, gItem) {
      let key = "" + index;
      let timeInMs = Date.now();
      let timestamp = this.imageLoadTimes[key];
      this.imageLoadTimes[key] = timeInMs;
      if (timestamp && timeInMs < timestamp + 500) {
        return;
      }
      let item = this.galleryItems[index];
      console.log(
        "galleryImageLoaded: index = " +
          index +
          ", imgRot = " +
          item.imgRotate +
          ", outRot = " +
          item.containerRotate
      );
      const page = this.pageList[index];
      if (item.imgRotate || item.containerRotate || page.properties.editing) {
        this.loadImage(page, gItem);
      }
    },
  },
  mounted: function () {
    this.galleryContainer = this.$refs.galleryContainer;
    message.config({
      top: "40px",
      maxCount: 3,
    });
    const url = process.env.VUE_APP_API + "/show_doc_contents";
    const params = {
      backupUUID: this.backupId,
      doc_uuid: this.docId,
      user_token: this.userToken,
    };
    console.log("show_doc_contents: " + url);
    console.log("backupUUID: " + params.backupUUID);
    console.log("doc_uuid: " + params.doc_uuid);
    console.log("user_token: " + params.user_token);
    axios
      .post(url, params)
      .then((res) => {
        if (res.data.error) {
          console.log("error = " + res.data.error);
          if (res.data.error === "invalid_session") {
            this.$router.push({
              path: "/login",
              name: "LoginView",
            });
          }
        } else if (res.data.result) {
          this.docProps = res.data.result.properties;
          this.pageList = res.data.result.pages;
          this.thumbnails = this.pageList.map(
            (element) => element.preview.download_url
          );
          // console.log(utils.my_dump(this.pageList, "this.pageList", false));
          this.galleryItems = this.pageList.map(function (el, idx) {
            let page = this.pageList[idx];
            let size = utils.rotatedImageSize(page);
            return {
              src: el.image.download_url,
              thumb: el.preview.download_url,
              download_url: el.image.download_url,
              imgRotate: utils.rotateSourceImageDegree(page),
              containerRotate: utils.rotateBaseImageDegree(page),
              w: size.width,
              h: size.height,
            };
          }, this);
          document.title = this.docProps.title;
          // initializes and opens PhotoSwipe
          this.gallery = new PhotoSwipe(
            this.galleryContainer,
            PhotoSwipeUI_Default,
            this.galleryItems,
            this.galleryOptions
          );
          this.gallery.listen(
            "destroy",
            function () {
              this.$nextTick(() => {
                this.goBack();
              });
            }.bind(this)
          );
          this.gallery.listen(
            "imageLoadComplete",
            function (index, item) {
              this.galleryImageLoaded(index, item);
            }.bind(this)
          );
          this.gallery.init();
        }
      })
      .catch((err) => {
        console.error(err);
        this.pageList = [];
        this.thumbnails = [];
      });
  },
};
</script>

<style lang="scss">
.ant-message {
  z-index: 9999;
}
</style>
<style lang="scss" scoped>
.rootdiv {
  background-color: black;
}
.pswp__button--download,
.pswp__button--download:before {
  background: url("~@/assets/download.png") 12px 12px no-repeat;
  background-size: 20px 20px;
  width: 44px;
  height: 44px;
}
</style>
