/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

import { Reader, Writer, util, roots } from "protobufjs/minimal";

// Common aliases
var $Reader = Reader,
  $Writer = Writer,
  $util = util;

// Exported root namespace
var $root = roots["default"] || (roots["default"] = {});

$root.BackupPageFile = (function () {
  /**
   * Properties of a BackupPageFile.
   * @exports IBackupPageFile
   * @interface IBackupPageFile
   * @property {string|null} [identifier] BackupPageFile identifier
   * @property {string|null} [uuid] BackupPageFile uuid
   * @property {Array.<number>|null} [imageSize] BackupPageFile imageSize
   * @property {number|null} [orientation] BackupPageFile orientation
   * @property {number|Long|null} [fileSize] BackupPageFile fileSize
   */

  /**
   * Constructs a new BackupPageFile.
   * @exports BackupPageFile
   * @classdesc Represents a BackupPageFile.
   * @implements IBackupPageFile
   * @constructor
   * @param {IBackupPageFile=} [properties] Properties to set
   */
  function BackupPageFile(properties) {
    this.imageSize = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPageFile identifier.
   * @member {string} identifier
   * @memberof BackupPageFile
   * @instance
   */
  BackupPageFile.prototype.identifier = "";

  /**
   * BackupPageFile uuid.
   * @member {string} uuid
   * @memberof BackupPageFile
   * @instance
   */
  BackupPageFile.prototype.uuid = "";

  /**
   * BackupPageFile imageSize.
   * @member {Array.<number>} imageSize
   * @memberof BackupPageFile
   * @instance
   */
  BackupPageFile.prototype.imageSize = $util.emptyArray;

  /**
   * BackupPageFile orientation.
   * @member {number} orientation
   * @memberof BackupPageFile
   * @instance
   */
  BackupPageFile.prototype.orientation = 0;

  /**
   * BackupPageFile fileSize.
   * @member {number|Long} fileSize
   * @memberof BackupPageFile
   * @instance
   */
  BackupPageFile.prototype.fileSize = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * Creates a new BackupPageFile instance using the specified properties.
   * @function create
   * @memberof BackupPageFile
   * @static
   * @param {IBackupPageFile=} [properties] Properties to set
   * @returns {BackupPageFile} BackupPageFile instance
   */
  BackupPageFile.create = function create(properties) {
    return new BackupPageFile(properties);
  };

  /**
   * Encodes the specified BackupPageFile message. Does not implicitly {@link BackupPageFile.verify|verify} messages.
   * @function encode
   * @memberof BackupPageFile
   * @static
   * @param {IBackupPageFile} message BackupPageFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageFile.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.identifier != null &&
      Object.hasOwnProperty.call(message, "identifier")
    )
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.identifier);
    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.uuid);
    if (message.imageSize != null && message.imageSize.length) {
      writer.uint32(/* id 3, wireType 2 =*/ 26).fork();
      for (var i = 0; i < message.imageSize.length; ++i)
        writer.double(message.imageSize[i]);
      writer.ldelim();
    }
    if (
      message.orientation != null &&
      Object.hasOwnProperty.call(message, "orientation")
    )
      writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.orientation);
    if (
      message.fileSize != null &&
      Object.hasOwnProperty.call(message, "fileSize")
    )
      writer.uint32(/* id 5, wireType 0 =*/ 40).uint64(message.fileSize);
    return writer;
  };

  /**
   * Encodes the specified BackupPageFile message, length delimited. Does not implicitly {@link BackupPageFile.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPageFile
   * @static
   * @param {IBackupPageFile} message BackupPageFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageFile.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPageFile message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPageFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPageFile} BackupPageFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageFile.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPageFile();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 2:
          message.uuid = reader.string();
          break;
        case 3:
          if (!(message.imageSize && message.imageSize.length))
            message.imageSize = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) message.imageSize.push(reader.double());
          } else message.imageSize.push(reader.double());
          break;
        case 4:
          message.orientation = reader.int32();
          break;
        case 5:
          message.fileSize = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPageFile message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPageFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPageFile} BackupPageFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageFile.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPageFile message.
   * @function verify
   * @memberof BackupPageFile
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPageFile.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      if (!$util.isString(message.identifier))
        return "identifier: string expected";
    if (message.uuid != null && message.hasOwnProperty("uuid"))
      if (!$util.isString(message.uuid)) return "uuid: string expected";
    if (message.imageSize != null && message.hasOwnProperty("imageSize")) {
      if (!Array.isArray(message.imageSize)) return "imageSize: array expected";
      for (var i = 0; i < message.imageSize.length; ++i)
        if (typeof message.imageSize[i] !== "number")
          return "imageSize: number[] expected";
    }
    if (message.orientation != null && message.hasOwnProperty("orientation"))
      if (!$util.isInteger(message.orientation))
        return "orientation: integer expected";
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (
        !$util.isInteger(message.fileSize) &&
        !(
          message.fileSize &&
          $util.isInteger(message.fileSize.low) &&
          $util.isInteger(message.fileSize.high)
        )
      )
        return "fileSize: integer|Long expected";
    return null;
  };

  /**
   * Creates a BackupPageFile message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPageFile
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPageFile} BackupPageFile
   */
  BackupPageFile.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPageFile) return object;
    var message = new $root.BackupPageFile();
    if (object.identifier != null)
      message.identifier = String(object.identifier);
    if (object.uuid != null) message.uuid = String(object.uuid);
    if (object.imageSize) {
      if (!Array.isArray(object.imageSize))
        throw TypeError(".BackupPageFile.imageSize: array expected");
      message.imageSize = [];
      for (var i = 0; i < object.imageSize.length; ++i)
        message.imageSize[i] = Number(object.imageSize[i]);
    }
    if (object.orientation != null)
      message.orientation = object.orientation | 0;
    if (object.fileSize != null)
      if ($util.Long)
        (message.fileSize = $util.Long.fromValue(
          object.fileSize
        )).unsigned = true;
      else if (typeof object.fileSize === "string")
        message.fileSize = parseInt(object.fileSize, 10);
      else if (typeof object.fileSize === "number")
        message.fileSize = object.fileSize;
      else if (typeof object.fileSize === "object")
        message.fileSize = new $util.LongBits(
          object.fileSize.low >>> 0,
          object.fileSize.high >>> 0
        ).toNumber(true);
    return message;
  };

  /**
   * Creates a plain object from a BackupPageFile message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPageFile
   * @static
   * @param {BackupPageFile} message BackupPageFile
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPageFile.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.imageSize = [];
    if (options.defaults) {
      object.identifier = "";
      object.uuid = "";
      object.orientation = 0;
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.fileSize =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.fileSize = options.longs === String ? "0" : 0;
    }
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      object.identifier = message.identifier;
    if (message.uuid != null && message.hasOwnProperty("uuid"))
      object.uuid = message.uuid;
    if (message.imageSize && message.imageSize.length) {
      object.imageSize = [];
      for (var j = 0; j < message.imageSize.length; ++j)
        object.imageSize[j] =
          options.json && !isFinite(message.imageSize[j])
            ? String(message.imageSize[j])
            : message.imageSize[j];
    }
    if (message.orientation != null && message.hasOwnProperty("orientation"))
      object.orientation = message.orientation;
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (typeof message.fileSize === "number")
        object.fileSize =
          options.longs === String
            ? String(message.fileSize)
            : message.fileSize;
      else
        object.fileSize =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.fileSize)
            : options.longs === Number
            ? new $util.LongBits(
                message.fileSize.low >>> 0,
                message.fileSize.high >>> 0
              ).toNumber(true)
            : message.fileSize;
    return object;
  };

  /**
   * Converts this BackupPageFile to JSON.
   * @function toJSON
   * @memberof BackupPageFile
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPageFile.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPageFile;
})();

$root.BackupPageEditingFile = (function () {
  /**
   * Properties of a BackupPageEditingFile.
   * @exports IBackupPageEditingFile
   * @interface IBackupPageEditingFile
   * @property {string|null} [identifier] BackupPageEditingFile identifier
   * @property {string|null} [hash] BackupPageEditingFile hash
   * @property {number|Long|null} [fileSize] BackupPageEditingFile fileSize
   */

  /**
   * Constructs a new BackupPageEditingFile.
   * @exports BackupPageEditingFile
   * @classdesc Represents a BackupPageEditingFile.
   * @implements IBackupPageEditingFile
   * @constructor
   * @param {IBackupPageEditingFile=} [properties] Properties to set
   */
  function BackupPageEditingFile(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPageEditingFile identifier.
   * @member {string} identifier
   * @memberof BackupPageEditingFile
   * @instance
   */
  BackupPageEditingFile.prototype.identifier = "";

  /**
   * BackupPageEditingFile hash.
   * @member {string} hash
   * @memberof BackupPageEditingFile
   * @instance
   */
  BackupPageEditingFile.prototype.hash = "";

  /**
   * BackupPageEditingFile fileSize.
   * @member {number|Long} fileSize
   * @memberof BackupPageEditingFile
   * @instance
   */
  BackupPageEditingFile.prototype.fileSize = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * Creates a new BackupPageEditingFile instance using the specified properties.
   * @function create
   * @memberof BackupPageEditingFile
   * @static
   * @param {IBackupPageEditingFile=} [properties] Properties to set
   * @returns {BackupPageEditingFile} BackupPageEditingFile instance
   */
  BackupPageEditingFile.create = function create(properties) {
    return new BackupPageEditingFile(properties);
  };

  /**
   * Encodes the specified BackupPageEditingFile message. Does not implicitly {@link BackupPageEditingFile.verify|verify} messages.
   * @function encode
   * @memberof BackupPageEditingFile
   * @static
   * @param {IBackupPageEditingFile} message BackupPageEditingFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageEditingFile.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.identifier != null &&
      Object.hasOwnProperty.call(message, "identifier")
    )
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.identifier);
    if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.hash);
    if (
      message.fileSize != null &&
      Object.hasOwnProperty.call(message, "fileSize")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.fileSize);
    return writer;
  };

  /**
   * Encodes the specified BackupPageEditingFile message, length delimited. Does not implicitly {@link BackupPageEditingFile.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPageEditingFile
   * @static
   * @param {IBackupPageEditingFile} message BackupPageEditingFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageEditingFile.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPageEditingFile message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPageEditingFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPageEditingFile} BackupPageEditingFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageEditingFile.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPageEditingFile();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 2:
          message.hash = reader.string();
          break;
        case 3:
          message.fileSize = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPageEditingFile message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPageEditingFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPageEditingFile} BackupPageEditingFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageEditingFile.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPageEditingFile message.
   * @function verify
   * @memberof BackupPageEditingFile
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPageEditingFile.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      if (!$util.isString(message.identifier))
        return "identifier: string expected";
    if (message.hash != null && message.hasOwnProperty("hash"))
      if (!$util.isString(message.hash)) return "hash: string expected";
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (
        !$util.isInteger(message.fileSize) &&
        !(
          message.fileSize &&
          $util.isInteger(message.fileSize.low) &&
          $util.isInteger(message.fileSize.high)
        )
      )
        return "fileSize: integer|Long expected";
    return null;
  };

  /**
   * Creates a BackupPageEditingFile message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPageEditingFile
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPageEditingFile} BackupPageEditingFile
   */
  BackupPageEditingFile.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPageEditingFile) return object;
    var message = new $root.BackupPageEditingFile();
    if (object.identifier != null)
      message.identifier = String(object.identifier);
    if (object.hash != null) message.hash = String(object.hash);
    if (object.fileSize != null)
      if ($util.Long)
        (message.fileSize = $util.Long.fromValue(
          object.fileSize
        )).unsigned = true;
      else if (typeof object.fileSize === "string")
        message.fileSize = parseInt(object.fileSize, 10);
      else if (typeof object.fileSize === "number")
        message.fileSize = object.fileSize;
      else if (typeof object.fileSize === "object")
        message.fileSize = new $util.LongBits(
          object.fileSize.low >>> 0,
          object.fileSize.high >>> 0
        ).toNumber(true);
    return message;
  };

  /**
   * Creates a plain object from a BackupPageEditingFile message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPageEditingFile
   * @static
   * @param {BackupPageEditingFile} message BackupPageEditingFile
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPageEditingFile.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.identifier = "";
      object.hash = "";
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.fileSize =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.fileSize = options.longs === String ? "0" : 0;
    }
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      object.identifier = message.identifier;
    if (message.hash != null && message.hasOwnProperty("hash"))
      object.hash = message.hash;
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (typeof message.fileSize === "number")
        object.fileSize =
          options.longs === String
            ? String(message.fileSize)
            : message.fileSize;
      else
        object.fileSize =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.fileSize)
            : options.longs === Number
            ? new $util.LongBits(
                message.fileSize.low >>> 0,
                message.fileSize.high >>> 0
              ).toNumber(true)
            : message.fileSize;
    return object;
  };

  /**
   * Converts this BackupPageEditingFile to JSON.
   * @function toJSON
   * @memberof BackupPageEditingFile
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPageEditingFile.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPageEditingFile;
})();

$root.BackupPageProp = (function () {
  /**
   * Properties of a BackupPageProp.
   * @exports IBackupPageProp
   * @interface IBackupPageProp
   * @property {string|null} [note] BackupPageProp note
   * @property {number|null} [rotateCount] BackupPageProp rotateCount
   * @property {number|null} [imageOrientation] BackupPageProp imageOrientation
   * @property {IBackupPageEditingFile|null} [editing] BackupPageProp editing
   * @property {boolean|null} [usePrevEditing] BackupPageProp usePrevEditing
   */

  /**
   * Constructs a new BackupPageProp.
   * @exports BackupPageProp
   * @classdesc Represents a BackupPageProp.
   * @implements IBackupPageProp
   * @constructor
   * @param {IBackupPageProp=} [properties] Properties to set
   */
  function BackupPageProp(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPageProp note.
   * @member {string} note
   * @memberof BackupPageProp
   * @instance
   */
  BackupPageProp.prototype.note = "";

  /**
   * BackupPageProp rotateCount.
   * @member {number} rotateCount
   * @memberof BackupPageProp
   * @instance
   */
  BackupPageProp.prototype.rotateCount = 0;

  /**
   * BackupPageProp imageOrientation.
   * @member {number} imageOrientation
   * @memberof BackupPageProp
   * @instance
   */
  BackupPageProp.prototype.imageOrientation = 0;

  /**
   * BackupPageProp editing.
   * @member {IBackupPageEditingFile|null|undefined} editing
   * @memberof BackupPageProp
   * @instance
   */
  BackupPageProp.prototype.editing = null;

  /**
   * BackupPageProp usePrevEditing.
   * @member {boolean} usePrevEditing
   * @memberof BackupPageProp
   * @instance
   */
  BackupPageProp.prototype.usePrevEditing = false;

  /**
   * Creates a new BackupPageProp instance using the specified properties.
   * @function create
   * @memberof BackupPageProp
   * @static
   * @param {IBackupPageProp=} [properties] Properties to set
   * @returns {BackupPageProp} BackupPageProp instance
   */
  BackupPageProp.create = function create(properties) {
    return new BackupPageProp(properties);
  };

  /**
   * Encodes the specified BackupPageProp message. Does not implicitly {@link BackupPageProp.verify|verify} messages.
   * @function encode
   * @memberof BackupPageProp
   * @static
   * @param {IBackupPageProp} message BackupPageProp message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageProp.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.note != null && Object.hasOwnProperty.call(message, "note"))
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.note);
    if (
      message.rotateCount != null &&
      Object.hasOwnProperty.call(message, "rotateCount")
    )
      writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.rotateCount);
    if (
      message.imageOrientation != null &&
      Object.hasOwnProperty.call(message, "imageOrientation")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.imageOrientation);
    if (
      message.editing != null &&
      Object.hasOwnProperty.call(message, "editing")
    )
      $root.BackupPageEditingFile.encode(
        message.editing,
        writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
      ).ldelim();
    if (
      message.usePrevEditing != null &&
      Object.hasOwnProperty.call(message, "usePrevEditing")
    )
      writer.uint32(/* id 5, wireType 0 =*/ 40).bool(message.usePrevEditing);
    return writer;
  };

  /**
   * Encodes the specified BackupPageProp message, length delimited. Does not implicitly {@link BackupPageProp.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPageProp
   * @static
   * @param {IBackupPageProp} message BackupPageProp message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageProp.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPageProp message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPageProp
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPageProp} BackupPageProp
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageProp.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPageProp();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.note = reader.string();
          break;
        case 2:
          message.rotateCount = reader.int32();
          break;
        case 3:
          message.imageOrientation = reader.int32();
          break;
        case 4:
          message.editing = $root.BackupPageEditingFile.decode(
            reader,
            reader.uint32()
          );
          break;
        case 5:
          message.usePrevEditing = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPageProp message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPageProp
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPageProp} BackupPageProp
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageProp.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPageProp message.
   * @function verify
   * @memberof BackupPageProp
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPageProp.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.note != null && message.hasOwnProperty("note"))
      if (!$util.isString(message.note)) return "note: string expected";
    if (message.rotateCount != null && message.hasOwnProperty("rotateCount"))
      if (!$util.isInteger(message.rotateCount))
        return "rotateCount: integer expected";
    if (
      message.imageOrientation != null &&
      message.hasOwnProperty("imageOrientation")
    )
      if (!$util.isInteger(message.imageOrientation))
        return "imageOrientation: integer expected";
    if (message.editing != null && message.hasOwnProperty("editing")) {
      var error = $root.BackupPageEditingFile.verify(message.editing);
      if (error) return "editing." + error;
    }
    if (
      message.usePrevEditing != null &&
      message.hasOwnProperty("usePrevEditing")
    )
      if (typeof message.usePrevEditing !== "boolean")
        return "usePrevEditing: boolean expected";
    return null;
  };

  /**
   * Creates a BackupPageProp message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPageProp
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPageProp} BackupPageProp
   */
  BackupPageProp.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPageProp) return object;
    var message = new $root.BackupPageProp();
    if (object.note != null) message.note = String(object.note);
    if (object.rotateCount != null)
      message.rotateCount = object.rotateCount | 0;
    if (object.imageOrientation != null)
      message.imageOrientation = object.imageOrientation | 0;
    if (object.editing != null) {
      if (typeof object.editing !== "object")
        throw TypeError(".BackupPageProp.editing: object expected");
      message.editing = $root.BackupPageEditingFile.fromObject(object.editing);
    }
    if (object.usePrevEditing != null)
      message.usePrevEditing = Boolean(object.usePrevEditing);
    return message;
  };

  /**
   * Creates a plain object from a BackupPageProp message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPageProp
   * @static
   * @param {BackupPageProp} message BackupPageProp
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPageProp.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.note = "";
      object.rotateCount = 0;
      object.imageOrientation = 0;
      object.editing = null;
      object.usePrevEditing = false;
    }
    if (message.note != null && message.hasOwnProperty("note"))
      object.note = message.note;
    if (message.rotateCount != null && message.hasOwnProperty("rotateCount"))
      object.rotateCount = message.rotateCount;
    if (
      message.imageOrientation != null &&
      message.hasOwnProperty("imageOrientation")
    )
      object.imageOrientation = message.imageOrientation;
    if (message.editing != null && message.hasOwnProperty("editing"))
      object.editing = $root.BackupPageEditingFile.toObject(
        message.editing,
        options
      );
    if (
      message.usePrevEditing != null &&
      message.hasOwnProperty("usePrevEditing")
    )
      object.usePrevEditing = message.usePrevEditing;
    return object;
  };

  /**
   * Converts this BackupPageProp to JSON.
   * @function toJSON
   * @memberof BackupPageProp
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPageProp.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPageProp;
})();

$root.BackupPageOCRProp = (function () {
  /**
   * Properties of a BackupPageOCRProp.
   * @exports IBackupPageOCRProp
   * @interface IBackupPageOCRProp
   * @property {Array.<number>|null} [imageSize] BackupPageOCRProp imageSize
   * @property {string|null} [languages] BackupPageOCRProp languages
   * @property {number|null} [minTextHeight] BackupPageOCRProp minTextHeight
   * @property {number|null} [skewRadian] BackupPageOCRProp skewRadian
   * @property {number|null} [srcToOcrScale] BackupPageOCRProp srcToOcrScale
   * @property {number|null} [useCRAFT] BackupPageOCRProp useCRAFT
   */

  /**
   * Constructs a new BackupPageOCRProp.
   * @exports BackupPageOCRProp
   * @classdesc Represents a BackupPageOCRProp.
   * @implements IBackupPageOCRProp
   * @constructor
   * @param {IBackupPageOCRProp=} [properties] Properties to set
   */
  function BackupPageOCRProp(properties) {
    this.imageSize = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPageOCRProp imageSize.
   * @member {Array.<number>} imageSize
   * @memberof BackupPageOCRProp
   * @instance
   */
  BackupPageOCRProp.prototype.imageSize = $util.emptyArray;

  /**
   * BackupPageOCRProp languages.
   * @member {string} languages
   * @memberof BackupPageOCRProp
   * @instance
   */
  BackupPageOCRProp.prototype.languages = "";

  /**
   * BackupPageOCRProp minTextHeight.
   * @member {number} minTextHeight
   * @memberof BackupPageOCRProp
   * @instance
   */
  BackupPageOCRProp.prototype.minTextHeight = 0;

  /**
   * BackupPageOCRProp skewRadian.
   * @member {number} skewRadian
   * @memberof BackupPageOCRProp
   * @instance
   */
  BackupPageOCRProp.prototype.skewRadian = 0;

  /**
   * BackupPageOCRProp srcToOcrScale.
   * @member {number} srcToOcrScale
   * @memberof BackupPageOCRProp
   * @instance
   */
  BackupPageOCRProp.prototype.srcToOcrScale = 0;

  /**
   * BackupPageOCRProp useCRAFT.
   * @member {number} useCRAFT
   * @memberof BackupPageOCRProp
   * @instance
   */
  BackupPageOCRProp.prototype.useCRAFT = 0;

  /**
   * Creates a new BackupPageOCRProp instance using the specified properties.
   * @function create
   * @memberof BackupPageOCRProp
   * @static
   * @param {IBackupPageOCRProp=} [properties] Properties to set
   * @returns {BackupPageOCRProp} BackupPageOCRProp instance
   */
  BackupPageOCRProp.create = function create(properties) {
    return new BackupPageOCRProp(properties);
  };

  /**
   * Encodes the specified BackupPageOCRProp message. Does not implicitly {@link BackupPageOCRProp.verify|verify} messages.
   * @function encode
   * @memberof BackupPageOCRProp
   * @static
   * @param {IBackupPageOCRProp} message BackupPageOCRProp message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCRProp.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.imageSize != null && message.imageSize.length) {
      writer.uint32(/* id 1, wireType 2 =*/ 10).fork();
      for (var i = 0; i < message.imageSize.length; ++i)
        writer.double(message.imageSize[i]);
      writer.ldelim();
    }
    if (
      message.languages != null &&
      Object.hasOwnProperty.call(message, "languages")
    )
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.languages);
    if (
      message.minTextHeight != null &&
      Object.hasOwnProperty.call(message, "minTextHeight")
    )
      writer.uint32(/* id 3, wireType 1 =*/ 25).double(message.minTextHeight);
    if (
      message.skewRadian != null &&
      Object.hasOwnProperty.call(message, "skewRadian")
    )
      writer.uint32(/* id 4, wireType 1 =*/ 33).double(message.skewRadian);
    if (
      message.srcToOcrScale != null &&
      Object.hasOwnProperty.call(message, "srcToOcrScale")
    )
      writer.uint32(/* id 5, wireType 1 =*/ 41).double(message.srcToOcrScale);
    if (
      message.useCRAFT != null &&
      Object.hasOwnProperty.call(message, "useCRAFT")
    )
      writer.uint32(/* id 6, wireType 1 =*/ 49).double(message.useCRAFT);
    return writer;
  };

  /**
   * Encodes the specified BackupPageOCRProp message, length delimited. Does not implicitly {@link BackupPageOCRProp.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPageOCRProp
   * @static
   * @param {IBackupPageOCRProp} message BackupPageOCRProp message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCRProp.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPageOCRProp message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPageOCRProp
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPageOCRProp} BackupPageOCRProp
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCRProp.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPageOCRProp();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (!(message.imageSize && message.imageSize.length))
            message.imageSize = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) message.imageSize.push(reader.double());
          } else message.imageSize.push(reader.double());
          break;
        case 2:
          message.languages = reader.string();
          break;
        case 3:
          message.minTextHeight = reader.double();
          break;
        case 4:
          message.skewRadian = reader.double();
          break;
        case 5:
          message.srcToOcrScale = reader.double();
          break;
        case 6:
          message.useCRAFT = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPageOCRProp message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPageOCRProp
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPageOCRProp} BackupPageOCRProp
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCRProp.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPageOCRProp message.
   * @function verify
   * @memberof BackupPageOCRProp
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPageOCRProp.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.imageSize != null && message.hasOwnProperty("imageSize")) {
      if (!Array.isArray(message.imageSize)) return "imageSize: array expected";
      for (var i = 0; i < message.imageSize.length; ++i)
        if (typeof message.imageSize[i] !== "number")
          return "imageSize: number[] expected";
    }
    if (message.languages != null && message.hasOwnProperty("languages"))
      if (!$util.isString(message.languages))
        return "languages: string expected";
    if (
      message.minTextHeight != null &&
      message.hasOwnProperty("minTextHeight")
    )
      if (typeof message.minTextHeight !== "number")
        return "minTextHeight: number expected";
    if (message.skewRadian != null && message.hasOwnProperty("skewRadian"))
      if (typeof message.skewRadian !== "number")
        return "skewRadian: number expected";
    if (
      message.srcToOcrScale != null &&
      message.hasOwnProperty("srcToOcrScale")
    )
      if (typeof message.srcToOcrScale !== "number")
        return "srcToOcrScale: number expected";
    if (message.useCRAFT != null && message.hasOwnProperty("useCRAFT"))
      if (typeof message.useCRAFT !== "number")
        return "useCRAFT: number expected";
    return null;
  };

  /**
   * Creates a BackupPageOCRProp message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPageOCRProp
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPageOCRProp} BackupPageOCRProp
   */
  BackupPageOCRProp.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPageOCRProp) return object;
    var message = new $root.BackupPageOCRProp();
    if (object.imageSize) {
      if (!Array.isArray(object.imageSize))
        throw TypeError(".BackupPageOCRProp.imageSize: array expected");
      message.imageSize = [];
      for (var i = 0; i < object.imageSize.length; ++i)
        message.imageSize[i] = Number(object.imageSize[i]);
    }
    if (object.languages != null) message.languages = String(object.languages);
    if (object.minTextHeight != null)
      message.minTextHeight = Number(object.minTextHeight);
    if (object.skewRadian != null)
      message.skewRadian = Number(object.skewRadian);
    if (object.srcToOcrScale != null)
      message.srcToOcrScale = Number(object.srcToOcrScale);
    if (object.useCRAFT != null) message.useCRAFT = Number(object.useCRAFT);
    return message;
  };

  /**
   * Creates a plain object from a BackupPageOCRProp message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPageOCRProp
   * @static
   * @param {BackupPageOCRProp} message BackupPageOCRProp
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPageOCRProp.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.imageSize = [];
    if (options.defaults) {
      object.languages = "";
      object.minTextHeight = 0;
      object.skewRadian = 0;
      object.srcToOcrScale = 0;
      object.useCRAFT = 0;
    }
    if (message.imageSize && message.imageSize.length) {
      object.imageSize = [];
      for (var j = 0; j < message.imageSize.length; ++j)
        object.imageSize[j] =
          options.json && !isFinite(message.imageSize[j])
            ? String(message.imageSize[j])
            : message.imageSize[j];
    }
    if (message.languages != null && message.hasOwnProperty("languages"))
      object.languages = message.languages;
    if (
      message.minTextHeight != null &&
      message.hasOwnProperty("minTextHeight")
    )
      object.minTextHeight =
        options.json && !isFinite(message.minTextHeight)
          ? String(message.minTextHeight)
          : message.minTextHeight;
    if (message.skewRadian != null && message.hasOwnProperty("skewRadian"))
      object.skewRadian =
        options.json && !isFinite(message.skewRadian)
          ? String(message.skewRadian)
          : message.skewRadian;
    if (
      message.srcToOcrScale != null &&
      message.hasOwnProperty("srcToOcrScale")
    )
      object.srcToOcrScale =
        options.json && !isFinite(message.srcToOcrScale)
          ? String(message.srcToOcrScale)
          : message.srcToOcrScale;
    if (message.useCRAFT != null && message.hasOwnProperty("useCRAFT"))
      object.useCRAFT =
        options.json && !isFinite(message.useCRAFT)
          ? String(message.useCRAFT)
          : message.useCRAFT;
    return object;
  };

  /**
   * Converts this BackupPageOCRProp to JSON.
   * @function toJSON
   * @memberof BackupPageOCRProp
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPageOCRProp.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPageOCRProp;
})();

$root.BackupPageOCRBgFile = (function () {
  /**
   * Properties of a BackupPageOCRBgFile.
   * @exports IBackupPageOCRBgFile
   * @interface IBackupPageOCRBgFile
   * @property {string|null} [identifier] BackupPageOCRBgFile identifier
   * @property {string|null} [hash] BackupPageOCRBgFile hash
   * @property {number|Long|null} [fileSize] BackupPageOCRBgFile fileSize
   */

  /**
   * Constructs a new BackupPageOCRBgFile.
   * @exports BackupPageOCRBgFile
   * @classdesc Represents a BackupPageOCRBgFile.
   * @implements IBackupPageOCRBgFile
   * @constructor
   * @param {IBackupPageOCRBgFile=} [properties] Properties to set
   */
  function BackupPageOCRBgFile(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPageOCRBgFile identifier.
   * @member {string} identifier
   * @memberof BackupPageOCRBgFile
   * @instance
   */
  BackupPageOCRBgFile.prototype.identifier = "";

  /**
   * BackupPageOCRBgFile hash.
   * @member {string} hash
   * @memberof BackupPageOCRBgFile
   * @instance
   */
  BackupPageOCRBgFile.prototype.hash = "";

  /**
   * BackupPageOCRBgFile fileSize.
   * @member {number|Long} fileSize
   * @memberof BackupPageOCRBgFile
   * @instance
   */
  BackupPageOCRBgFile.prototype.fileSize = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * Creates a new BackupPageOCRBgFile instance using the specified properties.
   * @function create
   * @memberof BackupPageOCRBgFile
   * @static
   * @param {IBackupPageOCRBgFile=} [properties] Properties to set
   * @returns {BackupPageOCRBgFile} BackupPageOCRBgFile instance
   */
  BackupPageOCRBgFile.create = function create(properties) {
    return new BackupPageOCRBgFile(properties);
  };

  /**
   * Encodes the specified BackupPageOCRBgFile message. Does not implicitly {@link BackupPageOCRBgFile.verify|verify} messages.
   * @function encode
   * @memberof BackupPageOCRBgFile
   * @static
   * @param {IBackupPageOCRBgFile} message BackupPageOCRBgFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCRBgFile.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.identifier != null &&
      Object.hasOwnProperty.call(message, "identifier")
    )
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.identifier);
    if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.hash);
    if (
      message.fileSize != null &&
      Object.hasOwnProperty.call(message, "fileSize")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.fileSize);
    return writer;
  };

  /**
   * Encodes the specified BackupPageOCRBgFile message, length delimited. Does not implicitly {@link BackupPageOCRBgFile.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPageOCRBgFile
   * @static
   * @param {IBackupPageOCRBgFile} message BackupPageOCRBgFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCRBgFile.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPageOCRBgFile message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPageOCRBgFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPageOCRBgFile} BackupPageOCRBgFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCRBgFile.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPageOCRBgFile();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 2:
          message.hash = reader.string();
          break;
        case 3:
          message.fileSize = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPageOCRBgFile message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPageOCRBgFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPageOCRBgFile} BackupPageOCRBgFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCRBgFile.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPageOCRBgFile message.
   * @function verify
   * @memberof BackupPageOCRBgFile
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPageOCRBgFile.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      if (!$util.isString(message.identifier))
        return "identifier: string expected";
    if (message.hash != null && message.hasOwnProperty("hash"))
      if (!$util.isString(message.hash)) return "hash: string expected";
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (
        !$util.isInteger(message.fileSize) &&
        !(
          message.fileSize &&
          $util.isInteger(message.fileSize.low) &&
          $util.isInteger(message.fileSize.high)
        )
      )
        return "fileSize: integer|Long expected";
    return null;
  };

  /**
   * Creates a BackupPageOCRBgFile message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPageOCRBgFile
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPageOCRBgFile} BackupPageOCRBgFile
   */
  BackupPageOCRBgFile.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPageOCRBgFile) return object;
    var message = new $root.BackupPageOCRBgFile();
    if (object.identifier != null)
      message.identifier = String(object.identifier);
    if (object.hash != null) message.hash = String(object.hash);
    if (object.fileSize != null)
      if ($util.Long)
        (message.fileSize = $util.Long.fromValue(
          object.fileSize
        )).unsigned = true;
      else if (typeof object.fileSize === "string")
        message.fileSize = parseInt(object.fileSize, 10);
      else if (typeof object.fileSize === "number")
        message.fileSize = object.fileSize;
      else if (typeof object.fileSize === "object")
        message.fileSize = new $util.LongBits(
          object.fileSize.low >>> 0,
          object.fileSize.high >>> 0
        ).toNumber(true);
    return message;
  };

  /**
   * Creates a plain object from a BackupPageOCRBgFile message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPageOCRBgFile
   * @static
   * @param {BackupPageOCRBgFile} message BackupPageOCRBgFile
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPageOCRBgFile.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.identifier = "";
      object.hash = "";
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.fileSize =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.fileSize = options.longs === String ? "0" : 0;
    }
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      object.identifier = message.identifier;
    if (message.hash != null && message.hasOwnProperty("hash"))
      object.hash = message.hash;
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (typeof message.fileSize === "number")
        object.fileSize =
          options.longs === String
            ? String(message.fileSize)
            : message.fileSize;
      else
        object.fileSize =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.fileSize)
            : options.longs === Number
            ? new $util.LongBits(
                message.fileSize.low >>> 0,
                message.fileSize.high >>> 0
              ).toNumber(true)
            : message.fileSize;
    return object;
  };

  /**
   * Converts this BackupPageOCRBgFile to JSON.
   * @function toJSON
   * @memberof BackupPageOCRBgFile
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPageOCRBgFile.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPageOCRBgFile;
})();

$root.BackupPageOCRTextBgFile = (function () {
  /**
   * Properties of a BackupPageOCRTextBgFile.
   * @exports IBackupPageOCRTextBgFile
   * @interface IBackupPageOCRTextBgFile
   * @property {string|null} [identifier] BackupPageOCRTextBgFile identifier
   * @property {string|null} [hash] BackupPageOCRTextBgFile hash
   * @property {number|Long|null} [fileSize] BackupPageOCRTextBgFile fileSize
   */

  /**
   * Constructs a new BackupPageOCRTextBgFile.
   * @exports BackupPageOCRTextBgFile
   * @classdesc Represents a BackupPageOCRTextBgFile.
   * @implements IBackupPageOCRTextBgFile
   * @constructor
   * @param {IBackupPageOCRTextBgFile=} [properties] Properties to set
   */
  function BackupPageOCRTextBgFile(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPageOCRTextBgFile identifier.
   * @member {string} identifier
   * @memberof BackupPageOCRTextBgFile
   * @instance
   */
  BackupPageOCRTextBgFile.prototype.identifier = "";

  /**
   * BackupPageOCRTextBgFile hash.
   * @member {string} hash
   * @memberof BackupPageOCRTextBgFile
   * @instance
   */
  BackupPageOCRTextBgFile.prototype.hash = "";

  /**
   * BackupPageOCRTextBgFile fileSize.
   * @member {number|Long} fileSize
   * @memberof BackupPageOCRTextBgFile
   * @instance
   */
  BackupPageOCRTextBgFile.prototype.fileSize = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * Creates a new BackupPageOCRTextBgFile instance using the specified properties.
   * @function create
   * @memberof BackupPageOCRTextBgFile
   * @static
   * @param {IBackupPageOCRTextBgFile=} [properties] Properties to set
   * @returns {BackupPageOCRTextBgFile} BackupPageOCRTextBgFile instance
   */
  BackupPageOCRTextBgFile.create = function create(properties) {
    return new BackupPageOCRTextBgFile(properties);
  };

  /**
   * Encodes the specified BackupPageOCRTextBgFile message. Does not implicitly {@link BackupPageOCRTextBgFile.verify|verify} messages.
   * @function encode
   * @memberof BackupPageOCRTextBgFile
   * @static
   * @param {IBackupPageOCRTextBgFile} message BackupPageOCRTextBgFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCRTextBgFile.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.identifier != null &&
      Object.hasOwnProperty.call(message, "identifier")
    )
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.identifier);
    if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.hash);
    if (
      message.fileSize != null &&
      Object.hasOwnProperty.call(message, "fileSize")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.fileSize);
    return writer;
  };

  /**
   * Encodes the specified BackupPageOCRTextBgFile message, length delimited. Does not implicitly {@link BackupPageOCRTextBgFile.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPageOCRTextBgFile
   * @static
   * @param {IBackupPageOCRTextBgFile} message BackupPageOCRTextBgFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCRTextBgFile.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPageOCRTextBgFile message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPageOCRTextBgFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPageOCRTextBgFile} BackupPageOCRTextBgFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCRTextBgFile.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPageOCRTextBgFile();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 2:
          message.hash = reader.string();
          break;
        case 3:
          message.fileSize = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPageOCRTextBgFile message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPageOCRTextBgFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPageOCRTextBgFile} BackupPageOCRTextBgFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCRTextBgFile.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPageOCRTextBgFile message.
   * @function verify
   * @memberof BackupPageOCRTextBgFile
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPageOCRTextBgFile.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      if (!$util.isString(message.identifier))
        return "identifier: string expected";
    if (message.hash != null && message.hasOwnProperty("hash"))
      if (!$util.isString(message.hash)) return "hash: string expected";
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (
        !$util.isInteger(message.fileSize) &&
        !(
          message.fileSize &&
          $util.isInteger(message.fileSize.low) &&
          $util.isInteger(message.fileSize.high)
        )
      )
        return "fileSize: integer|Long expected";
    return null;
  };

  /**
   * Creates a BackupPageOCRTextBgFile message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPageOCRTextBgFile
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPageOCRTextBgFile} BackupPageOCRTextBgFile
   */
  BackupPageOCRTextBgFile.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPageOCRTextBgFile) return object;
    var message = new $root.BackupPageOCRTextBgFile();
    if (object.identifier != null)
      message.identifier = String(object.identifier);
    if (object.hash != null) message.hash = String(object.hash);
    if (object.fileSize != null)
      if ($util.Long)
        (message.fileSize = $util.Long.fromValue(
          object.fileSize
        )).unsigned = true;
      else if (typeof object.fileSize === "string")
        message.fileSize = parseInt(object.fileSize, 10);
      else if (typeof object.fileSize === "number")
        message.fileSize = object.fileSize;
      else if (typeof object.fileSize === "object")
        message.fileSize = new $util.LongBits(
          object.fileSize.low >>> 0,
          object.fileSize.high >>> 0
        ).toNumber(true);
    return message;
  };

  /**
   * Creates a plain object from a BackupPageOCRTextBgFile message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPageOCRTextBgFile
   * @static
   * @param {BackupPageOCRTextBgFile} message BackupPageOCRTextBgFile
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPageOCRTextBgFile.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.identifier = "";
      object.hash = "";
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.fileSize =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.fileSize = options.longs === String ? "0" : 0;
    }
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      object.identifier = message.identifier;
    if (message.hash != null && message.hasOwnProperty("hash"))
      object.hash = message.hash;
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (typeof message.fileSize === "number")
        object.fileSize =
          options.longs === String
            ? String(message.fileSize)
            : message.fileSize;
      else
        object.fileSize =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.fileSize)
            : options.longs === Number
            ? new $util.LongBits(
                message.fileSize.low >>> 0,
                message.fileSize.high >>> 0
              ).toNumber(true)
            : message.fileSize;
    return object;
  };

  /**
   * Converts this BackupPageOCRTextBgFile to JSON.
   * @function toJSON
   * @memberof BackupPageOCRTextBgFile
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPageOCRTextBgFile.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPageOCRTextBgFile;
})();

$root.BackupPageOCRSourceFile = (function () {
  /**
   * Properties of a BackupPageOCRSourceFile.
   * @exports IBackupPageOCRSourceFile
   * @interface IBackupPageOCRSourceFile
   * @property {string|null} [identifier] BackupPageOCRSourceFile identifier
   * @property {string|null} [hash] BackupPageOCRSourceFile hash
   * @property {number|Long|null} [fileSize] BackupPageOCRSourceFile fileSize
   */

  /**
   * Constructs a new BackupPageOCRSourceFile.
   * @exports BackupPageOCRSourceFile
   * @classdesc Represents a BackupPageOCRSourceFile.
   * @implements IBackupPageOCRSourceFile
   * @constructor
   * @param {IBackupPageOCRSourceFile=} [properties] Properties to set
   */
  function BackupPageOCRSourceFile(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPageOCRSourceFile identifier.
   * @member {string} identifier
   * @memberof BackupPageOCRSourceFile
   * @instance
   */
  BackupPageOCRSourceFile.prototype.identifier = "";

  /**
   * BackupPageOCRSourceFile hash.
   * @member {string} hash
   * @memberof BackupPageOCRSourceFile
   * @instance
   */
  BackupPageOCRSourceFile.prototype.hash = "";

  /**
   * BackupPageOCRSourceFile fileSize.
   * @member {number|Long} fileSize
   * @memberof BackupPageOCRSourceFile
   * @instance
   */
  BackupPageOCRSourceFile.prototype.fileSize = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * Creates a new BackupPageOCRSourceFile instance using the specified properties.
   * @function create
   * @memberof BackupPageOCRSourceFile
   * @static
   * @param {IBackupPageOCRSourceFile=} [properties] Properties to set
   * @returns {BackupPageOCRSourceFile} BackupPageOCRSourceFile instance
   */
  BackupPageOCRSourceFile.create = function create(properties) {
    return new BackupPageOCRSourceFile(properties);
  };

  /**
   * Encodes the specified BackupPageOCRSourceFile message. Does not implicitly {@link BackupPageOCRSourceFile.verify|verify} messages.
   * @function encode
   * @memberof BackupPageOCRSourceFile
   * @static
   * @param {IBackupPageOCRSourceFile} message BackupPageOCRSourceFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCRSourceFile.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.identifier != null &&
      Object.hasOwnProperty.call(message, "identifier")
    )
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.identifier);
    if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.hash);
    if (
      message.fileSize != null &&
      Object.hasOwnProperty.call(message, "fileSize")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.fileSize);
    return writer;
  };

  /**
   * Encodes the specified BackupPageOCRSourceFile message, length delimited. Does not implicitly {@link BackupPageOCRSourceFile.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPageOCRSourceFile
   * @static
   * @param {IBackupPageOCRSourceFile} message BackupPageOCRSourceFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCRSourceFile.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPageOCRSourceFile message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPageOCRSourceFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPageOCRSourceFile} BackupPageOCRSourceFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCRSourceFile.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPageOCRSourceFile();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 2:
          message.hash = reader.string();
          break;
        case 3:
          message.fileSize = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPageOCRSourceFile message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPageOCRSourceFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPageOCRSourceFile} BackupPageOCRSourceFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCRSourceFile.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPageOCRSourceFile message.
   * @function verify
   * @memberof BackupPageOCRSourceFile
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPageOCRSourceFile.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      if (!$util.isString(message.identifier))
        return "identifier: string expected";
    if (message.hash != null && message.hasOwnProperty("hash"))
      if (!$util.isString(message.hash)) return "hash: string expected";
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (
        !$util.isInteger(message.fileSize) &&
        !(
          message.fileSize &&
          $util.isInteger(message.fileSize.low) &&
          $util.isInteger(message.fileSize.high)
        )
      )
        return "fileSize: integer|Long expected";
    return null;
  };

  /**
   * Creates a BackupPageOCRSourceFile message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPageOCRSourceFile
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPageOCRSourceFile} BackupPageOCRSourceFile
   */
  BackupPageOCRSourceFile.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPageOCRSourceFile) return object;
    var message = new $root.BackupPageOCRSourceFile();
    if (object.identifier != null)
      message.identifier = String(object.identifier);
    if (object.hash != null) message.hash = String(object.hash);
    if (object.fileSize != null)
      if ($util.Long)
        (message.fileSize = $util.Long.fromValue(
          object.fileSize
        )).unsigned = true;
      else if (typeof object.fileSize === "string")
        message.fileSize = parseInt(object.fileSize, 10);
      else if (typeof object.fileSize === "number")
        message.fileSize = object.fileSize;
      else if (typeof object.fileSize === "object")
        message.fileSize = new $util.LongBits(
          object.fileSize.low >>> 0,
          object.fileSize.high >>> 0
        ).toNumber(true);
    return message;
  };

  /**
   * Creates a plain object from a BackupPageOCRSourceFile message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPageOCRSourceFile
   * @static
   * @param {BackupPageOCRSourceFile} message BackupPageOCRSourceFile
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPageOCRSourceFile.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.identifier = "";
      object.hash = "";
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.fileSize =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.fileSize = options.longs === String ? "0" : 0;
    }
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      object.identifier = message.identifier;
    if (message.hash != null && message.hasOwnProperty("hash"))
      object.hash = message.hash;
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (typeof message.fileSize === "number")
        object.fileSize =
          options.longs === String
            ? String(message.fileSize)
            : message.fileSize;
      else
        object.fileSize =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.fileSize)
            : options.longs === Number
            ? new $util.LongBits(
                message.fileSize.low >>> 0,
                message.fileSize.high >>> 0
              ).toNumber(true)
            : message.fileSize;
    return object;
  };

  /**
   * Converts this BackupPageOCRSourceFile to JSON.
   * @function toJSON
   * @memberof BackupPageOCRSourceFile
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPageOCRSourceFile.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPageOCRSourceFile;
})();

$root.BackupPageOCRTextlineFile = (function () {
  /**
   * Properties of a BackupPageOCRTextlineFile.
   * @exports IBackupPageOCRTextlineFile
   * @interface IBackupPageOCRTextlineFile
   * @property {string|null} [identifier] BackupPageOCRTextlineFile identifier
   * @property {string|null} [hash] BackupPageOCRTextlineFile hash
   * @property {number|Long|null} [fileSize] BackupPageOCRTextlineFile fileSize
   */

  /**
   * Constructs a new BackupPageOCRTextlineFile.
   * @exports BackupPageOCRTextlineFile
   * @classdesc Represents a BackupPageOCRTextlineFile.
   * @implements IBackupPageOCRTextlineFile
   * @constructor
   * @param {IBackupPageOCRTextlineFile=} [properties] Properties to set
   */
  function BackupPageOCRTextlineFile(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPageOCRTextlineFile identifier.
   * @member {string} identifier
   * @memberof BackupPageOCRTextlineFile
   * @instance
   */
  BackupPageOCRTextlineFile.prototype.identifier = "";

  /**
   * BackupPageOCRTextlineFile hash.
   * @member {string} hash
   * @memberof BackupPageOCRTextlineFile
   * @instance
   */
  BackupPageOCRTextlineFile.prototype.hash = "";

  /**
   * BackupPageOCRTextlineFile fileSize.
   * @member {number|Long} fileSize
   * @memberof BackupPageOCRTextlineFile
   * @instance
   */
  BackupPageOCRTextlineFile.prototype.fileSize = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * Creates a new BackupPageOCRTextlineFile instance using the specified properties.
   * @function create
   * @memberof BackupPageOCRTextlineFile
   * @static
   * @param {IBackupPageOCRTextlineFile=} [properties] Properties to set
   * @returns {BackupPageOCRTextlineFile} BackupPageOCRTextlineFile instance
   */
  BackupPageOCRTextlineFile.create = function create(properties) {
    return new BackupPageOCRTextlineFile(properties);
  };

  /**
   * Encodes the specified BackupPageOCRTextlineFile message. Does not implicitly {@link BackupPageOCRTextlineFile.verify|verify} messages.
   * @function encode
   * @memberof BackupPageOCRTextlineFile
   * @static
   * @param {IBackupPageOCRTextlineFile} message BackupPageOCRTextlineFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCRTextlineFile.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.identifier != null &&
      Object.hasOwnProperty.call(message, "identifier")
    )
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.identifier);
    if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.hash);
    if (
      message.fileSize != null &&
      Object.hasOwnProperty.call(message, "fileSize")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.fileSize);
    return writer;
  };

  /**
   * Encodes the specified BackupPageOCRTextlineFile message, length delimited. Does not implicitly {@link BackupPageOCRTextlineFile.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPageOCRTextlineFile
   * @static
   * @param {IBackupPageOCRTextlineFile} message BackupPageOCRTextlineFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCRTextlineFile.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPageOCRTextlineFile message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPageOCRTextlineFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPageOCRTextlineFile} BackupPageOCRTextlineFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCRTextlineFile.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPageOCRTextlineFile();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 2:
          message.hash = reader.string();
          break;
        case 3:
          message.fileSize = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPageOCRTextlineFile message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPageOCRTextlineFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPageOCRTextlineFile} BackupPageOCRTextlineFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCRTextlineFile.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPageOCRTextlineFile message.
   * @function verify
   * @memberof BackupPageOCRTextlineFile
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPageOCRTextlineFile.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      if (!$util.isString(message.identifier))
        return "identifier: string expected";
    if (message.hash != null && message.hasOwnProperty("hash"))
      if (!$util.isString(message.hash)) return "hash: string expected";
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (
        !$util.isInteger(message.fileSize) &&
        !(
          message.fileSize &&
          $util.isInteger(message.fileSize.low) &&
          $util.isInteger(message.fileSize.high)
        )
      )
        return "fileSize: integer|Long expected";
    return null;
  };

  /**
   * Creates a BackupPageOCRTextlineFile message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPageOCRTextlineFile
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPageOCRTextlineFile} BackupPageOCRTextlineFile
   */
  BackupPageOCRTextlineFile.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPageOCRTextlineFile) return object;
    var message = new $root.BackupPageOCRTextlineFile();
    if (object.identifier != null)
      message.identifier = String(object.identifier);
    if (object.hash != null) message.hash = String(object.hash);
    if (object.fileSize != null)
      if ($util.Long)
        (message.fileSize = $util.Long.fromValue(
          object.fileSize
        )).unsigned = true;
      else if (typeof object.fileSize === "string")
        message.fileSize = parseInt(object.fileSize, 10);
      else if (typeof object.fileSize === "number")
        message.fileSize = object.fileSize;
      else if (typeof object.fileSize === "object")
        message.fileSize = new $util.LongBits(
          object.fileSize.low >>> 0,
          object.fileSize.high >>> 0
        ).toNumber(true);
    return message;
  };

  /**
   * Creates a plain object from a BackupPageOCRTextlineFile message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPageOCRTextlineFile
   * @static
   * @param {BackupPageOCRTextlineFile} message BackupPageOCRTextlineFile
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPageOCRTextlineFile.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.identifier = "";
      object.hash = "";
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.fileSize =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.fileSize = options.longs === String ? "0" : 0;
    }
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      object.identifier = message.identifier;
    if (message.hash != null && message.hasOwnProperty("hash"))
      object.hash = message.hash;
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (typeof message.fileSize === "number")
        object.fileSize =
          options.longs === String
            ? String(message.fileSize)
            : message.fileSize;
      else
        object.fileSize =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.fileSize)
            : options.longs === Number
            ? new $util.LongBits(
                message.fileSize.low >>> 0,
                message.fileSize.high >>> 0
              ).toNumber(true)
            : message.fileSize;
    return object;
  };

  /**
   * Converts this BackupPageOCRTextlineFile to JSON.
   * @function toJSON
   * @memberof BackupPageOCRTextlineFile
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPageOCRTextlineFile.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPageOCRTextlineFile;
})();

$root.BackupPageOCRTextboxFile = (function () {
  /**
   * Properties of a BackupPageOCRTextboxFile.
   * @exports IBackupPageOCRTextboxFile
   * @interface IBackupPageOCRTextboxFile
   * @property {string|null} [identifier] BackupPageOCRTextboxFile identifier
   * @property {string|null} [hash] BackupPageOCRTextboxFile hash
   * @property {number|Long|null} [fileSize] BackupPageOCRTextboxFile fileSize
   */

  /**
   * Constructs a new BackupPageOCRTextboxFile.
   * @exports BackupPageOCRTextboxFile
   * @classdesc Represents a BackupPageOCRTextboxFile.
   * @implements IBackupPageOCRTextboxFile
   * @constructor
   * @param {IBackupPageOCRTextboxFile=} [properties] Properties to set
   */
  function BackupPageOCRTextboxFile(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPageOCRTextboxFile identifier.
   * @member {string} identifier
   * @memberof BackupPageOCRTextboxFile
   * @instance
   */
  BackupPageOCRTextboxFile.prototype.identifier = "";

  /**
   * BackupPageOCRTextboxFile hash.
   * @member {string} hash
   * @memberof BackupPageOCRTextboxFile
   * @instance
   */
  BackupPageOCRTextboxFile.prototype.hash = "";

  /**
   * BackupPageOCRTextboxFile fileSize.
   * @member {number|Long} fileSize
   * @memberof BackupPageOCRTextboxFile
   * @instance
   */
  BackupPageOCRTextboxFile.prototype.fileSize = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * Creates a new BackupPageOCRTextboxFile instance using the specified properties.
   * @function create
   * @memberof BackupPageOCRTextboxFile
   * @static
   * @param {IBackupPageOCRTextboxFile=} [properties] Properties to set
   * @returns {BackupPageOCRTextboxFile} BackupPageOCRTextboxFile instance
   */
  BackupPageOCRTextboxFile.create = function create(properties) {
    return new BackupPageOCRTextboxFile(properties);
  };

  /**
   * Encodes the specified BackupPageOCRTextboxFile message. Does not implicitly {@link BackupPageOCRTextboxFile.verify|verify} messages.
   * @function encode
   * @memberof BackupPageOCRTextboxFile
   * @static
   * @param {IBackupPageOCRTextboxFile} message BackupPageOCRTextboxFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCRTextboxFile.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.identifier != null &&
      Object.hasOwnProperty.call(message, "identifier")
    )
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.identifier);
    if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.hash);
    if (
      message.fileSize != null &&
      Object.hasOwnProperty.call(message, "fileSize")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.fileSize);
    return writer;
  };

  /**
   * Encodes the specified BackupPageOCRTextboxFile message, length delimited. Does not implicitly {@link BackupPageOCRTextboxFile.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPageOCRTextboxFile
   * @static
   * @param {IBackupPageOCRTextboxFile} message BackupPageOCRTextboxFile message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCRTextboxFile.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPageOCRTextboxFile message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPageOCRTextboxFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPageOCRTextboxFile} BackupPageOCRTextboxFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCRTextboxFile.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPageOCRTextboxFile();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 2:
          message.hash = reader.string();
          break;
        case 3:
          message.fileSize = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPageOCRTextboxFile message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPageOCRTextboxFile
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPageOCRTextboxFile} BackupPageOCRTextboxFile
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCRTextboxFile.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPageOCRTextboxFile message.
   * @function verify
   * @memberof BackupPageOCRTextboxFile
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPageOCRTextboxFile.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      if (!$util.isString(message.identifier))
        return "identifier: string expected";
    if (message.hash != null && message.hasOwnProperty("hash"))
      if (!$util.isString(message.hash)) return "hash: string expected";
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (
        !$util.isInteger(message.fileSize) &&
        !(
          message.fileSize &&
          $util.isInteger(message.fileSize.low) &&
          $util.isInteger(message.fileSize.high)
        )
      )
        return "fileSize: integer|Long expected";
    return null;
  };

  /**
   * Creates a BackupPageOCRTextboxFile message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPageOCRTextboxFile
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPageOCRTextboxFile} BackupPageOCRTextboxFile
   */
  BackupPageOCRTextboxFile.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPageOCRTextboxFile) return object;
    var message = new $root.BackupPageOCRTextboxFile();
    if (object.identifier != null)
      message.identifier = String(object.identifier);
    if (object.hash != null) message.hash = String(object.hash);
    if (object.fileSize != null)
      if ($util.Long)
        (message.fileSize = $util.Long.fromValue(
          object.fileSize
        )).unsigned = true;
      else if (typeof object.fileSize === "string")
        message.fileSize = parseInt(object.fileSize, 10);
      else if (typeof object.fileSize === "number")
        message.fileSize = object.fileSize;
      else if (typeof object.fileSize === "object")
        message.fileSize = new $util.LongBits(
          object.fileSize.low >>> 0,
          object.fileSize.high >>> 0
        ).toNumber(true);
    return message;
  };

  /**
   * Creates a plain object from a BackupPageOCRTextboxFile message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPageOCRTextboxFile
   * @static
   * @param {BackupPageOCRTextboxFile} message BackupPageOCRTextboxFile
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPageOCRTextboxFile.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.identifier = "";
      object.hash = "";
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.fileSize =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.fileSize = options.longs === String ? "0" : 0;
    }
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      object.identifier = message.identifier;
    if (message.hash != null && message.hasOwnProperty("hash"))
      object.hash = message.hash;
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (typeof message.fileSize === "number")
        object.fileSize =
          options.longs === String
            ? String(message.fileSize)
            : message.fileSize;
      else
        object.fileSize =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.fileSize)
            : options.longs === Number
            ? new $util.LongBits(
                message.fileSize.low >>> 0,
                message.fileSize.high >>> 0
              ).toNumber(true)
            : message.fileSize;
    return object;
  };

  /**
   * Converts this BackupPageOCRTextboxFile to JSON.
   * @function toJSON
   * @memberof BackupPageOCRTextboxFile
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPageOCRTextboxFile.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPageOCRTextboxFile;
})();

$root.BackupPageOCR = (function () {
  /**
   * Properties of a BackupPageOCR.
   * @exports IBackupPageOCR
   * @interface IBackupPageOCR
   * @property {IBackupPageOCRProp|null} [properties] BackupPageOCR properties
   * @property {IBackupPageOCRBgFile|null} [bgImage] BackupPageOCR bgImage
   * @property {IBackupPageOCRTextBgFile|null} [textBgImage] BackupPageOCR textBgImage
   * @property {IBackupPageOCRSourceFile|null} [sourceImage] BackupPageOCR sourceImage
   * @property {IBackupPageOCRTextlineFile|null} [textlineFile] BackupPageOCR textlineFile
   * @property {IBackupPageOCRTextboxFile|null} [textboxFile] BackupPageOCR textboxFile
   * @property {boolean|null} [usePrevTextlineFile] BackupPageOCR usePrevTextlineFile
   * @property {boolean|null} [usePrevTextboxFile] BackupPageOCR usePrevTextboxFile
   */

  /**
   * Constructs a new BackupPageOCR.
   * @exports BackupPageOCR
   * @classdesc Represents a BackupPageOCR.
   * @implements IBackupPageOCR
   * @constructor
   * @param {IBackupPageOCR=} [properties] Properties to set
   */
  function BackupPageOCR(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPageOCR properties.
   * @member {IBackupPageOCRProp|null|undefined} properties
   * @memberof BackupPageOCR
   * @instance
   */
  BackupPageOCR.prototype.properties = null;

  /**
   * BackupPageOCR bgImage.
   * @member {IBackupPageOCRBgFile|null|undefined} bgImage
   * @memberof BackupPageOCR
   * @instance
   */
  BackupPageOCR.prototype.bgImage = null;

  /**
   * BackupPageOCR textBgImage.
   * @member {IBackupPageOCRTextBgFile|null|undefined} textBgImage
   * @memberof BackupPageOCR
   * @instance
   */
  BackupPageOCR.prototype.textBgImage = null;

  /**
   * BackupPageOCR sourceImage.
   * @member {IBackupPageOCRSourceFile|null|undefined} sourceImage
   * @memberof BackupPageOCR
   * @instance
   */
  BackupPageOCR.prototype.sourceImage = null;

  /**
   * BackupPageOCR textlineFile.
   * @member {IBackupPageOCRTextlineFile|null|undefined} textlineFile
   * @memberof BackupPageOCR
   * @instance
   */
  BackupPageOCR.prototype.textlineFile = null;

  /**
   * BackupPageOCR textboxFile.
   * @member {IBackupPageOCRTextboxFile|null|undefined} textboxFile
   * @memberof BackupPageOCR
   * @instance
   */
  BackupPageOCR.prototype.textboxFile = null;

  /**
   * BackupPageOCR usePrevTextlineFile.
   * @member {boolean} usePrevTextlineFile
   * @memberof BackupPageOCR
   * @instance
   */
  BackupPageOCR.prototype.usePrevTextlineFile = false;

  /**
   * BackupPageOCR usePrevTextboxFile.
   * @member {boolean} usePrevTextboxFile
   * @memberof BackupPageOCR
   * @instance
   */
  BackupPageOCR.prototype.usePrevTextboxFile = false;

  /**
   * Creates a new BackupPageOCR instance using the specified properties.
   * @function create
   * @memberof BackupPageOCR
   * @static
   * @param {IBackupPageOCR=} [properties] Properties to set
   * @returns {BackupPageOCR} BackupPageOCR instance
   */
  BackupPageOCR.create = function create(properties) {
    return new BackupPageOCR(properties);
  };

  /**
   * Encodes the specified BackupPageOCR message. Does not implicitly {@link BackupPageOCR.verify|verify} messages.
   * @function encode
   * @memberof BackupPageOCR
   * @static
   * @param {IBackupPageOCR} message BackupPageOCR message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCR.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.properties != null &&
      Object.hasOwnProperty.call(message, "properties")
    )
      $root.BackupPageOCRProp.encode(
        message.properties,
        writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
      ).ldelim();
    if (
      message.bgImage != null &&
      Object.hasOwnProperty.call(message, "bgImage")
    )
      $root.BackupPageOCRBgFile.encode(
        message.bgImage,
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
      ).ldelim();
    if (
      message.textBgImage != null &&
      Object.hasOwnProperty.call(message, "textBgImage")
    )
      $root.BackupPageOCRTextBgFile.encode(
        message.textBgImage,
        writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
      ).ldelim();
    if (
      message.sourceImage != null &&
      Object.hasOwnProperty.call(message, "sourceImage")
    )
      $root.BackupPageOCRSourceFile.encode(
        message.sourceImage,
        writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
      ).ldelim();
    if (
      message.textlineFile != null &&
      Object.hasOwnProperty.call(message, "textlineFile")
    )
      $root.BackupPageOCRTextlineFile.encode(
        message.textlineFile,
        writer.uint32(/* id 5, wireType 2 =*/ 42).fork()
      ).ldelim();
    if (
      message.textboxFile != null &&
      Object.hasOwnProperty.call(message, "textboxFile")
    )
      $root.BackupPageOCRTextboxFile.encode(
        message.textboxFile,
        writer.uint32(/* id 6, wireType 2 =*/ 50).fork()
      ).ldelim();
    if (
      message.usePrevTextlineFile != null &&
      Object.hasOwnProperty.call(message, "usePrevTextlineFile")
    )
      writer
        .uint32(/* id 7, wireType 0 =*/ 56)
        .bool(message.usePrevTextlineFile);
    if (
      message.usePrevTextboxFile != null &&
      Object.hasOwnProperty.call(message, "usePrevTextboxFile")
    )
      writer
        .uint32(/* id 8, wireType 0 =*/ 64)
        .bool(message.usePrevTextboxFile);
    return writer;
  };

  /**
   * Encodes the specified BackupPageOCR message, length delimited. Does not implicitly {@link BackupPageOCR.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPageOCR
   * @static
   * @param {IBackupPageOCR} message BackupPageOCR message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageOCR.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPageOCR message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPageOCR
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPageOCR} BackupPageOCR
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCR.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPageOCR();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.properties = $root.BackupPageOCRProp.decode(
            reader,
            reader.uint32()
          );
          break;
        case 2:
          message.bgImage = $root.BackupPageOCRBgFile.decode(
            reader,
            reader.uint32()
          );
          break;
        case 3:
          message.textBgImage = $root.BackupPageOCRTextBgFile.decode(
            reader,
            reader.uint32()
          );
          break;
        case 4:
          message.sourceImage = $root.BackupPageOCRSourceFile.decode(
            reader,
            reader.uint32()
          );
          break;
        case 5:
          message.textlineFile = $root.BackupPageOCRTextlineFile.decode(
            reader,
            reader.uint32()
          );
          break;
        case 6:
          message.textboxFile = $root.BackupPageOCRTextboxFile.decode(
            reader,
            reader.uint32()
          );
          break;
        case 7:
          message.usePrevTextlineFile = reader.bool();
          break;
        case 8:
          message.usePrevTextboxFile = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPageOCR message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPageOCR
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPageOCR} BackupPageOCR
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageOCR.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPageOCR message.
   * @function verify
   * @memberof BackupPageOCR
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPageOCR.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.properties != null && message.hasOwnProperty("properties")) {
      var error = $root.BackupPageOCRProp.verify(message.properties);
      if (error) return "properties." + error;
    }
    if (message.bgImage != null && message.hasOwnProperty("bgImage")) {
      var error = $root.BackupPageOCRBgFile.verify(message.bgImage);
      if (error) return "bgImage." + error;
    }
    if (message.textBgImage != null && message.hasOwnProperty("textBgImage")) {
      var error = $root.BackupPageOCRTextBgFile.verify(message.textBgImage);
      if (error) return "textBgImage." + error;
    }
    if (message.sourceImage != null && message.hasOwnProperty("sourceImage")) {
      var error = $root.BackupPageOCRSourceFile.verify(message.sourceImage);
      if (error) return "sourceImage." + error;
    }
    if (
      message.textlineFile != null &&
      message.hasOwnProperty("textlineFile")
    ) {
      var error = $root.BackupPageOCRTextlineFile.verify(message.textlineFile);
      if (error) return "textlineFile." + error;
    }
    if (message.textboxFile != null && message.hasOwnProperty("textboxFile")) {
      var error = $root.BackupPageOCRTextboxFile.verify(message.textboxFile);
      if (error) return "textboxFile." + error;
    }
    if (
      message.usePrevTextlineFile != null &&
      message.hasOwnProperty("usePrevTextlineFile")
    )
      if (typeof message.usePrevTextlineFile !== "boolean")
        return "usePrevTextlineFile: boolean expected";
    if (
      message.usePrevTextboxFile != null &&
      message.hasOwnProperty("usePrevTextboxFile")
    )
      if (typeof message.usePrevTextboxFile !== "boolean")
        return "usePrevTextboxFile: boolean expected";
    return null;
  };

  /**
   * Creates a BackupPageOCR message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPageOCR
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPageOCR} BackupPageOCR
   */
  BackupPageOCR.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPageOCR) return object;
    var message = new $root.BackupPageOCR();
    if (object.properties != null) {
      if (typeof object.properties !== "object")
        throw TypeError(".BackupPageOCR.properties: object expected");
      message.properties = $root.BackupPageOCRProp.fromObject(
        object.properties
      );
    }
    if (object.bgImage != null) {
      if (typeof object.bgImage !== "object")
        throw TypeError(".BackupPageOCR.bgImage: object expected");
      message.bgImage = $root.BackupPageOCRBgFile.fromObject(object.bgImage);
    }
    if (object.textBgImage != null) {
      if (typeof object.textBgImage !== "object")
        throw TypeError(".BackupPageOCR.textBgImage: object expected");
      message.textBgImage = $root.BackupPageOCRTextBgFile.fromObject(
        object.textBgImage
      );
    }
    if (object.sourceImage != null) {
      if (typeof object.sourceImage !== "object")
        throw TypeError(".BackupPageOCR.sourceImage: object expected");
      message.sourceImage = $root.BackupPageOCRSourceFile.fromObject(
        object.sourceImage
      );
    }
    if (object.textlineFile != null) {
      if (typeof object.textlineFile !== "object")
        throw TypeError(".BackupPageOCR.textlineFile: object expected");
      message.textlineFile = $root.BackupPageOCRTextlineFile.fromObject(
        object.textlineFile
      );
    }
    if (object.textboxFile != null) {
      if (typeof object.textboxFile !== "object")
        throw TypeError(".BackupPageOCR.textboxFile: object expected");
      message.textboxFile = $root.BackupPageOCRTextboxFile.fromObject(
        object.textboxFile
      );
    }
    if (object.usePrevTextlineFile != null)
      message.usePrevTextlineFile = Boolean(object.usePrevTextlineFile);
    if (object.usePrevTextboxFile != null)
      message.usePrevTextboxFile = Boolean(object.usePrevTextboxFile);
    return message;
  };

  /**
   * Creates a plain object from a BackupPageOCR message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPageOCR
   * @static
   * @param {BackupPageOCR} message BackupPageOCR
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPageOCR.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.properties = null;
      object.bgImage = null;
      object.textBgImage = null;
      object.sourceImage = null;
      object.textlineFile = null;
      object.textboxFile = null;
      object.usePrevTextlineFile = false;
      object.usePrevTextboxFile = false;
    }
    if (message.properties != null && message.hasOwnProperty("properties"))
      object.properties = $root.BackupPageOCRProp.toObject(
        message.properties,
        options
      );
    if (message.bgImage != null && message.hasOwnProperty("bgImage"))
      object.bgImage = $root.BackupPageOCRBgFile.toObject(
        message.bgImage,
        options
      );
    if (message.textBgImage != null && message.hasOwnProperty("textBgImage"))
      object.textBgImage = $root.BackupPageOCRTextBgFile.toObject(
        message.textBgImage,
        options
      );
    if (message.sourceImage != null && message.hasOwnProperty("sourceImage"))
      object.sourceImage = $root.BackupPageOCRSourceFile.toObject(
        message.sourceImage,
        options
      );
    if (message.textlineFile != null && message.hasOwnProperty("textlineFile"))
      object.textlineFile = $root.BackupPageOCRTextlineFile.toObject(
        message.textlineFile,
        options
      );
    if (message.textboxFile != null && message.hasOwnProperty("textboxFile"))
      object.textboxFile = $root.BackupPageOCRTextboxFile.toObject(
        message.textboxFile,
        options
      );
    if (
      message.usePrevTextlineFile != null &&
      message.hasOwnProperty("usePrevTextlineFile")
    )
      object.usePrevTextlineFile = message.usePrevTextlineFile;
    if (
      message.usePrevTextboxFile != null &&
      message.hasOwnProperty("usePrevTextboxFile")
    )
      object.usePrevTextboxFile = message.usePrevTextboxFile;
    return object;
  };

  /**
   * Converts this BackupPageOCR to JSON.
   * @function toJSON
   * @memberof BackupPageOCR
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPageOCR.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPageOCR;
})();

$root.BackupPagePreview = (function () {
  /**
   * Properties of a BackupPagePreview.
   * @exports IBackupPagePreview
   * @interface IBackupPagePreview
   * @property {string|null} [identifier] BackupPagePreview identifier
   * @property {Array.<number>|null} [imageSize] BackupPagePreview imageSize
   * @property {number|Long|null} [fileSize] BackupPagePreview fileSize
   */

  /**
   * Constructs a new BackupPagePreview.
   * @exports BackupPagePreview
   * @classdesc Represents a BackupPagePreview.
   * @implements IBackupPagePreview
   * @constructor
   * @param {IBackupPagePreview=} [properties] Properties to set
   */
  function BackupPagePreview(properties) {
    this.imageSize = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPagePreview identifier.
   * @member {string} identifier
   * @memberof BackupPagePreview
   * @instance
   */
  BackupPagePreview.prototype.identifier = "";

  /**
   * BackupPagePreview imageSize.
   * @member {Array.<number>} imageSize
   * @memberof BackupPagePreview
   * @instance
   */
  BackupPagePreview.prototype.imageSize = $util.emptyArray;

  /**
   * BackupPagePreview fileSize.
   * @member {number|Long} fileSize
   * @memberof BackupPagePreview
   * @instance
   */
  BackupPagePreview.prototype.fileSize = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * Creates a new BackupPagePreview instance using the specified properties.
   * @function create
   * @memberof BackupPagePreview
   * @static
   * @param {IBackupPagePreview=} [properties] Properties to set
   * @returns {BackupPagePreview} BackupPagePreview instance
   */
  BackupPagePreview.create = function create(properties) {
    return new BackupPagePreview(properties);
  };

  /**
   * Encodes the specified BackupPagePreview message. Does not implicitly {@link BackupPagePreview.verify|verify} messages.
   * @function encode
   * @memberof BackupPagePreview
   * @static
   * @param {IBackupPagePreview} message BackupPagePreview message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPagePreview.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.identifier != null &&
      Object.hasOwnProperty.call(message, "identifier")
    )
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.identifier);
    if (message.imageSize != null && message.imageSize.length) {
      writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
      for (var i = 0; i < message.imageSize.length; ++i)
        writer.double(message.imageSize[i]);
      writer.ldelim();
    }
    if (
      message.fileSize != null &&
      Object.hasOwnProperty.call(message, "fileSize")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.fileSize);
    return writer;
  };

  /**
   * Encodes the specified BackupPagePreview message, length delimited. Does not implicitly {@link BackupPagePreview.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPagePreview
   * @static
   * @param {IBackupPagePreview} message BackupPagePreview message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPagePreview.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPagePreview message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPagePreview
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPagePreview} BackupPagePreview
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPagePreview.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPagePreview();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 2:
          if (!(message.imageSize && message.imageSize.length))
            message.imageSize = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) message.imageSize.push(reader.double());
          } else message.imageSize.push(reader.double());
          break;
        case 3:
          message.fileSize = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPagePreview message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPagePreview
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPagePreview} BackupPagePreview
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPagePreview.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPagePreview message.
   * @function verify
   * @memberof BackupPagePreview
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPagePreview.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      if (!$util.isString(message.identifier))
        return "identifier: string expected";
    if (message.imageSize != null && message.hasOwnProperty("imageSize")) {
      if (!Array.isArray(message.imageSize)) return "imageSize: array expected";
      for (var i = 0; i < message.imageSize.length; ++i)
        if (typeof message.imageSize[i] !== "number")
          return "imageSize: number[] expected";
    }
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (
        !$util.isInteger(message.fileSize) &&
        !(
          message.fileSize &&
          $util.isInteger(message.fileSize.low) &&
          $util.isInteger(message.fileSize.high)
        )
      )
        return "fileSize: integer|Long expected";
    return null;
  };

  /**
   * Creates a BackupPagePreview message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPagePreview
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPagePreview} BackupPagePreview
   */
  BackupPagePreview.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPagePreview) return object;
    var message = new $root.BackupPagePreview();
    if (object.identifier != null)
      message.identifier = String(object.identifier);
    if (object.imageSize) {
      if (!Array.isArray(object.imageSize))
        throw TypeError(".BackupPagePreview.imageSize: array expected");
      message.imageSize = [];
      for (var i = 0; i < object.imageSize.length; ++i)
        message.imageSize[i] = Number(object.imageSize[i]);
    }
    if (object.fileSize != null)
      if ($util.Long)
        (message.fileSize = $util.Long.fromValue(
          object.fileSize
        )).unsigned = true;
      else if (typeof object.fileSize === "string")
        message.fileSize = parseInt(object.fileSize, 10);
      else if (typeof object.fileSize === "number")
        message.fileSize = object.fileSize;
      else if (typeof object.fileSize === "object")
        message.fileSize = new $util.LongBits(
          object.fileSize.low >>> 0,
          object.fileSize.high >>> 0
        ).toNumber(true);
    return message;
  };

  /**
   * Creates a plain object from a BackupPagePreview message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPagePreview
   * @static
   * @param {BackupPagePreview} message BackupPagePreview
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPagePreview.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.imageSize = [];
    if (options.defaults) {
      object.identifier = "";
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.fileSize =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.fileSize = options.longs === String ? "0" : 0;
    }
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      object.identifier = message.identifier;
    if (message.imageSize && message.imageSize.length) {
      object.imageSize = [];
      for (var j = 0; j < message.imageSize.length; ++j)
        object.imageSize[j] =
          options.json && !isFinite(message.imageSize[j])
            ? String(message.imageSize[j])
            : message.imageSize[j];
    }
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (typeof message.fileSize === "number")
        object.fileSize =
          options.longs === String
            ? String(message.fileSize)
            : message.fileSize;
      else
        object.fileSize =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.fileSize)
            : options.longs === Number
            ? new $util.LongBits(
                message.fileSize.low >>> 0,
                message.fileSize.high >>> 0
              ).toNumber(true)
            : message.fileSize;
    return object;
  };

  /**
   * Converts this BackupPagePreview to JSON.
   * @function toJSON
   * @memberof BackupPagePreview
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPagePreview.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPagePreview;
})();

$root.BackupPage = (function () {
  /**
   * Properties of a BackupPage.
   * @exports IBackupPage
   * @interface IBackupPage
   * @property {IBackupPageFile|null} [file] BackupPage file
   * @property {IBackupPageProp|null} [properties] BackupPage properties
   * @property {IBackupPageOCR|null} [ocr] BackupPage ocr
   * @property {IBackupPagePreview|null} [preview] BackupPage preview
   * @property {boolean|null} [usePrevOCR] BackupPage usePrevOCR
   */

  /**
   * Constructs a new BackupPage.
   * @exports BackupPage
   * @classdesc Represents a BackupPage.
   * @implements IBackupPage
   * @constructor
   * @param {IBackupPage=} [properties] Properties to set
   */
  function BackupPage(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPage file.
   * @member {IBackupPageFile|null|undefined} file
   * @memberof BackupPage
   * @instance
   */
  BackupPage.prototype.file = null;

  /**
   * BackupPage properties.
   * @member {IBackupPageProp|null|undefined} properties
   * @memberof BackupPage
   * @instance
   */
  BackupPage.prototype.properties = null;

  /**
   * BackupPage ocr.
   * @member {IBackupPageOCR|null|undefined} ocr
   * @memberof BackupPage
   * @instance
   */
  BackupPage.prototype.ocr = null;

  /**
   * BackupPage preview.
   * @member {IBackupPagePreview|null|undefined} preview
   * @memberof BackupPage
   * @instance
   */
  BackupPage.prototype.preview = null;

  /**
   * BackupPage usePrevOCR.
   * @member {boolean} usePrevOCR
   * @memberof BackupPage
   * @instance
   */
  BackupPage.prototype.usePrevOCR = false;

  /**
   * Creates a new BackupPage instance using the specified properties.
   * @function create
   * @memberof BackupPage
   * @static
   * @param {IBackupPage=} [properties] Properties to set
   * @returns {BackupPage} BackupPage instance
   */
  BackupPage.create = function create(properties) {
    return new BackupPage(properties);
  };

  /**
   * Encodes the specified BackupPage message. Does not implicitly {@link BackupPage.verify|verify} messages.
   * @function encode
   * @memberof BackupPage
   * @static
   * @param {IBackupPage} message BackupPage message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPage.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.file != null && Object.hasOwnProperty.call(message, "file"))
      $root.BackupPageFile.encode(
        message.file,
        writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
      ).ldelim();
    if (
      message.properties != null &&
      Object.hasOwnProperty.call(message, "properties")
    )
      $root.BackupPageProp.encode(
        message.properties,
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
      ).ldelim();
    if (message.ocr != null && Object.hasOwnProperty.call(message, "ocr"))
      $root.BackupPageOCR.encode(
        message.ocr,
        writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
      ).ldelim();
    if (
      message.preview != null &&
      Object.hasOwnProperty.call(message, "preview")
    )
      $root.BackupPagePreview.encode(
        message.preview,
        writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
      ).ldelim();
    if (
      message.usePrevOCR != null &&
      Object.hasOwnProperty.call(message, "usePrevOCR")
    )
      writer.uint32(/* id 5, wireType 0 =*/ 40).bool(message.usePrevOCR);
    return writer;
  };

  /**
   * Encodes the specified BackupPage message, length delimited. Does not implicitly {@link BackupPage.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPage
   * @static
   * @param {IBackupPage} message BackupPage message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPage.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPage message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPage
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPage} BackupPage
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPage.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPage();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.file = $root.BackupPageFile.decode(reader, reader.uint32());
          break;
        case 2:
          message.properties = $root.BackupPageProp.decode(
            reader,
            reader.uint32()
          );
          break;
        case 3:
          message.ocr = $root.BackupPageOCR.decode(reader, reader.uint32());
          break;
        case 4:
          message.preview = $root.BackupPagePreview.decode(
            reader,
            reader.uint32()
          );
          break;
        case 5:
          message.usePrevOCR = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPage message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPage
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPage} BackupPage
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPage.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPage message.
   * @function verify
   * @memberof BackupPage
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPage.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.file != null && message.hasOwnProperty("file")) {
      var error = $root.BackupPageFile.verify(message.file);
      if (error) return "file." + error;
    }
    if (message.properties != null && message.hasOwnProperty("properties")) {
      var error = $root.BackupPageProp.verify(message.properties);
      if (error) return "properties." + error;
    }
    if (message.ocr != null && message.hasOwnProperty("ocr")) {
      var error = $root.BackupPageOCR.verify(message.ocr);
      if (error) return "ocr." + error;
    }
    if (message.preview != null && message.hasOwnProperty("preview")) {
      var error = $root.BackupPagePreview.verify(message.preview);
      if (error) return "preview." + error;
    }
    if (message.usePrevOCR != null && message.hasOwnProperty("usePrevOCR"))
      if (typeof message.usePrevOCR !== "boolean")
        return "usePrevOCR: boolean expected";
    return null;
  };

  /**
   * Creates a BackupPage message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPage
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPage} BackupPage
   */
  BackupPage.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPage) return object;
    var message = new $root.BackupPage();
    if (object.file != null) {
      if (typeof object.file !== "object")
        throw TypeError(".BackupPage.file: object expected");
      message.file = $root.BackupPageFile.fromObject(object.file);
    }
    if (object.properties != null) {
      if (typeof object.properties !== "object")
        throw TypeError(".BackupPage.properties: object expected");
      message.properties = $root.BackupPageProp.fromObject(object.properties);
    }
    if (object.ocr != null) {
      if (typeof object.ocr !== "object")
        throw TypeError(".BackupPage.ocr: object expected");
      message.ocr = $root.BackupPageOCR.fromObject(object.ocr);
    }
    if (object.preview != null) {
      if (typeof object.preview !== "object")
        throw TypeError(".BackupPage.preview: object expected");
      message.preview = $root.BackupPagePreview.fromObject(object.preview);
    }
    if (object.usePrevOCR != null)
      message.usePrevOCR = Boolean(object.usePrevOCR);
    return message;
  };

  /**
   * Creates a plain object from a BackupPage message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPage
   * @static
   * @param {BackupPage} message BackupPage
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPage.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.file = null;
      object.properties = null;
      object.ocr = null;
      object.preview = null;
      object.usePrevOCR = false;
    }
    if (message.file != null && message.hasOwnProperty("file"))
      object.file = $root.BackupPageFile.toObject(message.file, options);
    if (message.properties != null && message.hasOwnProperty("properties"))
      object.properties = $root.BackupPageProp.toObject(
        message.properties,
        options
      );
    if (message.ocr != null && message.hasOwnProperty("ocr"))
      object.ocr = $root.BackupPageOCR.toObject(message.ocr, options);
    if (message.preview != null && message.hasOwnProperty("preview"))
      object.preview = $root.BackupPagePreview.toObject(
        message.preview,
        options
      );
    if (message.usePrevOCR != null && message.hasOwnProperty("usePrevOCR"))
      object.usePrevOCR = message.usePrevOCR;
    return object;
  };

  /**
   * Converts this BackupPage to JSON.
   * @function toJSON
   * @memberof BackupPage
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPage.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPage;
})();

$root.BackupPageList = (function () {
  /**
   * Properties of a BackupPageList.
   * @exports IBackupPageList
   * @interface IBackupPageList
   * @property {string|null} [identifier] BackupPageList identifier
   * @property {IBackupPage|null} [page] BackupPageList page
   * @property {number|Long|null} [position] BackupPageList position
   * @property {string|null} [pageUUID] BackupPageList pageUUID
   */

  /**
   * Constructs a new BackupPageList.
   * @exports BackupPageList
   * @classdesc Represents a BackupPageList.
   * @implements IBackupPageList
   * @constructor
   * @param {IBackupPageList=} [properties] Properties to set
   */
  function BackupPageList(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPageList identifier.
   * @member {string} identifier
   * @memberof BackupPageList
   * @instance
   */
  BackupPageList.prototype.identifier = "";

  /**
   * BackupPageList page.
   * @member {IBackupPage|null|undefined} page
   * @memberof BackupPageList
   * @instance
   */
  BackupPageList.prototype.page = null;

  /**
   * BackupPageList position.
   * @member {number|Long} position
   * @memberof BackupPageList
   * @instance
   */
  BackupPageList.prototype.position = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * BackupPageList pageUUID.
   * @member {string} pageUUID
   * @memberof BackupPageList
   * @instance
   */
  BackupPageList.prototype.pageUUID = "";

  /**
   * Creates a new BackupPageList instance using the specified properties.
   * @function create
   * @memberof BackupPageList
   * @static
   * @param {IBackupPageList=} [properties] Properties to set
   * @returns {BackupPageList} BackupPageList instance
   */
  BackupPageList.create = function create(properties) {
    return new BackupPageList(properties);
  };

  /**
   * Encodes the specified BackupPageList message. Does not implicitly {@link BackupPageList.verify|verify} messages.
   * @function encode
   * @memberof BackupPageList
   * @static
   * @param {IBackupPageList} message BackupPageList message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageList.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.identifier != null &&
      Object.hasOwnProperty.call(message, "identifier")
    )
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.identifier);
    if (message.page != null && Object.hasOwnProperty.call(message, "page"))
      $root.BackupPage.encode(
        message.page,
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
      ).ldelim();
    if (
      message.position != null &&
      Object.hasOwnProperty.call(message, "position")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.position);
    if (
      message.pageUUID != null &&
      Object.hasOwnProperty.call(message, "pageUUID")
    )
      writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.pageUUID);
    return writer;
  };

  /**
   * Encodes the specified BackupPageList message, length delimited. Does not implicitly {@link BackupPageList.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPageList
   * @static
   * @param {IBackupPageList} message BackupPageList message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageList.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPageList message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPageList
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPageList} BackupPageList
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageList.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPageList();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 2:
          message.page = $root.BackupPage.decode(reader, reader.uint32());
          break;
        case 3:
          message.position = reader.uint64();
          break;
        case 4:
          message.pageUUID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPageList message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPageList
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPageList} BackupPageList
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageList.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPageList message.
   * @function verify
   * @memberof BackupPageList
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPageList.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      if (!$util.isString(message.identifier))
        return "identifier: string expected";
    if (message.page != null && message.hasOwnProperty("page")) {
      var error = $root.BackupPage.verify(message.page);
      if (error) return "page." + error;
    }
    if (message.position != null && message.hasOwnProperty("position"))
      if (
        !$util.isInteger(message.position) &&
        !(
          message.position &&
          $util.isInteger(message.position.low) &&
          $util.isInteger(message.position.high)
        )
      )
        return "position: integer|Long expected";
    if (message.pageUUID != null && message.hasOwnProperty("pageUUID"))
      if (!$util.isString(message.pageUUID)) return "pageUUID: string expected";
    return null;
  };

  /**
   * Creates a BackupPageList message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPageList
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPageList} BackupPageList
   */
  BackupPageList.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPageList) return object;
    var message = new $root.BackupPageList();
    if (object.identifier != null)
      message.identifier = String(object.identifier);
    if (object.page != null) {
      if (typeof object.page !== "object")
        throw TypeError(".BackupPageList.page: object expected");
      message.page = $root.BackupPage.fromObject(object.page);
    }
    if (object.position != null)
      if ($util.Long)
        (message.position = $util.Long.fromValue(
          object.position
        )).unsigned = true;
      else if (typeof object.position === "string")
        message.position = parseInt(object.position, 10);
      else if (typeof object.position === "number")
        message.position = object.position;
      else if (typeof object.position === "object")
        message.position = new $util.LongBits(
          object.position.low >>> 0,
          object.position.high >>> 0
        ).toNumber(true);
    if (object.pageUUID != null) message.pageUUID = String(object.pageUUID);
    return message;
  };

  /**
   * Creates a plain object from a BackupPageList message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPageList
   * @static
   * @param {BackupPageList} message BackupPageList
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPageList.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.identifier = "";
      object.page = null;
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.position =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.position = options.longs === String ? "0" : 0;
      object.pageUUID = "";
    }
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      object.identifier = message.identifier;
    if (message.page != null && message.hasOwnProperty("page"))
      object.page = $root.BackupPage.toObject(message.page, options);
    if (message.position != null && message.hasOwnProperty("position"))
      if (typeof message.position === "number")
        object.position =
          options.longs === String
            ? String(message.position)
            : message.position;
      else
        object.position =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.position)
            : options.longs === Number
            ? new $util.LongBits(
                message.position.low >>> 0,
                message.position.high >>> 0
              ).toNumber(true)
            : message.position;
    if (message.pageUUID != null && message.hasOwnProperty("pageUUID"))
      object.pageUUID = message.pageUUID;
    return object;
  };

  /**
   * Converts this BackupPageList to JSON.
   * @function toJSON
   * @memberof BackupPageList
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPageList.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPageList;
})();

$root.BackupDocProp = (function () {
  /**
   * Properties of a BackupDocProp.
   * @exports IBackupDocProp
   * @interface IBackupDocProp
   * @property {string|null} [uuid] BackupDocProp uuid
   * @property {string|null} [lastModifiedDateMsString] BackupDocProp lastModifiedDateMsString
   * @property {number|null} [pageSizeNum] BackupDocProp pageSizeNum
   * @property {string|null} [title] BackupDocProp title
   * @property {Array.<number>|null} [customPageSizeValue] BackupDocProp customPageSizeValue
   */

  /**
   * Constructs a new BackupDocProp.
   * @exports BackupDocProp
   * @classdesc Represents a BackupDocProp.
   * @implements IBackupDocProp
   * @constructor
   * @param {IBackupDocProp=} [properties] Properties to set
   */
  function BackupDocProp(properties) {
    this.customPageSizeValue = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupDocProp uuid.
   * @member {string} uuid
   * @memberof BackupDocProp
   * @instance
   */
  BackupDocProp.prototype.uuid = "";

  /**
   * BackupDocProp lastModifiedDateMsString.
   * @member {string} lastModifiedDateMsString
   * @memberof BackupDocProp
   * @instance
   */
  BackupDocProp.prototype.lastModifiedDateMsString = "";

  /**
   * BackupDocProp pageSizeNum.
   * @member {number} pageSizeNum
   * @memberof BackupDocProp
   * @instance
   */
  BackupDocProp.prototype.pageSizeNum = 0;

  /**
   * BackupDocProp title.
   * @member {string} title
   * @memberof BackupDocProp
   * @instance
   */
  BackupDocProp.prototype.title = "";

  /**
   * BackupDocProp customPageSizeValue.
   * @member {Array.<number>} customPageSizeValue
   * @memberof BackupDocProp
   * @instance
   */
  BackupDocProp.prototype.customPageSizeValue = $util.emptyArray;

  /**
   * Creates a new BackupDocProp instance using the specified properties.
   * @function create
   * @memberof BackupDocProp
   * @static
   * @param {IBackupDocProp=} [properties] Properties to set
   * @returns {BackupDocProp} BackupDocProp instance
   */
  BackupDocProp.create = function create(properties) {
    return new BackupDocProp(properties);
  };

  /**
   * Encodes the specified BackupDocProp message. Does not implicitly {@link BackupDocProp.verify|verify} messages.
   * @function encode
   * @memberof BackupDocProp
   * @static
   * @param {IBackupDocProp} message BackupDocProp message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupDocProp.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.uuid);
    if (
      message.lastModifiedDateMsString != null &&
      Object.hasOwnProperty.call(message, "lastModifiedDateMsString")
    )
      writer
        .uint32(/* id 2, wireType 2 =*/ 18)
        .string(message.lastModifiedDateMsString);
    if (
      message.pageSizeNum != null &&
      Object.hasOwnProperty.call(message, "pageSizeNum")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.pageSizeNum);
    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
      writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.title);
    if (
      message.customPageSizeValue != null &&
      message.customPageSizeValue.length
    ) {
      writer.uint32(/* id 5, wireType 2 =*/ 42).fork();
      for (var i = 0; i < message.customPageSizeValue.length; ++i)
        writer.double(message.customPageSizeValue[i]);
      writer.ldelim();
    }
    return writer;
  };

  /**
   * Encodes the specified BackupDocProp message, length delimited. Does not implicitly {@link BackupDocProp.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupDocProp
   * @static
   * @param {IBackupDocProp} message BackupDocProp message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupDocProp.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupDocProp message from the specified reader or buffer.
   * @function decode
   * @memberof BackupDocProp
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupDocProp} BackupDocProp
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupDocProp.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupDocProp();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.uuid = reader.string();
          break;
        case 2:
          message.lastModifiedDateMsString = reader.string();
          break;
        case 3:
          message.pageSizeNum = reader.int32();
          break;
        case 4:
          message.title = reader.string();
          break;
        case 5:
          if (
            !(message.customPageSizeValue && message.customPageSizeValue.length)
          )
            message.customPageSizeValue = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2)
              message.customPageSizeValue.push(reader.double());
          } else message.customPageSizeValue.push(reader.double());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupDocProp message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupDocProp
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupDocProp} BackupDocProp
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupDocProp.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupDocProp message.
   * @function verify
   * @memberof BackupDocProp
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupDocProp.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.uuid != null && message.hasOwnProperty("uuid"))
      if (!$util.isString(message.uuid)) return "uuid: string expected";
    if (
      message.lastModifiedDateMsString != null &&
      message.hasOwnProperty("lastModifiedDateMsString")
    )
      if (!$util.isString(message.lastModifiedDateMsString))
        return "lastModifiedDateMsString: string expected";
    if (message.pageSizeNum != null && message.hasOwnProperty("pageSizeNum"))
      if (!$util.isInteger(message.pageSizeNum))
        return "pageSizeNum: integer expected";
    if (message.title != null && message.hasOwnProperty("title"))
      if (!$util.isString(message.title)) return "title: string expected";
    if (
      message.customPageSizeValue != null &&
      message.hasOwnProperty("customPageSizeValue")
    ) {
      if (!Array.isArray(message.customPageSizeValue))
        return "customPageSizeValue: array expected";
      for (var i = 0; i < message.customPageSizeValue.length; ++i)
        if (typeof message.customPageSizeValue[i] !== "number")
          return "customPageSizeValue: number[] expected";
    }
    return null;
  };

  /**
   * Creates a BackupDocProp message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupDocProp
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupDocProp} BackupDocProp
   */
  BackupDocProp.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupDocProp) return object;
    var message = new $root.BackupDocProp();
    if (object.uuid != null) message.uuid = String(object.uuid);
    if (object.lastModifiedDateMsString != null)
      message.lastModifiedDateMsString = String(
        object.lastModifiedDateMsString
      );
    if (object.pageSizeNum != null)
      message.pageSizeNum = object.pageSizeNum | 0;
    if (object.title != null) message.title = String(object.title);
    if (object.customPageSizeValue) {
      if (!Array.isArray(object.customPageSizeValue))
        throw TypeError(".BackupDocProp.customPageSizeValue: array expected");
      message.customPageSizeValue = [];
      for (var i = 0; i < object.customPageSizeValue.length; ++i)
        message.customPageSizeValue[i] = Number(object.customPageSizeValue[i]);
    }
    return message;
  };

  /**
   * Creates a plain object from a BackupDocProp message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupDocProp
   * @static
   * @param {BackupDocProp} message BackupDocProp
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupDocProp.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.customPageSizeValue = [];
    if (options.defaults) {
      object.uuid = "";
      object.lastModifiedDateMsString = "";
      object.pageSizeNum = 0;
      object.title = "";
    }
    if (message.uuid != null && message.hasOwnProperty("uuid"))
      object.uuid = message.uuid;
    if (
      message.lastModifiedDateMsString != null &&
      message.hasOwnProperty("lastModifiedDateMsString")
    )
      object.lastModifiedDateMsString = message.lastModifiedDateMsString;
    if (message.pageSizeNum != null && message.hasOwnProperty("pageSizeNum"))
      object.pageSizeNum = message.pageSizeNum;
    if (message.title != null && message.hasOwnProperty("title"))
      object.title = message.title;
    if (message.customPageSizeValue && message.customPageSizeValue.length) {
      object.customPageSizeValue = [];
      for (var j = 0; j < message.customPageSizeValue.length; ++j)
        object.customPageSizeValue[j] =
          options.json && !isFinite(message.customPageSizeValue[j])
            ? String(message.customPageSizeValue[j])
            : message.customPageSizeValue[j];
    }
    return object;
  };

  /**
   * Converts this BackupDocProp to JSON.
   * @function toJSON
   * @memberof BackupDocProp
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupDocProp.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupDocProp;
})();

$root.BackupDoc = (function () {
  /**
   * Properties of a BackupDoc.
   * @exports IBackupDoc
   * @interface IBackupDoc
   * @property {IBackupDocProp|null} [properties] BackupDoc properties
   * @property {string|null} [inFolderName] BackupDoc inFolderName
   * @property {Array.<IBackupPageList>|null} [pages] BackupDoc pages
   * @property {string|null} [pagesIdentifier] BackupDoc pagesIdentifier
   * @property {string|null} [uuid] BackupDoc uuid
   * @property {boolean|null} [usePrevDoc] BackupDoc usePrevDoc
   */

  /**
   * Constructs a new BackupDoc.
   * @exports BackupDoc
   * @classdesc Represents a BackupDoc.
   * @implements IBackupDoc
   * @constructor
   * @param {IBackupDoc=} [properties] Properties to set
   */
  function BackupDoc(properties) {
    this.pages = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupDoc properties.
   * @member {IBackupDocProp|null|undefined} properties
   * @memberof BackupDoc
   * @instance
   */
  BackupDoc.prototype.properties = null;

  /**
   * BackupDoc inFolderName.
   * @member {string} inFolderName
   * @memberof BackupDoc
   * @instance
   */
  BackupDoc.prototype.inFolderName = "";

  /**
   * BackupDoc pages.
   * @member {Array.<IBackupPageList>} pages
   * @memberof BackupDoc
   * @instance
   */
  BackupDoc.prototype.pages = $util.emptyArray;

  /**
   * BackupDoc pagesIdentifier.
   * @member {string} pagesIdentifier
   * @memberof BackupDoc
   * @instance
   */
  BackupDoc.prototype.pagesIdentifier = "";

  /**
   * BackupDoc uuid.
   * @member {string} uuid
   * @memberof BackupDoc
   * @instance
   */
  BackupDoc.prototype.uuid = "";

  /**
   * BackupDoc usePrevDoc.
   * @member {boolean} usePrevDoc
   * @memberof BackupDoc
   * @instance
   */
  BackupDoc.prototype.usePrevDoc = false;

  /**
   * Creates a new BackupDoc instance using the specified properties.
   * @function create
   * @memberof BackupDoc
   * @static
   * @param {IBackupDoc=} [properties] Properties to set
   * @returns {BackupDoc} BackupDoc instance
   */
  BackupDoc.create = function create(properties) {
    return new BackupDoc(properties);
  };

  /**
   * Encodes the specified BackupDoc message. Does not implicitly {@link BackupDoc.verify|verify} messages.
   * @function encode
   * @memberof BackupDoc
   * @static
   * @param {IBackupDoc} message BackupDoc message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupDoc.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.properties != null &&
      Object.hasOwnProperty.call(message, "properties")
    )
      $root.BackupDocProp.encode(
        message.properties,
        writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
      ).ldelim();
    if (
      message.inFolderName != null &&
      Object.hasOwnProperty.call(message, "inFolderName")
    )
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.inFolderName);
    if (message.pages != null && message.pages.length)
      for (var i = 0; i < message.pages.length; ++i)
        $root.BackupPageList.encode(
          message.pages[i],
          writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
        ).ldelim();
    if (
      message.pagesIdentifier != null &&
      Object.hasOwnProperty.call(message, "pagesIdentifier")
    )
      writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.pagesIdentifier);
    if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
      writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.uuid);
    if (
      message.usePrevDoc != null &&
      Object.hasOwnProperty.call(message, "usePrevDoc")
    )
      writer.uint32(/* id 6, wireType 0 =*/ 48).bool(message.usePrevDoc);
    return writer;
  };

  /**
   * Encodes the specified BackupDoc message, length delimited. Does not implicitly {@link BackupDoc.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupDoc
   * @static
   * @param {IBackupDoc} message BackupDoc message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupDoc.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupDoc message from the specified reader or buffer.
   * @function decode
   * @memberof BackupDoc
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupDoc} BackupDoc
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupDoc.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupDoc();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.properties = $root.BackupDocProp.decode(
            reader,
            reader.uint32()
          );
          break;
        case 2:
          message.inFolderName = reader.string();
          break;
        case 3:
          if (!(message.pages && message.pages.length)) message.pages = [];
          message.pages.push(
            $root.BackupPageList.decode(reader, reader.uint32())
          );
          break;
        case 4:
          message.pagesIdentifier = reader.string();
          break;
        case 5:
          message.uuid = reader.string();
          break;
        case 6:
          message.usePrevDoc = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupDoc message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupDoc
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupDoc} BackupDoc
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupDoc.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupDoc message.
   * @function verify
   * @memberof BackupDoc
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupDoc.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.properties != null && message.hasOwnProperty("properties")) {
      var error = $root.BackupDocProp.verify(message.properties);
      if (error) return "properties." + error;
    }
    if (message.inFolderName != null && message.hasOwnProperty("inFolderName"))
      if (!$util.isString(message.inFolderName))
        return "inFolderName: string expected";
    if (message.pages != null && message.hasOwnProperty("pages")) {
      if (!Array.isArray(message.pages)) return "pages: array expected";
      for (var i = 0; i < message.pages.length; ++i) {
        var error = $root.BackupPageList.verify(message.pages[i]);
        if (error) return "pages." + error;
      }
    }
    if (
      message.pagesIdentifier != null &&
      message.hasOwnProperty("pagesIdentifier")
    )
      if (!$util.isString(message.pagesIdentifier))
        return "pagesIdentifier: string expected";
    if (message.uuid != null && message.hasOwnProperty("uuid"))
      if (!$util.isString(message.uuid)) return "uuid: string expected";
    if (message.usePrevDoc != null && message.hasOwnProperty("usePrevDoc"))
      if (typeof message.usePrevDoc !== "boolean")
        return "usePrevDoc: boolean expected";
    return null;
  };

  /**
   * Creates a BackupDoc message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupDoc
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupDoc} BackupDoc
   */
  BackupDoc.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupDoc) return object;
    var message = new $root.BackupDoc();
    if (object.properties != null) {
      if (typeof object.properties !== "object")
        throw TypeError(".BackupDoc.properties: object expected");
      message.properties = $root.BackupDocProp.fromObject(object.properties);
    }
    if (object.inFolderName != null)
      message.inFolderName = String(object.inFolderName);
    if (object.pages) {
      if (!Array.isArray(object.pages))
        throw TypeError(".BackupDoc.pages: array expected");
      message.pages = [];
      for (var i = 0; i < object.pages.length; ++i) {
        if (typeof object.pages[i] !== "object")
          throw TypeError(".BackupDoc.pages: object expected");
        message.pages[i] = $root.BackupPageList.fromObject(object.pages[i]);
      }
    }
    if (object.pagesIdentifier != null)
      message.pagesIdentifier = String(object.pagesIdentifier);
    if (object.uuid != null) message.uuid = String(object.uuid);
    if (object.usePrevDoc != null)
      message.usePrevDoc = Boolean(object.usePrevDoc);
    return message;
  };

  /**
   * Creates a plain object from a BackupDoc message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupDoc
   * @static
   * @param {BackupDoc} message BackupDoc
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupDoc.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.pages = [];
    if (options.defaults) {
      object.properties = null;
      object.inFolderName = "";
      object.pagesIdentifier = "";
      object.uuid = "";
      object.usePrevDoc = false;
    }
    if (message.properties != null && message.hasOwnProperty("properties"))
      object.properties = $root.BackupDocProp.toObject(
        message.properties,
        options
      );
    if (message.inFolderName != null && message.hasOwnProperty("inFolderName"))
      object.inFolderName = message.inFolderName;
    if (message.pages && message.pages.length) {
      object.pages = [];
      for (var j = 0; j < message.pages.length; ++j)
        object.pages[j] = $root.BackupPageList.toObject(
          message.pages[j],
          options
        );
    }
    if (
      message.pagesIdentifier != null &&
      message.hasOwnProperty("pagesIdentifier")
    )
      object.pagesIdentifier = message.pagesIdentifier;
    if (message.uuid != null && message.hasOwnProperty("uuid"))
      object.uuid = message.uuid;
    if (message.usePrevDoc != null && message.hasOwnProperty("usePrevDoc"))
      object.usePrevDoc = message.usePrevDoc;
    return object;
  };

  /**
   * Converts this BackupDoc to JSON.
   * @function toJSON
   * @memberof BackupDoc
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupDoc.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupDoc;
})();

$root.BackupFolder = (function () {
  /**
   * Properties of a BackupFolder.
   * @exports IBackupFolder
   * @interface IBackupFolder
   * @property {string|null} [name] BackupFolder name
   * @property {string|null} [password] BackupFolder password
   * @property {Array.<IBackupDoc>|null} [docs] BackupFolder docs
   * @property {boolean|null} [usePrevFolder] BackupFolder usePrevFolder
   */

  /**
   * Constructs a new BackupFolder.
   * @exports BackupFolder
   * @classdesc Represents a BackupFolder.
   * @implements IBackupFolder
   * @constructor
   * @param {IBackupFolder=} [properties] Properties to set
   */
  function BackupFolder(properties) {
    this.docs = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupFolder name.
   * @member {string} name
   * @memberof BackupFolder
   * @instance
   */
  BackupFolder.prototype.name = "";

  /**
   * BackupFolder password.
   * @member {string} password
   * @memberof BackupFolder
   * @instance
   */
  BackupFolder.prototype.password = "";

  /**
   * BackupFolder docs.
   * @member {Array.<IBackupDoc>} docs
   * @memberof BackupFolder
   * @instance
   */
  BackupFolder.prototype.docs = $util.emptyArray;

  /**
   * BackupFolder usePrevFolder.
   * @member {boolean} usePrevFolder
   * @memberof BackupFolder
   * @instance
   */
  BackupFolder.prototype.usePrevFolder = false;

  /**
   * Creates a new BackupFolder instance using the specified properties.
   * @function create
   * @memberof BackupFolder
   * @static
   * @param {IBackupFolder=} [properties] Properties to set
   * @returns {BackupFolder} BackupFolder instance
   */
  BackupFolder.create = function create(properties) {
    return new BackupFolder(properties);
  };

  /**
   * Encodes the specified BackupFolder message. Does not implicitly {@link BackupFolder.verify|verify} messages.
   * @function encode
   * @memberof BackupFolder
   * @static
   * @param {IBackupFolder} message BackupFolder message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupFolder.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name);
    if (
      message.password != null &&
      Object.hasOwnProperty.call(message, "password")
    )
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.password);
    if (message.docs != null && message.docs.length)
      for (var i = 0; i < message.docs.length; ++i)
        $root.BackupDoc.encode(
          message.docs[i],
          writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
        ).ldelim();
    if (
      message.usePrevFolder != null &&
      Object.hasOwnProperty.call(message, "usePrevFolder")
    )
      writer.uint32(/* id 4, wireType 0 =*/ 32).bool(message.usePrevFolder);
    return writer;
  };

  /**
   * Encodes the specified BackupFolder message, length delimited. Does not implicitly {@link BackupFolder.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupFolder
   * @static
   * @param {IBackupFolder} message BackupFolder message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupFolder.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupFolder message from the specified reader or buffer.
   * @function decode
   * @memberof BackupFolder
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupFolder} BackupFolder
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupFolder.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupFolder();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.password = reader.string();
          break;
        case 3:
          if (!(message.docs && message.docs.length)) message.docs = [];
          message.docs.push($root.BackupDoc.decode(reader, reader.uint32()));
          break;
        case 4:
          message.usePrevFolder = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupFolder message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupFolder
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupFolder} BackupFolder
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupFolder.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupFolder message.
   * @function verify
   * @memberof BackupFolder
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupFolder.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.name != null && message.hasOwnProperty("name"))
      if (!$util.isString(message.name)) return "name: string expected";
    if (message.password != null && message.hasOwnProperty("password"))
      if (!$util.isString(message.password)) return "password: string expected";
    if (message.docs != null && message.hasOwnProperty("docs")) {
      if (!Array.isArray(message.docs)) return "docs: array expected";
      for (var i = 0; i < message.docs.length; ++i) {
        var error = $root.BackupDoc.verify(message.docs[i]);
        if (error) return "docs." + error;
      }
    }
    if (
      message.usePrevFolder != null &&
      message.hasOwnProperty("usePrevFolder")
    )
      if (typeof message.usePrevFolder !== "boolean")
        return "usePrevFolder: boolean expected";
    return null;
  };

  /**
   * Creates a BackupFolder message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupFolder
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupFolder} BackupFolder
   */
  BackupFolder.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupFolder) return object;
    var message = new $root.BackupFolder();
    if (object.name != null) message.name = String(object.name);
    if (object.password != null) message.password = String(object.password);
    if (object.docs) {
      if (!Array.isArray(object.docs))
        throw TypeError(".BackupFolder.docs: array expected");
      message.docs = [];
      for (var i = 0; i < object.docs.length; ++i) {
        if (typeof object.docs[i] !== "object")
          throw TypeError(".BackupFolder.docs: object expected");
        message.docs[i] = $root.BackupDoc.fromObject(object.docs[i]);
      }
    }
    if (object.usePrevFolder != null)
      message.usePrevFolder = Boolean(object.usePrevFolder);
    return message;
  };

  /**
   * Creates a plain object from a BackupFolder message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupFolder
   * @static
   * @param {BackupFolder} message BackupFolder
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupFolder.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.docs = [];
    if (options.defaults) {
      object.name = "";
      object.password = "";
      object.usePrevFolder = false;
    }
    if (message.name != null && message.hasOwnProperty("name"))
      object.name = message.name;
    if (message.password != null && message.hasOwnProperty("password"))
      object.password = message.password;
    if (message.docs && message.docs.length) {
      object.docs = [];
      for (var j = 0; j < message.docs.length; ++j)
        object.docs[j] = $root.BackupDoc.toObject(message.docs[j], options);
    }
    if (
      message.usePrevFolder != null &&
      message.hasOwnProperty("usePrevFolder")
    )
      object.usePrevFolder = message.usePrevFolder;
    return object;
  };

  /**
   * Converts this BackupFolder to JSON.
   * @function toJSON
   * @memberof BackupFolder
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupFolder.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupFolder;
})();

$root.BackupSettings = (function () {
  /**
   * Properties of a BackupSettings.
   * @exports IBackupSettings
   * @interface IBackupSettings
   * @property {string|null} [identifier] BackupSettings identifier
   * @property {string|null} [hash] BackupSettings hash
   * @property {number|Long|null} [fileSize] BackupSettings fileSize
   * @property {boolean|null} [usePrevSettings] BackupSettings usePrevSettings
   * @property {string|null} [backupUUID] BackupSettings backupUUID
   * @property {string|null} [prevBackupUUID] BackupSettings prevBackupUUID
   */

  /**
   * Constructs a new BackupSettings.
   * @exports BackupSettings
   * @classdesc Represents a BackupSettings.
   * @implements IBackupSettings
   * @constructor
   * @param {IBackupSettings=} [properties] Properties to set
   */
  function BackupSettings(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupSettings identifier.
   * @member {string} identifier
   * @memberof BackupSettings
   * @instance
   */
  BackupSettings.prototype.identifier = "";

  /**
   * BackupSettings hash.
   * @member {string} hash
   * @memberof BackupSettings
   * @instance
   */
  BackupSettings.prototype.hash = "";

  /**
   * BackupSettings fileSize.
   * @member {number|Long} fileSize
   * @memberof BackupSettings
   * @instance
   */
  BackupSettings.prototype.fileSize = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * BackupSettings usePrevSettings.
   * @member {boolean} usePrevSettings
   * @memberof BackupSettings
   * @instance
   */
  BackupSettings.prototype.usePrevSettings = false;

  /**
   * BackupSettings backupUUID.
   * @member {string} backupUUID
   * @memberof BackupSettings
   * @instance
   */
  BackupSettings.prototype.backupUUID = "";

  /**
   * BackupSettings prevBackupUUID.
   * @member {string} prevBackupUUID
   * @memberof BackupSettings
   * @instance
   */
  BackupSettings.prototype.prevBackupUUID = "";

  /**
   * Creates a new BackupSettings instance using the specified properties.
   * @function create
   * @memberof BackupSettings
   * @static
   * @param {IBackupSettings=} [properties] Properties to set
   * @returns {BackupSettings} BackupSettings instance
   */
  BackupSettings.create = function create(properties) {
    return new BackupSettings(properties);
  };

  /**
   * Encodes the specified BackupSettings message. Does not implicitly {@link BackupSettings.verify|verify} messages.
   * @function encode
   * @memberof BackupSettings
   * @static
   * @param {IBackupSettings} message BackupSettings message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupSettings.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.identifier != null &&
      Object.hasOwnProperty.call(message, "identifier")
    )
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.identifier);
    if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.hash);
    if (
      message.fileSize != null &&
      Object.hasOwnProperty.call(message, "fileSize")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.fileSize);
    if (
      message.usePrevSettings != null &&
      Object.hasOwnProperty.call(message, "usePrevSettings")
    )
      writer.uint32(/* id 4, wireType 0 =*/ 32).bool(message.usePrevSettings);
    if (
      message.backupUUID != null &&
      Object.hasOwnProperty.call(message, "backupUUID")
    )
      writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.backupUUID);
    if (
      message.prevBackupUUID != null &&
      Object.hasOwnProperty.call(message, "prevBackupUUID")
    )
      writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.prevBackupUUID);
    return writer;
  };

  /**
   * Encodes the specified BackupSettings message, length delimited. Does not implicitly {@link BackupSettings.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupSettings
   * @static
   * @param {IBackupSettings} message BackupSettings message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupSettings.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupSettings message from the specified reader or buffer.
   * @function decode
   * @memberof BackupSettings
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupSettings} BackupSettings
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupSettings.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupSettings();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifier = reader.string();
          break;
        case 2:
          message.hash = reader.string();
          break;
        case 3:
          message.fileSize = reader.uint64();
          break;
        case 4:
          message.usePrevSettings = reader.bool();
          break;
        case 5:
          message.backupUUID = reader.string();
          break;
        case 6:
          message.prevBackupUUID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupSettings message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupSettings
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupSettings} BackupSettings
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupSettings.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupSettings message.
   * @function verify
   * @memberof BackupSettings
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupSettings.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      if (!$util.isString(message.identifier))
        return "identifier: string expected";
    if (message.hash != null && message.hasOwnProperty("hash"))
      if (!$util.isString(message.hash)) return "hash: string expected";
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (
        !$util.isInteger(message.fileSize) &&
        !(
          message.fileSize &&
          $util.isInteger(message.fileSize.low) &&
          $util.isInteger(message.fileSize.high)
        )
      )
        return "fileSize: integer|Long expected";
    if (
      message.usePrevSettings != null &&
      message.hasOwnProperty("usePrevSettings")
    )
      if (typeof message.usePrevSettings !== "boolean")
        return "usePrevSettings: boolean expected";
    if (message.backupUUID != null && message.hasOwnProperty("backupUUID"))
      if (!$util.isString(message.backupUUID))
        return "backupUUID: string expected";
    if (
      message.prevBackupUUID != null &&
      message.hasOwnProperty("prevBackupUUID")
    )
      if (!$util.isString(message.prevBackupUUID))
        return "prevBackupUUID: string expected";
    return null;
  };

  /**
   * Creates a BackupSettings message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupSettings
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupSettings} BackupSettings
   */
  BackupSettings.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupSettings) return object;
    var message = new $root.BackupSettings();
    if (object.identifier != null)
      message.identifier = String(object.identifier);
    if (object.hash != null) message.hash = String(object.hash);
    if (object.fileSize != null)
      if ($util.Long)
        (message.fileSize = $util.Long.fromValue(
          object.fileSize
        )).unsigned = true;
      else if (typeof object.fileSize === "string")
        message.fileSize = parseInt(object.fileSize, 10);
      else if (typeof object.fileSize === "number")
        message.fileSize = object.fileSize;
      else if (typeof object.fileSize === "object")
        message.fileSize = new $util.LongBits(
          object.fileSize.low >>> 0,
          object.fileSize.high >>> 0
        ).toNumber(true);
    if (object.usePrevSettings != null)
      message.usePrevSettings = Boolean(object.usePrevSettings);
    if (object.backupUUID != null)
      message.backupUUID = String(object.backupUUID);
    if (object.prevBackupUUID != null)
      message.prevBackupUUID = String(object.prevBackupUUID);
    return message;
  };

  /**
   * Creates a plain object from a BackupSettings message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupSettings
   * @static
   * @param {BackupSettings} message BackupSettings
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupSettings.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.identifier = "";
      object.hash = "";
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.fileSize =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.fileSize = options.longs === String ? "0" : 0;
      object.usePrevSettings = false;
      object.backupUUID = "";
      object.prevBackupUUID = "";
    }
    if (message.identifier != null && message.hasOwnProperty("identifier"))
      object.identifier = message.identifier;
    if (message.hash != null && message.hasOwnProperty("hash"))
      object.hash = message.hash;
    if (message.fileSize != null && message.hasOwnProperty("fileSize"))
      if (typeof message.fileSize === "number")
        object.fileSize =
          options.longs === String
            ? String(message.fileSize)
            : message.fileSize;
      else
        object.fileSize =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.fileSize)
            : options.longs === Number
            ? new $util.LongBits(
                message.fileSize.low >>> 0,
                message.fileSize.high >>> 0
              ).toNumber(true)
            : message.fileSize;
    if (
      message.usePrevSettings != null &&
      message.hasOwnProperty("usePrevSettings")
    )
      object.usePrevSettings = message.usePrevSettings;
    if (message.backupUUID != null && message.hasOwnProperty("backupUUID"))
      object.backupUUID = message.backupUUID;
    if (
      message.prevBackupUUID != null &&
      message.hasOwnProperty("prevBackupUUID")
    )
      object.prevBackupUUID = message.prevBackupUUID;
    return object;
  };

  /**
   * Converts this BackupSettings to JSON.
   * @function toJSON
   * @memberof BackupSettings
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupSettings.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupSettings;
})();

$root.BackupPageListPartition = (function () {
  /**
   * Properties of a BackupPageListPartition.
   * @exports IBackupPageListPartition
   * @interface IBackupPageListPartition
   * @property {Array.<IBackupPageList>|null} [pages] BackupPageListPartition pages
   * @property {string|null} [backupUUID] BackupPageListPartition backupUUID
   * @property {string|null} [prevBackupUUID] BackupPageListPartition prevBackupUUID
   * @property {number|Long|null} [partitionIndex] BackupPageListPartition partitionIndex
   */

  /**
   * Constructs a new BackupPageListPartition.
   * @exports BackupPageListPartition
   * @classdesc Represents a BackupPageListPartition.
   * @implements IBackupPageListPartition
   * @constructor
   * @param {IBackupPageListPartition=} [properties] Properties to set
   */
  function BackupPageListPartition(properties) {
    this.pages = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPageListPartition pages.
   * @member {Array.<IBackupPageList>} pages
   * @memberof BackupPageListPartition
   * @instance
   */
  BackupPageListPartition.prototype.pages = $util.emptyArray;

  /**
   * BackupPageListPartition backupUUID.
   * @member {string} backupUUID
   * @memberof BackupPageListPartition
   * @instance
   */
  BackupPageListPartition.prototype.backupUUID = "";

  /**
   * BackupPageListPartition prevBackupUUID.
   * @member {string} prevBackupUUID
   * @memberof BackupPageListPartition
   * @instance
   */
  BackupPageListPartition.prototype.prevBackupUUID = "";

  /**
   * BackupPageListPartition partitionIndex.
   * @member {number|Long} partitionIndex
   * @memberof BackupPageListPartition
   * @instance
   */
  BackupPageListPartition.prototype.partitionIndex = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * Creates a new BackupPageListPartition instance using the specified properties.
   * @function create
   * @memberof BackupPageListPartition
   * @static
   * @param {IBackupPageListPartition=} [properties] Properties to set
   * @returns {BackupPageListPartition} BackupPageListPartition instance
   */
  BackupPageListPartition.create = function create(properties) {
    return new BackupPageListPartition(properties);
  };

  /**
   * Encodes the specified BackupPageListPartition message. Does not implicitly {@link BackupPageListPartition.verify|verify} messages.
   * @function encode
   * @memberof BackupPageListPartition
   * @static
   * @param {IBackupPageListPartition} message BackupPageListPartition message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageListPartition.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.pages != null && message.pages.length)
      for (var i = 0; i < message.pages.length; ++i)
        $root.BackupPageList.encode(
          message.pages[i],
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim();
    if (
      message.backupUUID != null &&
      Object.hasOwnProperty.call(message, "backupUUID")
    )
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.backupUUID);
    if (
      message.prevBackupUUID != null &&
      Object.hasOwnProperty.call(message, "prevBackupUUID")
    )
      writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.prevBackupUUID);
    if (
      message.partitionIndex != null &&
      Object.hasOwnProperty.call(message, "partitionIndex")
    )
      writer.uint32(/* id 4, wireType 0 =*/ 32).uint64(message.partitionIndex);
    return writer;
  };

  /**
   * Encodes the specified BackupPageListPartition message, length delimited. Does not implicitly {@link BackupPageListPartition.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPageListPartition
   * @static
   * @param {IBackupPageListPartition} message BackupPageListPartition message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPageListPartition.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPageListPartition message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPageListPartition
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPageListPartition} BackupPageListPartition
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageListPartition.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPageListPartition();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (!(message.pages && message.pages.length)) message.pages = [];
          message.pages.push(
            $root.BackupPageList.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.backupUUID = reader.string();
          break;
        case 3:
          message.prevBackupUUID = reader.string();
          break;
        case 4:
          message.partitionIndex = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPageListPartition message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPageListPartition
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPageListPartition} BackupPageListPartition
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPageListPartition.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPageListPartition message.
   * @function verify
   * @memberof BackupPageListPartition
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPageListPartition.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.pages != null && message.hasOwnProperty("pages")) {
      if (!Array.isArray(message.pages)) return "pages: array expected";
      for (var i = 0; i < message.pages.length; ++i) {
        var error = $root.BackupPageList.verify(message.pages[i]);
        if (error) return "pages." + error;
      }
    }
    if (message.backupUUID != null && message.hasOwnProperty("backupUUID"))
      if (!$util.isString(message.backupUUID))
        return "backupUUID: string expected";
    if (
      message.prevBackupUUID != null &&
      message.hasOwnProperty("prevBackupUUID")
    )
      if (!$util.isString(message.prevBackupUUID))
        return "prevBackupUUID: string expected";
    if (
      message.partitionIndex != null &&
      message.hasOwnProperty("partitionIndex")
    )
      if (
        !$util.isInteger(message.partitionIndex) &&
        !(
          message.partitionIndex &&
          $util.isInteger(message.partitionIndex.low) &&
          $util.isInteger(message.partitionIndex.high)
        )
      )
        return "partitionIndex: integer|Long expected";
    return null;
  };

  /**
   * Creates a BackupPageListPartition message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPageListPartition
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPageListPartition} BackupPageListPartition
   */
  BackupPageListPartition.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPageListPartition) return object;
    var message = new $root.BackupPageListPartition();
    if (object.pages) {
      if (!Array.isArray(object.pages))
        throw TypeError(".BackupPageListPartition.pages: array expected");
      message.pages = [];
      for (var i = 0; i < object.pages.length; ++i) {
        if (typeof object.pages[i] !== "object")
          throw TypeError(".BackupPageListPartition.pages: object expected");
        message.pages[i] = $root.BackupPageList.fromObject(object.pages[i]);
      }
    }
    if (object.backupUUID != null)
      message.backupUUID = String(object.backupUUID);
    if (object.prevBackupUUID != null)
      message.prevBackupUUID = String(object.prevBackupUUID);
    if (object.partitionIndex != null)
      if ($util.Long)
        (message.partitionIndex = $util.Long.fromValue(
          object.partitionIndex
        )).unsigned = true;
      else if (typeof object.partitionIndex === "string")
        message.partitionIndex = parseInt(object.partitionIndex, 10);
      else if (typeof object.partitionIndex === "number")
        message.partitionIndex = object.partitionIndex;
      else if (typeof object.partitionIndex === "object")
        message.partitionIndex = new $util.LongBits(
          object.partitionIndex.low >>> 0,
          object.partitionIndex.high >>> 0
        ).toNumber(true);
    return message;
  };

  /**
   * Creates a plain object from a BackupPageListPartition message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPageListPartition
   * @static
   * @param {BackupPageListPartition} message BackupPageListPartition
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPageListPartition.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.pages = [];
    if (options.defaults) {
      object.backupUUID = "";
      object.prevBackupUUID = "";
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.partitionIndex =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.partitionIndex = options.longs === String ? "0" : 0;
    }
    if (message.pages && message.pages.length) {
      object.pages = [];
      for (var j = 0; j < message.pages.length; ++j)
        object.pages[j] = $root.BackupPageList.toObject(
          message.pages[j],
          options
        );
    }
    if (message.backupUUID != null && message.hasOwnProperty("backupUUID"))
      object.backupUUID = message.backupUUID;
    if (
      message.prevBackupUUID != null &&
      message.hasOwnProperty("prevBackupUUID")
    )
      object.prevBackupUUID = message.prevBackupUUID;
    if (
      message.partitionIndex != null &&
      message.hasOwnProperty("partitionIndex")
    )
      if (typeof message.partitionIndex === "number")
        object.partitionIndex =
          options.longs === String
            ? String(message.partitionIndex)
            : message.partitionIndex;
      else
        object.partitionIndex =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.partitionIndex)
            : options.longs === Number
            ? new $util.LongBits(
                message.partitionIndex.low >>> 0,
                message.partitionIndex.high >>> 0
              ).toNumber(true)
            : message.partitionIndex;
    return object;
  };

  /**
   * Converts this BackupPageListPartition to JSON.
   * @function toJSON
   * @memberof BackupPageListPartition
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPageListPartition.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPageListPartition;
})();

$root.BackupDocsPartition = (function () {
  /**
   * Properties of a BackupDocsPartition.
   * @exports IBackupDocsPartition
   * @interface IBackupDocsPartition
   * @property {Array.<IBackupDoc>|null} [docs] BackupDocsPartition docs
   * @property {string|null} [backupUUID] BackupDocsPartition backupUUID
   * @property {string|null} [prevBackupUUID] BackupDocsPartition prevBackupUUID
   * @property {number|Long|null} [partitionIndex] BackupDocsPartition partitionIndex
   */

  /**
   * Constructs a new BackupDocsPartition.
   * @exports BackupDocsPartition
   * @classdesc Represents a BackupDocsPartition.
   * @implements IBackupDocsPartition
   * @constructor
   * @param {IBackupDocsPartition=} [properties] Properties to set
   */
  function BackupDocsPartition(properties) {
    this.docs = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupDocsPartition docs.
   * @member {Array.<IBackupDoc>} docs
   * @memberof BackupDocsPartition
   * @instance
   */
  BackupDocsPartition.prototype.docs = $util.emptyArray;

  /**
   * BackupDocsPartition backupUUID.
   * @member {string} backupUUID
   * @memberof BackupDocsPartition
   * @instance
   */
  BackupDocsPartition.prototype.backupUUID = "";

  /**
   * BackupDocsPartition prevBackupUUID.
   * @member {string} prevBackupUUID
   * @memberof BackupDocsPartition
   * @instance
   */
  BackupDocsPartition.prototype.prevBackupUUID = "";

  /**
   * BackupDocsPartition partitionIndex.
   * @member {number|Long} partitionIndex
   * @memberof BackupDocsPartition
   * @instance
   */
  BackupDocsPartition.prototype.partitionIndex = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * Creates a new BackupDocsPartition instance using the specified properties.
   * @function create
   * @memberof BackupDocsPartition
   * @static
   * @param {IBackupDocsPartition=} [properties] Properties to set
   * @returns {BackupDocsPartition} BackupDocsPartition instance
   */
  BackupDocsPartition.create = function create(properties) {
    return new BackupDocsPartition(properties);
  };

  /**
   * Encodes the specified BackupDocsPartition message. Does not implicitly {@link BackupDocsPartition.verify|verify} messages.
   * @function encode
   * @memberof BackupDocsPartition
   * @static
   * @param {IBackupDocsPartition} message BackupDocsPartition message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupDocsPartition.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.docs != null && message.docs.length)
      for (var i = 0; i < message.docs.length; ++i)
        $root.BackupDoc.encode(
          message.docs[i],
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim();
    if (
      message.backupUUID != null &&
      Object.hasOwnProperty.call(message, "backupUUID")
    )
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.backupUUID);
    if (
      message.prevBackupUUID != null &&
      Object.hasOwnProperty.call(message, "prevBackupUUID")
    )
      writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.prevBackupUUID);
    if (
      message.partitionIndex != null &&
      Object.hasOwnProperty.call(message, "partitionIndex")
    )
      writer.uint32(/* id 4, wireType 0 =*/ 32).uint64(message.partitionIndex);
    return writer;
  };

  /**
   * Encodes the specified BackupDocsPartition message, length delimited. Does not implicitly {@link BackupDocsPartition.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupDocsPartition
   * @static
   * @param {IBackupDocsPartition} message BackupDocsPartition message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupDocsPartition.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupDocsPartition message from the specified reader or buffer.
   * @function decode
   * @memberof BackupDocsPartition
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupDocsPartition} BackupDocsPartition
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupDocsPartition.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupDocsPartition();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (!(message.docs && message.docs.length)) message.docs = [];
          message.docs.push($root.BackupDoc.decode(reader, reader.uint32()));
          break;
        case 2:
          message.backupUUID = reader.string();
          break;
        case 3:
          message.prevBackupUUID = reader.string();
          break;
        case 4:
          message.partitionIndex = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupDocsPartition message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupDocsPartition
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupDocsPartition} BackupDocsPartition
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupDocsPartition.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupDocsPartition message.
   * @function verify
   * @memberof BackupDocsPartition
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupDocsPartition.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.docs != null && message.hasOwnProperty("docs")) {
      if (!Array.isArray(message.docs)) return "docs: array expected";
      for (var i = 0; i < message.docs.length; ++i) {
        var error = $root.BackupDoc.verify(message.docs[i]);
        if (error) return "docs." + error;
      }
    }
    if (message.backupUUID != null && message.hasOwnProperty("backupUUID"))
      if (!$util.isString(message.backupUUID))
        return "backupUUID: string expected";
    if (
      message.prevBackupUUID != null &&
      message.hasOwnProperty("prevBackupUUID")
    )
      if (!$util.isString(message.prevBackupUUID))
        return "prevBackupUUID: string expected";
    if (
      message.partitionIndex != null &&
      message.hasOwnProperty("partitionIndex")
    )
      if (
        !$util.isInteger(message.partitionIndex) &&
        !(
          message.partitionIndex &&
          $util.isInteger(message.partitionIndex.low) &&
          $util.isInteger(message.partitionIndex.high)
        )
      )
        return "partitionIndex: integer|Long expected";
    return null;
  };

  /**
   * Creates a BackupDocsPartition message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupDocsPartition
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupDocsPartition} BackupDocsPartition
   */
  BackupDocsPartition.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupDocsPartition) return object;
    var message = new $root.BackupDocsPartition();
    if (object.docs) {
      if (!Array.isArray(object.docs))
        throw TypeError(".BackupDocsPartition.docs: array expected");
      message.docs = [];
      for (var i = 0; i < object.docs.length; ++i) {
        if (typeof object.docs[i] !== "object")
          throw TypeError(".BackupDocsPartition.docs: object expected");
        message.docs[i] = $root.BackupDoc.fromObject(object.docs[i]);
      }
    }
    if (object.backupUUID != null)
      message.backupUUID = String(object.backupUUID);
    if (object.prevBackupUUID != null)
      message.prevBackupUUID = String(object.prevBackupUUID);
    if (object.partitionIndex != null)
      if ($util.Long)
        (message.partitionIndex = $util.Long.fromValue(
          object.partitionIndex
        )).unsigned = true;
      else if (typeof object.partitionIndex === "string")
        message.partitionIndex = parseInt(object.partitionIndex, 10);
      else if (typeof object.partitionIndex === "number")
        message.partitionIndex = object.partitionIndex;
      else if (typeof object.partitionIndex === "object")
        message.partitionIndex = new $util.LongBits(
          object.partitionIndex.low >>> 0,
          object.partitionIndex.high >>> 0
        ).toNumber(true);
    return message;
  };

  /**
   * Creates a plain object from a BackupDocsPartition message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupDocsPartition
   * @static
   * @param {BackupDocsPartition} message BackupDocsPartition
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupDocsPartition.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.docs = [];
    if (options.defaults) {
      object.backupUUID = "";
      object.prevBackupUUID = "";
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.partitionIndex =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.partitionIndex = options.longs === String ? "0" : 0;
    }
    if (message.docs && message.docs.length) {
      object.docs = [];
      for (var j = 0; j < message.docs.length; ++j)
        object.docs[j] = $root.BackupDoc.toObject(message.docs[j], options);
    }
    if (message.backupUUID != null && message.hasOwnProperty("backupUUID"))
      object.backupUUID = message.backupUUID;
    if (
      message.prevBackupUUID != null &&
      message.hasOwnProperty("prevBackupUUID")
    )
      object.prevBackupUUID = message.prevBackupUUID;
    if (
      message.partitionIndex != null &&
      message.hasOwnProperty("partitionIndex")
    )
      if (typeof message.partitionIndex === "number")
        object.partitionIndex =
          options.longs === String
            ? String(message.partitionIndex)
            : message.partitionIndex;
      else
        object.partitionIndex =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.partitionIndex)
            : options.longs === Number
            ? new $util.LongBits(
                message.partitionIndex.low >>> 0,
                message.partitionIndex.high >>> 0
              ).toNumber(true)
            : message.partitionIndex;
    return object;
  };

  /**
   * Converts this BackupDocsPartition to JSON.
   * @function toJSON
   * @memberof BackupDocsPartition
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupDocsPartition.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupDocsPartition;
})();

$root.BackupFoldersPartition = (function () {
  /**
   * Properties of a BackupFoldersPartition.
   * @exports IBackupFoldersPartition
   * @interface IBackupFoldersPartition
   * @property {Array.<IBackupFolder>|null} [folders] BackupFoldersPartition folders
   * @property {string|null} [backupUUID] BackupFoldersPartition backupUUID
   * @property {string|null} [prevBackupUUID] BackupFoldersPartition prevBackupUUID
   * @property {number|Long|null} [partitionIndex] BackupFoldersPartition partitionIndex
   */

  /**
   * Constructs a new BackupFoldersPartition.
   * @exports BackupFoldersPartition
   * @classdesc Represents a BackupFoldersPartition.
   * @implements IBackupFoldersPartition
   * @constructor
   * @param {IBackupFoldersPartition=} [properties] Properties to set
   */
  function BackupFoldersPartition(properties) {
    this.folders = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupFoldersPartition folders.
   * @member {Array.<IBackupFolder>} folders
   * @memberof BackupFoldersPartition
   * @instance
   */
  BackupFoldersPartition.prototype.folders = $util.emptyArray;

  /**
   * BackupFoldersPartition backupUUID.
   * @member {string} backupUUID
   * @memberof BackupFoldersPartition
   * @instance
   */
  BackupFoldersPartition.prototype.backupUUID = "";

  /**
   * BackupFoldersPartition prevBackupUUID.
   * @member {string} prevBackupUUID
   * @memberof BackupFoldersPartition
   * @instance
   */
  BackupFoldersPartition.prototype.prevBackupUUID = "";

  /**
   * BackupFoldersPartition partitionIndex.
   * @member {number|Long} partitionIndex
   * @memberof BackupFoldersPartition
   * @instance
   */
  BackupFoldersPartition.prototype.partitionIndex = $util.Long
    ? $util.Long.fromBits(0, 0, true)
    : 0;

  /**
   * Creates a new BackupFoldersPartition instance using the specified properties.
   * @function create
   * @memberof BackupFoldersPartition
   * @static
   * @param {IBackupFoldersPartition=} [properties] Properties to set
   * @returns {BackupFoldersPartition} BackupFoldersPartition instance
   */
  BackupFoldersPartition.create = function create(properties) {
    return new BackupFoldersPartition(properties);
  };

  /**
   * Encodes the specified BackupFoldersPartition message. Does not implicitly {@link BackupFoldersPartition.verify|verify} messages.
   * @function encode
   * @memberof BackupFoldersPartition
   * @static
   * @param {IBackupFoldersPartition} message BackupFoldersPartition message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupFoldersPartition.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.folders != null && message.folders.length)
      for (var i = 0; i < message.folders.length; ++i)
        $root.BackupFolder.encode(
          message.folders[i],
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim();
    if (
      message.backupUUID != null &&
      Object.hasOwnProperty.call(message, "backupUUID")
    )
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.backupUUID);
    if (
      message.prevBackupUUID != null &&
      Object.hasOwnProperty.call(message, "prevBackupUUID")
    )
      writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.prevBackupUUID);
    if (
      message.partitionIndex != null &&
      Object.hasOwnProperty.call(message, "partitionIndex")
    )
      writer.uint32(/* id 4, wireType 0 =*/ 32).uint64(message.partitionIndex);
    return writer;
  };

  /**
   * Encodes the specified BackupFoldersPartition message, length delimited. Does not implicitly {@link BackupFoldersPartition.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupFoldersPartition
   * @static
   * @param {IBackupFoldersPartition} message BackupFoldersPartition message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupFoldersPartition.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupFoldersPartition message from the specified reader or buffer.
   * @function decode
   * @memberof BackupFoldersPartition
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupFoldersPartition} BackupFoldersPartition
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupFoldersPartition.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupFoldersPartition();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (!(message.folders && message.folders.length))
            message.folders = [];
          message.folders.push(
            $root.BackupFolder.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.backupUUID = reader.string();
          break;
        case 3:
          message.prevBackupUUID = reader.string();
          break;
        case 4:
          message.partitionIndex = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupFoldersPartition message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupFoldersPartition
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupFoldersPartition} BackupFoldersPartition
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupFoldersPartition.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupFoldersPartition message.
   * @function verify
   * @memberof BackupFoldersPartition
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupFoldersPartition.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.folders != null && message.hasOwnProperty("folders")) {
      if (!Array.isArray(message.folders)) return "folders: array expected";
      for (var i = 0; i < message.folders.length; ++i) {
        var error = $root.BackupFolder.verify(message.folders[i]);
        if (error) return "folders." + error;
      }
    }
    if (message.backupUUID != null && message.hasOwnProperty("backupUUID"))
      if (!$util.isString(message.backupUUID))
        return "backupUUID: string expected";
    if (
      message.prevBackupUUID != null &&
      message.hasOwnProperty("prevBackupUUID")
    )
      if (!$util.isString(message.prevBackupUUID))
        return "prevBackupUUID: string expected";
    if (
      message.partitionIndex != null &&
      message.hasOwnProperty("partitionIndex")
    )
      if (
        !$util.isInteger(message.partitionIndex) &&
        !(
          message.partitionIndex &&
          $util.isInteger(message.partitionIndex.low) &&
          $util.isInteger(message.partitionIndex.high)
        )
      )
        return "partitionIndex: integer|Long expected";
    return null;
  };

  /**
   * Creates a BackupFoldersPartition message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupFoldersPartition
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupFoldersPartition} BackupFoldersPartition
   */
  BackupFoldersPartition.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupFoldersPartition) return object;
    var message = new $root.BackupFoldersPartition();
    if (object.folders) {
      if (!Array.isArray(object.folders))
        throw TypeError(".BackupFoldersPartition.folders: array expected");
      message.folders = [];
      for (var i = 0; i < object.folders.length; ++i) {
        if (typeof object.folders[i] !== "object")
          throw TypeError(".BackupFoldersPartition.folders: object expected");
        message.folders[i] = $root.BackupFolder.fromObject(object.folders[i]);
      }
    }
    if (object.backupUUID != null)
      message.backupUUID = String(object.backupUUID);
    if (object.prevBackupUUID != null)
      message.prevBackupUUID = String(object.prevBackupUUID);
    if (object.partitionIndex != null)
      if ($util.Long)
        (message.partitionIndex = $util.Long.fromValue(
          object.partitionIndex
        )).unsigned = true;
      else if (typeof object.partitionIndex === "string")
        message.partitionIndex = parseInt(object.partitionIndex, 10);
      else if (typeof object.partitionIndex === "number")
        message.partitionIndex = object.partitionIndex;
      else if (typeof object.partitionIndex === "object")
        message.partitionIndex = new $util.LongBits(
          object.partitionIndex.low >>> 0,
          object.partitionIndex.high >>> 0
        ).toNumber(true);
    return message;
  };

  /**
   * Creates a plain object from a BackupFoldersPartition message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupFoldersPartition
   * @static
   * @param {BackupFoldersPartition} message BackupFoldersPartition
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupFoldersPartition.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.folders = [];
    if (options.defaults) {
      object.backupUUID = "";
      object.prevBackupUUID = "";
      if ($util.Long) {
        var long = new $util.Long(0, 0, true);
        object.partitionIndex =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.partitionIndex = options.longs === String ? "0" : 0;
    }
    if (message.folders && message.folders.length) {
      object.folders = [];
      for (var j = 0; j < message.folders.length; ++j)
        object.folders[j] = $root.BackupFolder.toObject(
          message.folders[j],
          options
        );
    }
    if (message.backupUUID != null && message.hasOwnProperty("backupUUID"))
      object.backupUUID = message.backupUUID;
    if (
      message.prevBackupUUID != null &&
      message.hasOwnProperty("prevBackupUUID")
    )
      object.prevBackupUUID = message.prevBackupUUID;
    if (
      message.partitionIndex != null &&
      message.hasOwnProperty("partitionIndex")
    )
      if (typeof message.partitionIndex === "number")
        object.partitionIndex =
          options.longs === String
            ? String(message.partitionIndex)
            : message.partitionIndex;
      else
        object.partitionIndex =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.partitionIndex)
            : options.longs === Number
            ? new $util.LongBits(
                message.partitionIndex.low >>> 0,
                message.partitionIndex.high >>> 0
              ).toNumber(true)
            : message.partitionIndex;
    return object;
  };

  /**
   * Converts this BackupFoldersPartition to JSON.
   * @function toJSON
   * @memberof BackupFoldersPartition
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupFoldersPartition.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupFoldersPartition;
})();

$root.BackupData = (function () {
  /**
   * Properties of a BackupData.
   * @exports IBackupData
   * @interface IBackupData
   * @property {Array.<IBackupFolder>|null} [folders] BackupData folders
   * @property {Array.<IBackupDoc>|null} [docs] BackupData docs
   * @property {IBackupSettings|null} [settings] BackupData settings
   */

  /**
   * Constructs a new BackupData.
   * @exports BackupData
   * @classdesc Represents a BackupData.
   * @implements IBackupData
   * @constructor
   * @param {IBackupData=} [properties] Properties to set
   */
  function BackupData(properties) {
    this.folders = [];
    this.docs = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupData folders.
   * @member {Array.<IBackupFolder>} folders
   * @memberof BackupData
   * @instance
   */
  BackupData.prototype.folders = $util.emptyArray;

  /**
   * BackupData docs.
   * @member {Array.<IBackupDoc>} docs
   * @memberof BackupData
   * @instance
   */
  BackupData.prototype.docs = $util.emptyArray;

  /**
   * BackupData settings.
   * @member {IBackupSettings|null|undefined} settings
   * @memberof BackupData
   * @instance
   */
  BackupData.prototype.settings = null;

  /**
   * Creates a new BackupData instance using the specified properties.
   * @function create
   * @memberof BackupData
   * @static
   * @param {IBackupData=} [properties] Properties to set
   * @returns {BackupData} BackupData instance
   */
  BackupData.create = function create(properties) {
    return new BackupData(properties);
  };

  /**
   * Encodes the specified BackupData message. Does not implicitly {@link BackupData.verify|verify} messages.
   * @function encode
   * @memberof BackupData
   * @static
   * @param {IBackupData} message BackupData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupData.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.folders != null && message.folders.length)
      for (var i = 0; i < message.folders.length; ++i)
        $root.BackupFolder.encode(
          message.folders[i],
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim();
    if (message.docs != null && message.docs.length)
      for (var i = 0; i < message.docs.length; ++i)
        $root.BackupDoc.encode(
          message.docs[i],
          writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
        ).ldelim();
    if (
      message.settings != null &&
      Object.hasOwnProperty.call(message, "settings")
    )
      $root.BackupSettings.encode(
        message.settings,
        writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
      ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified BackupData message, length delimited. Does not implicitly {@link BackupData.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupData
   * @static
   * @param {IBackupData} message BackupData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupData.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupData message from the specified reader or buffer.
   * @function decode
   * @memberof BackupData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupData} BackupData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupData.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupData();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (!(message.folders && message.folders.length))
            message.folders = [];
          message.folders.push(
            $root.BackupFolder.decode(reader, reader.uint32())
          );
          break;
        case 2:
          if (!(message.docs && message.docs.length)) message.docs = [];
          message.docs.push($root.BackupDoc.decode(reader, reader.uint32()));
          break;
        case 3:
          message.settings = $root.BackupSettings.decode(
            reader,
            reader.uint32()
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupData message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupData} BackupData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupData.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupData message.
   * @function verify
   * @memberof BackupData
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupData.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.folders != null && message.hasOwnProperty("folders")) {
      if (!Array.isArray(message.folders)) return "folders: array expected";
      for (var i = 0; i < message.folders.length; ++i) {
        var error = $root.BackupFolder.verify(message.folders[i]);
        if (error) return "folders." + error;
      }
    }
    if (message.docs != null && message.hasOwnProperty("docs")) {
      if (!Array.isArray(message.docs)) return "docs: array expected";
      for (var i = 0; i < message.docs.length; ++i) {
        var error = $root.BackupDoc.verify(message.docs[i]);
        if (error) return "docs." + error;
      }
    }
    if (message.settings != null && message.hasOwnProperty("settings")) {
      var error = $root.BackupSettings.verify(message.settings);
      if (error) return "settings." + error;
    }
    return null;
  };

  /**
   * Creates a BackupData message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupData
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupData} BackupData
   */
  BackupData.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupData) return object;
    var message = new $root.BackupData();
    if (object.folders) {
      if (!Array.isArray(object.folders))
        throw TypeError(".BackupData.folders: array expected");
      message.folders = [];
      for (var i = 0; i < object.folders.length; ++i) {
        if (typeof object.folders[i] !== "object")
          throw TypeError(".BackupData.folders: object expected");
        message.folders[i] = $root.BackupFolder.fromObject(object.folders[i]);
      }
    }
    if (object.docs) {
      if (!Array.isArray(object.docs))
        throw TypeError(".BackupData.docs: array expected");
      message.docs = [];
      for (var i = 0; i < object.docs.length; ++i) {
        if (typeof object.docs[i] !== "object")
          throw TypeError(".BackupData.docs: object expected");
        message.docs[i] = $root.BackupDoc.fromObject(object.docs[i]);
      }
    }
    if (object.settings != null) {
      if (typeof object.settings !== "object")
        throw TypeError(".BackupData.settings: object expected");
      message.settings = $root.BackupSettings.fromObject(object.settings);
    }
    return message;
  };

  /**
   * Creates a plain object from a BackupData message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupData
   * @static
   * @param {BackupData} message BackupData
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupData.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) {
      object.folders = [];
      object.docs = [];
    }
    if (options.defaults) object.settings = null;
    if (message.folders && message.folders.length) {
      object.folders = [];
      for (var j = 0; j < message.folders.length; ++j)
        object.folders[j] = $root.BackupFolder.toObject(
          message.folders[j],
          options
        );
    }
    if (message.docs && message.docs.length) {
      object.docs = [];
      for (var j = 0; j < message.docs.length; ++j)
        object.docs[j] = $root.BackupDoc.toObject(message.docs[j], options);
    }
    if (message.settings != null && message.hasOwnProperty("settings"))
      object.settings = $root.BackupSettings.toObject(
        message.settings,
        options
      );
    return object;
  };

  /**
   * Converts this BackupData to JSON.
   * @function toJSON
   * @memberof BackupData
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupData.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupData;
})();

$root.BackupPoint = (function () {
  /**
   * Properties of a BackupPoint.
   * @exports IBackupPoint
   * @interface IBackupPoint
   * @property {number|null} [x] BackupPoint x
   * @property {number|null} [y] BackupPoint y
   */

  /**
   * Constructs a new BackupPoint.
   * @exports BackupPoint
   * @classdesc Represents a BackupPoint.
   * @implements IBackupPoint
   * @constructor
   * @param {IBackupPoint=} [properties] Properties to set
   */
  function BackupPoint(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupPoint x.
   * @member {number} x
   * @memberof BackupPoint
   * @instance
   */
  BackupPoint.prototype.x = 0;

  /**
   * BackupPoint y.
   * @member {number} y
   * @memberof BackupPoint
   * @instance
   */
  BackupPoint.prototype.y = 0;

  /**
   * Creates a new BackupPoint instance using the specified properties.
   * @function create
   * @memberof BackupPoint
   * @static
   * @param {IBackupPoint=} [properties] Properties to set
   * @returns {BackupPoint} BackupPoint instance
   */
  BackupPoint.create = function create(properties) {
    return new BackupPoint(properties);
  };

  /**
   * Encodes the specified BackupPoint message. Does not implicitly {@link BackupPoint.verify|verify} messages.
   * @function encode
   * @memberof BackupPoint
   * @static
   * @param {IBackupPoint} message BackupPoint message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPoint.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.x != null && Object.hasOwnProperty.call(message, "x"))
      writer.uint32(/* id 1, wireType 1 =*/ 9).double(message.x);
    if (message.y != null && Object.hasOwnProperty.call(message, "y"))
      writer.uint32(/* id 2, wireType 1 =*/ 17).double(message.y);
    return writer;
  };

  /**
   * Encodes the specified BackupPoint message, length delimited. Does not implicitly {@link BackupPoint.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupPoint
   * @static
   * @param {IBackupPoint} message BackupPoint message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupPoint.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupPoint message from the specified reader or buffer.
   * @function decode
   * @memberof BackupPoint
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupPoint} BackupPoint
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPoint.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupPoint();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.x = reader.double();
          break;
        case 2:
          message.y = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupPoint message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupPoint
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupPoint} BackupPoint
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupPoint.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupPoint message.
   * @function verify
   * @memberof BackupPoint
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupPoint.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.x != null && message.hasOwnProperty("x"))
      if (typeof message.x !== "number") return "x: number expected";
    if (message.y != null && message.hasOwnProperty("y"))
      if (typeof message.y !== "number") return "y: number expected";
    return null;
  };

  /**
   * Creates a BackupPoint message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupPoint
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupPoint} BackupPoint
   */
  BackupPoint.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupPoint) return object;
    var message = new $root.BackupPoint();
    if (object.x != null) message.x = Number(object.x);
    if (object.y != null) message.y = Number(object.y);
    return message;
  };

  /**
   * Creates a plain object from a BackupPoint message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupPoint
   * @static
   * @param {BackupPoint} message BackupPoint
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupPoint.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.x = 0;
      object.y = 0;
    }
    if (message.x != null && message.hasOwnProperty("x"))
      object.x =
        options.json && !isFinite(message.x) ? String(message.x) : message.x;
    if (message.y != null && message.hasOwnProperty("y"))
      object.y =
        options.json && !isFinite(message.y) ? String(message.y) : message.y;
    return object;
  };

  /**
   * Converts this BackupPoint to JSON.
   * @function toJSON
   * @memberof BackupPoint
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupPoint.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupPoint;
})();

$root.BackupSignaturePathlineData = (function () {
  /**
   * Properties of a BackupSignaturePathlineData.
   * @exports IBackupSignaturePathlineData
   * @interface IBackupSignaturePathlineData
   * @property {Array.<IBackupPoint>|null} [points] BackupSignaturePathlineData points
   */

  /**
   * Constructs a new BackupSignaturePathlineData.
   * @exports BackupSignaturePathlineData
   * @classdesc Represents a BackupSignaturePathlineData.
   * @implements IBackupSignaturePathlineData
   * @constructor
   * @param {IBackupSignaturePathlineData=} [properties] Properties to set
   */
  function BackupSignaturePathlineData(properties) {
    this.points = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupSignaturePathlineData points.
   * @member {Array.<IBackupPoint>} points
   * @memberof BackupSignaturePathlineData
   * @instance
   */
  BackupSignaturePathlineData.prototype.points = $util.emptyArray;

  /**
   * Creates a new BackupSignaturePathlineData instance using the specified properties.
   * @function create
   * @memberof BackupSignaturePathlineData
   * @static
   * @param {IBackupSignaturePathlineData=} [properties] Properties to set
   * @returns {BackupSignaturePathlineData} BackupSignaturePathlineData instance
   */
  BackupSignaturePathlineData.create = function create(properties) {
    return new BackupSignaturePathlineData(properties);
  };

  /**
   * Encodes the specified BackupSignaturePathlineData message. Does not implicitly {@link BackupSignaturePathlineData.verify|verify} messages.
   * @function encode
   * @memberof BackupSignaturePathlineData
   * @static
   * @param {IBackupSignaturePathlineData} message BackupSignaturePathlineData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupSignaturePathlineData.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.points != null && message.points.length)
      for (var i = 0; i < message.points.length; ++i)
        $root.BackupPoint.encode(
          message.points[i],
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified BackupSignaturePathlineData message, length delimited. Does not implicitly {@link BackupSignaturePathlineData.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupSignaturePathlineData
   * @static
   * @param {IBackupSignaturePathlineData} message BackupSignaturePathlineData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupSignaturePathlineData.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupSignaturePathlineData message from the specified reader or buffer.
   * @function decode
   * @memberof BackupSignaturePathlineData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupSignaturePathlineData} BackupSignaturePathlineData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupSignaturePathlineData.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupSignaturePathlineData();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (!(message.points && message.points.length)) message.points = [];
          message.points.push(
            $root.BackupPoint.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupSignaturePathlineData message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupSignaturePathlineData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupSignaturePathlineData} BackupSignaturePathlineData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupSignaturePathlineData.decodeDelimited = function decodeDelimited(
    reader
  ) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupSignaturePathlineData message.
   * @function verify
   * @memberof BackupSignaturePathlineData
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupSignaturePathlineData.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.points != null && message.hasOwnProperty("points")) {
      if (!Array.isArray(message.points)) return "points: array expected";
      for (var i = 0; i < message.points.length; ++i) {
        var error = $root.BackupPoint.verify(message.points[i]);
        if (error) return "points." + error;
      }
    }
    return null;
  };

  /**
   * Creates a BackupSignaturePathlineData message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupSignaturePathlineData
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupSignaturePathlineData} BackupSignaturePathlineData
   */
  BackupSignaturePathlineData.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupSignaturePathlineData) return object;
    var message = new $root.BackupSignaturePathlineData();
    if (object.points) {
      if (!Array.isArray(object.points))
        throw TypeError(".BackupSignaturePathlineData.points: array expected");
      message.points = [];
      for (var i = 0; i < object.points.length; ++i) {
        if (typeof object.points[i] !== "object")
          throw TypeError(
            ".BackupSignaturePathlineData.points: object expected"
          );
        message.points[i] = $root.BackupPoint.fromObject(object.points[i]);
      }
    }
    return message;
  };

  /**
   * Creates a plain object from a BackupSignaturePathlineData message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupSignaturePathlineData
   * @static
   * @param {BackupSignaturePathlineData} message BackupSignaturePathlineData
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupSignaturePathlineData.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.points = [];
    if (message.points && message.points.length) {
      object.points = [];
      for (var j = 0; j < message.points.length; ++j)
        object.points[j] = $root.BackupPoint.toObject(
          message.points[j],
          options
        );
    }
    return object;
  };

  /**
   * Converts this BackupSignaturePathlineData to JSON.
   * @function toJSON
   * @memberof BackupSignaturePathlineData
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupSignaturePathlineData.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupSignaturePathlineData;
})();

$root.BackupSignatureData = (function () {
  /**
   * Properties of a BackupSignatureData.
   * @exports IBackupSignatureData
   * @interface IBackupSignatureData
   * @property {IBackupPoint|null} [origin] BackupSignatureData origin
   * @property {Array.<number>|null} [size] BackupSignatureData size
   * @property {number|null} [scale] BackupSignatureData scale
   * @property {Array.<number>|null} [strokeColorRGBA] BackupSignatureData strokeColorRGBA
   * @property {number|null} [strokeWidth] BackupSignatureData strokeWidth
   * @property {Array.<IBackupSignaturePathlineData>|null} [pathLineDataArray] BackupSignatureData pathLineDataArray
   */

  /**
   * Constructs a new BackupSignatureData.
   * @exports BackupSignatureData
   * @classdesc Represents a BackupSignatureData.
   * @implements IBackupSignatureData
   * @constructor
   * @param {IBackupSignatureData=} [properties] Properties to set
   */
  function BackupSignatureData(properties) {
    this.size = [];
    this.strokeColorRGBA = [];
    this.pathLineDataArray = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupSignatureData origin.
   * @member {IBackupPoint|null|undefined} origin
   * @memberof BackupSignatureData
   * @instance
   */
  BackupSignatureData.prototype.origin = null;

  /**
   * BackupSignatureData size.
   * @member {Array.<number>} size
   * @memberof BackupSignatureData
   * @instance
   */
  BackupSignatureData.prototype.size = $util.emptyArray;

  /**
   * BackupSignatureData scale.
   * @member {number} scale
   * @memberof BackupSignatureData
   * @instance
   */
  BackupSignatureData.prototype.scale = 0;

  /**
   * BackupSignatureData strokeColorRGBA.
   * @member {Array.<number>} strokeColorRGBA
   * @memberof BackupSignatureData
   * @instance
   */
  BackupSignatureData.prototype.strokeColorRGBA = $util.emptyArray;

  /**
   * BackupSignatureData strokeWidth.
   * @member {number} strokeWidth
   * @memberof BackupSignatureData
   * @instance
   */
  BackupSignatureData.prototype.strokeWidth = 0;

  /**
   * BackupSignatureData pathLineDataArray.
   * @member {Array.<IBackupSignaturePathlineData>} pathLineDataArray
   * @memberof BackupSignatureData
   * @instance
   */
  BackupSignatureData.prototype.pathLineDataArray = $util.emptyArray;

  /**
   * Creates a new BackupSignatureData instance using the specified properties.
   * @function create
   * @memberof BackupSignatureData
   * @static
   * @param {IBackupSignatureData=} [properties] Properties to set
   * @returns {BackupSignatureData} BackupSignatureData instance
   */
  BackupSignatureData.create = function create(properties) {
    return new BackupSignatureData(properties);
  };

  /**
   * Encodes the specified BackupSignatureData message. Does not implicitly {@link BackupSignatureData.verify|verify} messages.
   * @function encode
   * @memberof BackupSignatureData
   * @static
   * @param {IBackupSignatureData} message BackupSignatureData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupSignatureData.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.origin != null && Object.hasOwnProperty.call(message, "origin"))
      $root.BackupPoint.encode(
        message.origin,
        writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
      ).ldelim();
    if (message.size != null && message.size.length) {
      writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
      for (var i = 0; i < message.size.length; ++i)
        writer.double(message.size[i]);
      writer.ldelim();
    }
    if (message.scale != null && Object.hasOwnProperty.call(message, "scale"))
      writer.uint32(/* id 3, wireType 1 =*/ 25).double(message.scale);
    if (message.strokeColorRGBA != null && message.strokeColorRGBA.length) {
      writer.uint32(/* id 4, wireType 2 =*/ 34).fork();
      for (var i = 0; i < message.strokeColorRGBA.length; ++i)
        writer.double(message.strokeColorRGBA[i]);
      writer.ldelim();
    }
    if (
      message.strokeWidth != null &&
      Object.hasOwnProperty.call(message, "strokeWidth")
    )
      writer.uint32(/* id 5, wireType 1 =*/ 41).double(message.strokeWidth);
    if (message.pathLineDataArray != null && message.pathLineDataArray.length)
      for (var i = 0; i < message.pathLineDataArray.length; ++i)
        $root.BackupSignaturePathlineData.encode(
          message.pathLineDataArray[i],
          writer.uint32(/* id 6, wireType 2 =*/ 50).fork()
        ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified BackupSignatureData message, length delimited. Does not implicitly {@link BackupSignatureData.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupSignatureData
   * @static
   * @param {IBackupSignatureData} message BackupSignatureData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupSignatureData.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupSignatureData message from the specified reader or buffer.
   * @function decode
   * @memberof BackupSignatureData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupSignatureData} BackupSignatureData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupSignatureData.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupSignatureData();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.origin = $root.BackupPoint.decode(reader, reader.uint32());
          break;
        case 2:
          if (!(message.size && message.size.length)) message.size = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) message.size.push(reader.double());
          } else message.size.push(reader.double());
          break;
        case 3:
          message.scale = reader.double();
          break;
        case 4:
          if (!(message.strokeColorRGBA && message.strokeColorRGBA.length))
            message.strokeColorRGBA = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2)
              message.strokeColorRGBA.push(reader.double());
          } else message.strokeColorRGBA.push(reader.double());
          break;
        case 5:
          message.strokeWidth = reader.double();
          break;
        case 6:
          if (!(message.pathLineDataArray && message.pathLineDataArray.length))
            message.pathLineDataArray = [];
          message.pathLineDataArray.push(
            $root.BackupSignaturePathlineData.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupSignatureData message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupSignatureData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupSignatureData} BackupSignatureData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupSignatureData.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupSignatureData message.
   * @function verify
   * @memberof BackupSignatureData
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupSignatureData.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.origin != null && message.hasOwnProperty("origin")) {
      var error = $root.BackupPoint.verify(message.origin);
      if (error) return "origin." + error;
    }
    if (message.size != null && message.hasOwnProperty("size")) {
      if (!Array.isArray(message.size)) return "size: array expected";
      for (var i = 0; i < message.size.length; ++i)
        if (typeof message.size[i] !== "number")
          return "size: number[] expected";
    }
    if (message.scale != null && message.hasOwnProperty("scale"))
      if (typeof message.scale !== "number") return "scale: number expected";
    if (
      message.strokeColorRGBA != null &&
      message.hasOwnProperty("strokeColorRGBA")
    ) {
      if (!Array.isArray(message.strokeColorRGBA))
        return "strokeColorRGBA: array expected";
      for (var i = 0; i < message.strokeColorRGBA.length; ++i)
        if (typeof message.strokeColorRGBA[i] !== "number")
          return "strokeColorRGBA: number[] expected";
    }
    if (message.strokeWidth != null && message.hasOwnProperty("strokeWidth"))
      if (typeof message.strokeWidth !== "number")
        return "strokeWidth: number expected";
    if (
      message.pathLineDataArray != null &&
      message.hasOwnProperty("pathLineDataArray")
    ) {
      if (!Array.isArray(message.pathLineDataArray))
        return "pathLineDataArray: array expected";
      for (var i = 0; i < message.pathLineDataArray.length; ++i) {
        var error = $root.BackupSignaturePathlineData.verify(
          message.pathLineDataArray[i]
        );
        if (error) return "pathLineDataArray." + error;
      }
    }
    return null;
  };

  /**
   * Creates a BackupSignatureData message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupSignatureData
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupSignatureData} BackupSignatureData
   */
  BackupSignatureData.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupSignatureData) return object;
    var message = new $root.BackupSignatureData();
    if (object.origin != null) {
      if (typeof object.origin !== "object")
        throw TypeError(".BackupSignatureData.origin: object expected");
      message.origin = $root.BackupPoint.fromObject(object.origin);
    }
    if (object.size) {
      if (!Array.isArray(object.size))
        throw TypeError(".BackupSignatureData.size: array expected");
      message.size = [];
      for (var i = 0; i < object.size.length; ++i)
        message.size[i] = Number(object.size[i]);
    }
    if (object.scale != null) message.scale = Number(object.scale);
    if (object.strokeColorRGBA) {
      if (!Array.isArray(object.strokeColorRGBA))
        throw TypeError(".BackupSignatureData.strokeColorRGBA: array expected");
      message.strokeColorRGBA = [];
      for (var i = 0; i < object.strokeColorRGBA.length; ++i)
        message.strokeColorRGBA[i] = Number(object.strokeColorRGBA[i]);
    }
    if (object.strokeWidth != null)
      message.strokeWidth = Number(object.strokeWidth);
    if (object.pathLineDataArray) {
      if (!Array.isArray(object.pathLineDataArray))
        throw TypeError(
          ".BackupSignatureData.pathLineDataArray: array expected"
        );
      message.pathLineDataArray = [];
      for (var i = 0; i < object.pathLineDataArray.length; ++i) {
        if (typeof object.pathLineDataArray[i] !== "object")
          throw TypeError(
            ".BackupSignatureData.pathLineDataArray: object expected"
          );
        message.pathLineDataArray[i] =
          $root.BackupSignaturePathlineData.fromObject(
            object.pathLineDataArray[i]
          );
      }
    }
    return message;
  };

  /**
   * Creates a plain object from a BackupSignatureData message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupSignatureData
   * @static
   * @param {BackupSignatureData} message BackupSignatureData
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupSignatureData.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) {
      object.size = [];
      object.strokeColorRGBA = [];
      object.pathLineDataArray = [];
    }
    if (options.defaults) {
      object.origin = null;
      object.scale = 0;
      object.strokeWidth = 0;
    }
    if (message.origin != null && message.hasOwnProperty("origin"))
      object.origin = $root.BackupPoint.toObject(message.origin, options);
    if (message.size && message.size.length) {
      object.size = [];
      for (var j = 0; j < message.size.length; ++j)
        object.size[j] =
          options.json && !isFinite(message.size[j])
            ? String(message.size[j])
            : message.size[j];
    }
    if (message.scale != null && message.hasOwnProperty("scale"))
      object.scale =
        options.json && !isFinite(message.scale)
          ? String(message.scale)
          : message.scale;
    if (message.strokeColorRGBA && message.strokeColorRGBA.length) {
      object.strokeColorRGBA = [];
      for (var j = 0; j < message.strokeColorRGBA.length; ++j)
        object.strokeColorRGBA[j] =
          options.json && !isFinite(message.strokeColorRGBA[j])
            ? String(message.strokeColorRGBA[j])
            : message.strokeColorRGBA[j];
    }
    if (message.strokeWidth != null && message.hasOwnProperty("strokeWidth"))
      object.strokeWidth =
        options.json && !isFinite(message.strokeWidth)
          ? String(message.strokeWidth)
          : message.strokeWidth;
    if (message.pathLineDataArray && message.pathLineDataArray.length) {
      object.pathLineDataArray = [];
      for (var j = 0; j < message.pathLineDataArray.length; ++j)
        object.pathLineDataArray[j] =
          $root.BackupSignaturePathlineData.toObject(
            message.pathLineDataArray[j],
            options
          );
    }
    return object;
  };

  /**
   * Converts this BackupSignatureData to JSON.
   * @function toJSON
   * @memberof BackupSignatureData
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupSignatureData.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupSignatureData;
})();

$root.BackupTextData = (function () {
  /**
   * Properties of a BackupTextData.
   * @exports IBackupTextData
   * @interface IBackupTextData
   * @property {string|null} [text] BackupTextData text
   * @property {number|null} [rotate] BackupTextData rotate
   * @property {IBackupPoint|null} [center] BackupTextData center
   * @property {Array.<number>|null} [backgroundColorRGBA] BackupTextData backgroundColorRGBA
   * @property {Array.<number>|null} [textColorRGBA] BackupTextData textColorRGBA
   * @property {number|null} [textAlignment] BackupTextData textAlignment
   * @property {number|null} [scale] BackupTextData scale
   * @property {number|null} [fontSize] BackupTextData fontSize
   * @property {Uint8Array|null} [fontDescriptorData] BackupTextData fontDescriptorData
   * @property {string|null} [fontString] BackupTextData fontString
   */

  /**
   * Constructs a new BackupTextData.
   * @exports BackupTextData
   * @classdesc Represents a BackupTextData.
   * @implements IBackupTextData
   * @constructor
   * @param {IBackupTextData=} [properties] Properties to set
   */
  function BackupTextData(properties) {
    this.backgroundColorRGBA = [];
    this.textColorRGBA = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupTextData text.
   * @member {string} text
   * @memberof BackupTextData
   * @instance
   */
  BackupTextData.prototype.text = "";

  /**
   * BackupTextData rotate.
   * @member {number} rotate
   * @memberof BackupTextData
   * @instance
   */
  BackupTextData.prototype.rotate = 0;

  /**
   * BackupTextData center.
   * @member {IBackupPoint|null|undefined} center
   * @memberof BackupTextData
   * @instance
   */
  BackupTextData.prototype.center = null;

  /**
   * BackupTextData backgroundColorRGBA.
   * @member {Array.<number>} backgroundColorRGBA
   * @memberof BackupTextData
   * @instance
   */
  BackupTextData.prototype.backgroundColorRGBA = $util.emptyArray;

  /**
   * BackupTextData textColorRGBA.
   * @member {Array.<number>} textColorRGBA
   * @memberof BackupTextData
   * @instance
   */
  BackupTextData.prototype.textColorRGBA = $util.emptyArray;

  /**
   * BackupTextData textAlignment.
   * @member {number} textAlignment
   * @memberof BackupTextData
   * @instance
   */
  BackupTextData.prototype.textAlignment = 0;

  /**
   * BackupTextData scale.
   * @member {number} scale
   * @memberof BackupTextData
   * @instance
   */
  BackupTextData.prototype.scale = 0;

  /**
   * BackupTextData fontSize.
   * @member {number} fontSize
   * @memberof BackupTextData
   * @instance
   */
  BackupTextData.prototype.fontSize = 0;

  /**
   * BackupTextData fontDescriptorData.
   * @member {Uint8Array} fontDescriptorData
   * @memberof BackupTextData
   * @instance
   */
  BackupTextData.prototype.fontDescriptorData = $util.newBuffer([]);

  /**
   * BackupTextData fontString.
   * @member {string} fontString
   * @memberof BackupTextData
   * @instance
   */
  BackupTextData.prototype.fontString = "";

  /**
   * Creates a new BackupTextData instance using the specified properties.
   * @function create
   * @memberof BackupTextData
   * @static
   * @param {IBackupTextData=} [properties] Properties to set
   * @returns {BackupTextData} BackupTextData instance
   */
  BackupTextData.create = function create(properties) {
    return new BackupTextData(properties);
  };

  /**
   * Encodes the specified BackupTextData message. Does not implicitly {@link BackupTextData.verify|verify} messages.
   * @function encode
   * @memberof BackupTextData
   * @static
   * @param {IBackupTextData} message BackupTextData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupTextData.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.text);
    if (message.rotate != null && Object.hasOwnProperty.call(message, "rotate"))
      writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.rotate);
    if (message.center != null && Object.hasOwnProperty.call(message, "center"))
      $root.BackupPoint.encode(
        message.center,
        writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
      ).ldelim();
    if (
      message.backgroundColorRGBA != null &&
      message.backgroundColorRGBA.length
    ) {
      writer.uint32(/* id 4, wireType 2 =*/ 34).fork();
      for (var i = 0; i < message.backgroundColorRGBA.length; ++i)
        writer.double(message.backgroundColorRGBA[i]);
      writer.ldelim();
    }
    if (message.textColorRGBA != null && message.textColorRGBA.length) {
      writer.uint32(/* id 5, wireType 2 =*/ 42).fork();
      for (var i = 0; i < message.textColorRGBA.length; ++i)
        writer.double(message.textColorRGBA[i]);
      writer.ldelim();
    }
    if (
      message.textAlignment != null &&
      Object.hasOwnProperty.call(message, "textAlignment")
    )
      writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.textAlignment);
    if (message.scale != null && Object.hasOwnProperty.call(message, "scale"))
      writer.uint32(/* id 7, wireType 1 =*/ 57).double(message.scale);
    if (
      message.fontSize != null &&
      Object.hasOwnProperty.call(message, "fontSize")
    )
      writer.uint32(/* id 8, wireType 1 =*/ 65).double(message.fontSize);
    if (
      message.fontDescriptorData != null &&
      Object.hasOwnProperty.call(message, "fontDescriptorData")
    )
      writer
        .uint32(/* id 9, wireType 2 =*/ 74)
        .bytes(message.fontDescriptorData);
    if (
      message.fontString != null &&
      Object.hasOwnProperty.call(message, "fontString")
    )
      writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.fontString);
    return writer;
  };

  /**
   * Encodes the specified BackupTextData message, length delimited. Does not implicitly {@link BackupTextData.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupTextData
   * @static
   * @param {IBackupTextData} message BackupTextData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupTextData.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupTextData message from the specified reader or buffer.
   * @function decode
   * @memberof BackupTextData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupTextData} BackupTextData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupTextData.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupTextData();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.text = reader.string();
          break;
        case 2:
          message.rotate = reader.int32();
          break;
        case 3:
          message.center = $root.BackupPoint.decode(reader, reader.uint32());
          break;
        case 4:
          if (
            !(message.backgroundColorRGBA && message.backgroundColorRGBA.length)
          )
            message.backgroundColorRGBA = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2)
              message.backgroundColorRGBA.push(reader.double());
          } else message.backgroundColorRGBA.push(reader.double());
          break;
        case 5:
          if (!(message.textColorRGBA && message.textColorRGBA.length))
            message.textColorRGBA = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2)
              message.textColorRGBA.push(reader.double());
          } else message.textColorRGBA.push(reader.double());
          break;
        case 6:
          message.textAlignment = reader.int32();
          break;
        case 7:
          message.scale = reader.double();
          break;
        case 8:
          message.fontSize = reader.double();
          break;
        case 9:
          message.fontDescriptorData = reader.bytes();
          break;
        case 10:
          message.fontString = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupTextData message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupTextData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupTextData} BackupTextData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupTextData.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupTextData message.
   * @function verify
   * @memberof BackupTextData
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupTextData.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.text != null && message.hasOwnProperty("text"))
      if (!$util.isString(message.text)) return "text: string expected";
    if (message.rotate != null && message.hasOwnProperty("rotate"))
      if (!$util.isInteger(message.rotate)) return "rotate: integer expected";
    if (message.center != null && message.hasOwnProperty("center")) {
      var error = $root.BackupPoint.verify(message.center);
      if (error) return "center." + error;
    }
    if (
      message.backgroundColorRGBA != null &&
      message.hasOwnProperty("backgroundColorRGBA")
    ) {
      if (!Array.isArray(message.backgroundColorRGBA))
        return "backgroundColorRGBA: array expected";
      for (var i = 0; i < message.backgroundColorRGBA.length; ++i)
        if (typeof message.backgroundColorRGBA[i] !== "number")
          return "backgroundColorRGBA: number[] expected";
    }
    if (
      message.textColorRGBA != null &&
      message.hasOwnProperty("textColorRGBA")
    ) {
      if (!Array.isArray(message.textColorRGBA))
        return "textColorRGBA: array expected";
      for (var i = 0; i < message.textColorRGBA.length; ++i)
        if (typeof message.textColorRGBA[i] !== "number")
          return "textColorRGBA: number[] expected";
    }
    if (
      message.textAlignment != null &&
      message.hasOwnProperty("textAlignment")
    )
      if (!$util.isInteger(message.textAlignment))
        return "textAlignment: integer expected";
    if (message.scale != null && message.hasOwnProperty("scale"))
      if (typeof message.scale !== "number") return "scale: number expected";
    if (message.fontSize != null && message.hasOwnProperty("fontSize"))
      if (typeof message.fontSize !== "number")
        return "fontSize: number expected";
    if (
      message.fontDescriptorData != null &&
      message.hasOwnProperty("fontDescriptorData")
    )
      if (
        !(
          (message.fontDescriptorData &&
            typeof message.fontDescriptorData.length === "number") ||
          $util.isString(message.fontDescriptorData)
        )
      )
        return "fontDescriptorData: buffer expected";
    if (message.fontString != null && message.hasOwnProperty("fontString"))
      if (!$util.isString(message.fontString))
        return "fontString: string expected";
    return null;
  };

  /**
   * Creates a BackupTextData message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupTextData
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupTextData} BackupTextData
   */
  BackupTextData.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupTextData) return object;
    var message = new $root.BackupTextData();
    if (object.text != null) message.text = String(object.text);
    if (object.rotate != null) message.rotate = object.rotate | 0;
    if (object.center != null) {
      if (typeof object.center !== "object")
        throw TypeError(".BackupTextData.center: object expected");
      message.center = $root.BackupPoint.fromObject(object.center);
    }
    if (object.backgroundColorRGBA) {
      if (!Array.isArray(object.backgroundColorRGBA))
        throw TypeError(".BackupTextData.backgroundColorRGBA: array expected");
      message.backgroundColorRGBA = [];
      for (var i = 0; i < object.backgroundColorRGBA.length; ++i)
        message.backgroundColorRGBA[i] = Number(object.backgroundColorRGBA[i]);
    }
    if (object.textColorRGBA) {
      if (!Array.isArray(object.textColorRGBA))
        throw TypeError(".BackupTextData.textColorRGBA: array expected");
      message.textColorRGBA = [];
      for (var i = 0; i < object.textColorRGBA.length; ++i)
        message.textColorRGBA[i] = Number(object.textColorRGBA[i]);
    }
    if (object.textAlignment != null)
      message.textAlignment = object.textAlignment | 0;
    if (object.scale != null) message.scale = Number(object.scale);
    if (object.fontSize != null) message.fontSize = Number(object.fontSize);
    if (object.fontDescriptorData != null)
      if (typeof object.fontDescriptorData === "string")
        $util.base64.decode(
          object.fontDescriptorData,
          (message.fontDescriptorData = $util.newBuffer(
            $util.base64.length(object.fontDescriptorData)
          )),
          0
        );
      else if (object.fontDescriptorData.length)
        message.fontDescriptorData = object.fontDescriptorData;
    if (object.fontString != null)
      message.fontString = String(object.fontString);
    return message;
  };

  /**
   * Creates a plain object from a BackupTextData message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupTextData
   * @static
   * @param {BackupTextData} message BackupTextData
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupTextData.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) {
      object.backgroundColorRGBA = [];
      object.textColorRGBA = [];
    }
    if (options.defaults) {
      object.text = "";
      object.rotate = 0;
      object.center = null;
      object.textAlignment = 0;
      object.scale = 0;
      object.fontSize = 0;
      if (options.bytes === String) object.fontDescriptorData = "";
      else {
        object.fontDescriptorData = [];
        if (options.bytes !== Array)
          object.fontDescriptorData = $util.newBuffer(
            object.fontDescriptorData
          );
      }
      object.fontString = "";
    }
    if (message.text != null && message.hasOwnProperty("text"))
      object.text = message.text;
    if (message.rotate != null && message.hasOwnProperty("rotate"))
      object.rotate = message.rotate;
    if (message.center != null && message.hasOwnProperty("center"))
      object.center = $root.BackupPoint.toObject(message.center, options);
    if (message.backgroundColorRGBA && message.backgroundColorRGBA.length) {
      object.backgroundColorRGBA = [];
      for (var j = 0; j < message.backgroundColorRGBA.length; ++j)
        object.backgroundColorRGBA[j] =
          options.json && !isFinite(message.backgroundColorRGBA[j])
            ? String(message.backgroundColorRGBA[j])
            : message.backgroundColorRGBA[j];
    }
    if (message.textColorRGBA && message.textColorRGBA.length) {
      object.textColorRGBA = [];
      for (var j = 0; j < message.textColorRGBA.length; ++j)
        object.textColorRGBA[j] =
          options.json && !isFinite(message.textColorRGBA[j])
            ? String(message.textColorRGBA[j])
            : message.textColorRGBA[j];
    }
    if (
      message.textAlignment != null &&
      message.hasOwnProperty("textAlignment")
    )
      object.textAlignment = message.textAlignment;
    if (message.scale != null && message.hasOwnProperty("scale"))
      object.scale =
        options.json && !isFinite(message.scale)
          ? String(message.scale)
          : message.scale;
    if (message.fontSize != null && message.hasOwnProperty("fontSize"))
      object.fontSize =
        options.json && !isFinite(message.fontSize)
          ? String(message.fontSize)
          : message.fontSize;
    if (
      message.fontDescriptorData != null &&
      message.hasOwnProperty("fontDescriptorData")
    )
      object.fontDescriptorData =
        options.bytes === String
          ? $util.base64.encode(
              message.fontDescriptorData,
              0,
              message.fontDescriptorData.length
            )
          : options.bytes === Array
          ? Array.prototype.slice.call(message.fontDescriptorData)
          : message.fontDescriptorData;
    if (message.fontString != null && message.hasOwnProperty("fontString"))
      object.fontString = message.fontString;
    return object;
  };

  /**
   * Converts this BackupTextData to JSON.
   * @function toJSON
   * @memberof BackupTextData
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupTextData.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupTextData;
})();

$root.BackupDrawData = (function () {
  /**
   * Properties of a BackupDrawData.
   * @exports IBackupDrawData
   * @interface IBackupDrawData
   * @property {boolean|null} [isEraser] BackupDrawData isEraser
   * @property {number|null} [width] BackupDrawData width
   * @property {Array.<number>|null} [colorRGBA] BackupDrawData colorRGBA
   * @property {number|null} [vertexBufferSize] BackupDrawData vertexBufferSize
   * @property {Array.<IBackupPoint>|null} [vertexBuffer] BackupDrawData vertexBuffer
   */

  /**
   * Constructs a new BackupDrawData.
   * @exports BackupDrawData
   * @classdesc Represents a BackupDrawData.
   * @implements IBackupDrawData
   * @constructor
   * @param {IBackupDrawData=} [properties] Properties to set
   */
  function BackupDrawData(properties) {
    this.colorRGBA = [];
    this.vertexBuffer = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupDrawData isEraser.
   * @member {boolean} isEraser
   * @memberof BackupDrawData
   * @instance
   */
  BackupDrawData.prototype.isEraser = false;

  /**
   * BackupDrawData width.
   * @member {number} width
   * @memberof BackupDrawData
   * @instance
   */
  BackupDrawData.prototype.width = 0;

  /**
   * BackupDrawData colorRGBA.
   * @member {Array.<number>} colorRGBA
   * @memberof BackupDrawData
   * @instance
   */
  BackupDrawData.prototype.colorRGBA = $util.emptyArray;

  /**
   * BackupDrawData vertexBufferSize.
   * @member {number} vertexBufferSize
   * @memberof BackupDrawData
   * @instance
   */
  BackupDrawData.prototype.vertexBufferSize = 0;

  /**
   * BackupDrawData vertexBuffer.
   * @member {Array.<IBackupPoint>} vertexBuffer
   * @memberof BackupDrawData
   * @instance
   */
  BackupDrawData.prototype.vertexBuffer = $util.emptyArray;

  /**
   * Creates a new BackupDrawData instance using the specified properties.
   * @function create
   * @memberof BackupDrawData
   * @static
   * @param {IBackupDrawData=} [properties] Properties to set
   * @returns {BackupDrawData} BackupDrawData instance
   */
  BackupDrawData.create = function create(properties) {
    return new BackupDrawData(properties);
  };

  /**
   * Encodes the specified BackupDrawData message. Does not implicitly {@link BackupDrawData.verify|verify} messages.
   * @function encode
   * @memberof BackupDrawData
   * @static
   * @param {IBackupDrawData} message BackupDrawData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupDrawData.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.isEraser != null &&
      Object.hasOwnProperty.call(message, "isEraser")
    )
      writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.isEraser);
    if (message.width != null && Object.hasOwnProperty.call(message, "width"))
      writer.uint32(/* id 2, wireType 1 =*/ 17).double(message.width);
    if (message.colorRGBA != null && message.colorRGBA.length) {
      writer.uint32(/* id 3, wireType 2 =*/ 26).fork();
      for (var i = 0; i < message.colorRGBA.length; ++i)
        writer.double(message.colorRGBA[i]);
      writer.ldelim();
    }
    if (
      message.vertexBufferSize != null &&
      Object.hasOwnProperty.call(message, "vertexBufferSize")
    )
      writer
        .uint32(/* id 4, wireType 1 =*/ 33)
        .double(message.vertexBufferSize);
    if (message.vertexBuffer != null && message.vertexBuffer.length)
      for (var i = 0; i < message.vertexBuffer.length; ++i)
        $root.BackupPoint.encode(
          message.vertexBuffer[i],
          writer.uint32(/* id 5, wireType 2 =*/ 42).fork()
        ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified BackupDrawData message, length delimited. Does not implicitly {@link BackupDrawData.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupDrawData
   * @static
   * @param {IBackupDrawData} message BackupDrawData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupDrawData.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupDrawData message from the specified reader or buffer.
   * @function decode
   * @memberof BackupDrawData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupDrawData} BackupDrawData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupDrawData.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupDrawData();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.isEraser = reader.bool();
          break;
        case 2:
          message.width = reader.double();
          break;
        case 3:
          if (!(message.colorRGBA && message.colorRGBA.length))
            message.colorRGBA = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) message.colorRGBA.push(reader.double());
          } else message.colorRGBA.push(reader.double());
          break;
        case 4:
          message.vertexBufferSize = reader.double();
          break;
        case 5:
          if (!(message.vertexBuffer && message.vertexBuffer.length))
            message.vertexBuffer = [];
          message.vertexBuffer.push(
            $root.BackupPoint.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupDrawData message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupDrawData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupDrawData} BackupDrawData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupDrawData.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupDrawData message.
   * @function verify
   * @memberof BackupDrawData
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupDrawData.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.isEraser != null && message.hasOwnProperty("isEraser"))
      if (typeof message.isEraser !== "boolean")
        return "isEraser: boolean expected";
    if (message.width != null && message.hasOwnProperty("width"))
      if (typeof message.width !== "number") return "width: number expected";
    if (message.colorRGBA != null && message.hasOwnProperty("colorRGBA")) {
      if (!Array.isArray(message.colorRGBA)) return "colorRGBA: array expected";
      for (var i = 0; i < message.colorRGBA.length; ++i)
        if (typeof message.colorRGBA[i] !== "number")
          return "colorRGBA: number[] expected";
    }
    if (
      message.vertexBufferSize != null &&
      message.hasOwnProperty("vertexBufferSize")
    )
      if (typeof message.vertexBufferSize !== "number")
        return "vertexBufferSize: number expected";
    if (
      message.vertexBuffer != null &&
      message.hasOwnProperty("vertexBuffer")
    ) {
      if (!Array.isArray(message.vertexBuffer))
        return "vertexBuffer: array expected";
      for (var i = 0; i < message.vertexBuffer.length; ++i) {
        var error = $root.BackupPoint.verify(message.vertexBuffer[i]);
        if (error) return "vertexBuffer." + error;
      }
    }
    return null;
  };

  /**
   * Creates a BackupDrawData message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupDrawData
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupDrawData} BackupDrawData
   */
  BackupDrawData.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupDrawData) return object;
    var message = new $root.BackupDrawData();
    if (object.isEraser != null) message.isEraser = Boolean(object.isEraser);
    if (object.width != null) message.width = Number(object.width);
    if (object.colorRGBA) {
      if (!Array.isArray(object.colorRGBA))
        throw TypeError(".BackupDrawData.colorRGBA: array expected");
      message.colorRGBA = [];
      for (var i = 0; i < object.colorRGBA.length; ++i)
        message.colorRGBA[i] = Number(object.colorRGBA[i]);
    }
    if (object.vertexBufferSize != null)
      message.vertexBufferSize = Number(object.vertexBufferSize);
    if (object.vertexBuffer) {
      if (!Array.isArray(object.vertexBuffer))
        throw TypeError(".BackupDrawData.vertexBuffer: array expected");
      message.vertexBuffer = [];
      for (var i = 0; i < object.vertexBuffer.length; ++i) {
        if (typeof object.vertexBuffer[i] !== "object")
          throw TypeError(".BackupDrawData.vertexBuffer: object expected");
        message.vertexBuffer[i] = $root.BackupPoint.fromObject(
          object.vertexBuffer[i]
        );
      }
    }
    return message;
  };

  /**
   * Creates a plain object from a BackupDrawData message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupDrawData
   * @static
   * @param {BackupDrawData} message BackupDrawData
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupDrawData.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) {
      object.colorRGBA = [];
      object.vertexBuffer = [];
    }
    if (options.defaults) {
      object.isEraser = false;
      object.width = 0;
      object.vertexBufferSize = 0;
    }
    if (message.isEraser != null && message.hasOwnProperty("isEraser"))
      object.isEraser = message.isEraser;
    if (message.width != null && message.hasOwnProperty("width"))
      object.width =
        options.json && !isFinite(message.width)
          ? String(message.width)
          : message.width;
    if (message.colorRGBA && message.colorRGBA.length) {
      object.colorRGBA = [];
      for (var j = 0; j < message.colorRGBA.length; ++j)
        object.colorRGBA[j] =
          options.json && !isFinite(message.colorRGBA[j])
            ? String(message.colorRGBA[j])
            : message.colorRGBA[j];
    }
    if (
      message.vertexBufferSize != null &&
      message.hasOwnProperty("vertexBufferSize")
    )
      object.vertexBufferSize =
        options.json && !isFinite(message.vertexBufferSize)
          ? String(message.vertexBufferSize)
          : message.vertexBufferSize;
    if (message.vertexBuffer && message.vertexBuffer.length) {
      object.vertexBuffer = [];
      for (var j = 0; j < message.vertexBuffer.length; ++j)
        object.vertexBuffer[j] = $root.BackupPoint.toObject(
          message.vertexBuffer[j],
          options
        );
    }
    return object;
  };

  /**
   * Converts this BackupDrawData to JSON.
   * @function toJSON
   * @memberof BackupDrawData
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupDrawData.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupDrawData;
})();

$root.BackupEditingData = (function () {
  /**
   * Properties of a BackupEditingData.
   * @exports IBackupEditingData
   * @interface IBackupEditingData
   * @property {Array.<IBackupDrawData>|null} [pathLineDataArray] BackupEditingData pathLineDataArray
   * @property {Array.<IBackupTextData>|null} [texts] BackupEditingData texts
   * @property {Array.<IBackupSignatureData>|null} [signatures] BackupEditingData signatures
   */

  /**
   * Constructs a new BackupEditingData.
   * @exports BackupEditingData
   * @classdesc Represents a BackupEditingData.
   * @implements IBackupEditingData
   * @constructor
   * @param {IBackupEditingData=} [properties] Properties to set
   */
  function BackupEditingData(properties) {
    this.pathLineDataArray = [];
    this.texts = [];
    this.signatures = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupEditingData pathLineDataArray.
   * @member {Array.<IBackupDrawData>} pathLineDataArray
   * @memberof BackupEditingData
   * @instance
   */
  BackupEditingData.prototype.pathLineDataArray = $util.emptyArray;

  /**
   * BackupEditingData texts.
   * @member {Array.<IBackupTextData>} texts
   * @memberof BackupEditingData
   * @instance
   */
  BackupEditingData.prototype.texts = $util.emptyArray;

  /**
   * BackupEditingData signatures.
   * @member {Array.<IBackupSignatureData>} signatures
   * @memberof BackupEditingData
   * @instance
   */
  BackupEditingData.prototype.signatures = $util.emptyArray;

  /**
   * Creates a new BackupEditingData instance using the specified properties.
   * @function create
   * @memberof BackupEditingData
   * @static
   * @param {IBackupEditingData=} [properties] Properties to set
   * @returns {BackupEditingData} BackupEditingData instance
   */
  BackupEditingData.create = function create(properties) {
    return new BackupEditingData(properties);
  };

  /**
   * Encodes the specified BackupEditingData message. Does not implicitly {@link BackupEditingData.verify|verify} messages.
   * @function encode
   * @memberof BackupEditingData
   * @static
   * @param {IBackupEditingData} message BackupEditingData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupEditingData.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.pathLineDataArray != null && message.pathLineDataArray.length)
      for (var i = 0; i < message.pathLineDataArray.length; ++i)
        $root.BackupDrawData.encode(
          message.pathLineDataArray[i],
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim();
    if (message.texts != null && message.texts.length)
      for (var i = 0; i < message.texts.length; ++i)
        $root.BackupTextData.encode(
          message.texts[i],
          writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
        ).ldelim();
    if (message.signatures != null && message.signatures.length)
      for (var i = 0; i < message.signatures.length; ++i)
        $root.BackupSignatureData.encode(
          message.signatures[i],
          writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
        ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified BackupEditingData message, length delimited. Does not implicitly {@link BackupEditingData.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupEditingData
   * @static
   * @param {IBackupEditingData} message BackupEditingData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupEditingData.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupEditingData message from the specified reader or buffer.
   * @function decode
   * @memberof BackupEditingData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupEditingData} BackupEditingData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupEditingData.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupEditingData();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (!(message.pathLineDataArray && message.pathLineDataArray.length))
            message.pathLineDataArray = [];
          message.pathLineDataArray.push(
            $root.BackupDrawData.decode(reader, reader.uint32())
          );
          break;
        case 2:
          if (!(message.texts && message.texts.length)) message.texts = [];
          message.texts.push(
            $root.BackupTextData.decode(reader, reader.uint32())
          );
          break;
        case 3:
          if (!(message.signatures && message.signatures.length))
            message.signatures = [];
          message.signatures.push(
            $root.BackupSignatureData.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupEditingData message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupEditingData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupEditingData} BackupEditingData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupEditingData.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupEditingData message.
   * @function verify
   * @memberof BackupEditingData
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupEditingData.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (
      message.pathLineDataArray != null &&
      message.hasOwnProperty("pathLineDataArray")
    ) {
      if (!Array.isArray(message.pathLineDataArray))
        return "pathLineDataArray: array expected";
      for (var i = 0; i < message.pathLineDataArray.length; ++i) {
        var error = $root.BackupDrawData.verify(message.pathLineDataArray[i]);
        if (error) return "pathLineDataArray." + error;
      }
    }
    if (message.texts != null && message.hasOwnProperty("texts")) {
      if (!Array.isArray(message.texts)) return "texts: array expected";
      for (var i = 0; i < message.texts.length; ++i) {
        var error = $root.BackupTextData.verify(message.texts[i]);
        if (error) return "texts." + error;
      }
    }
    if (message.signatures != null && message.hasOwnProperty("signatures")) {
      if (!Array.isArray(message.signatures))
        return "signatures: array expected";
      for (var i = 0; i < message.signatures.length; ++i) {
        var error = $root.BackupSignatureData.verify(message.signatures[i]);
        if (error) return "signatures." + error;
      }
    }
    return null;
  };

  /**
   * Creates a BackupEditingData message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupEditingData
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupEditingData} BackupEditingData
   */
  BackupEditingData.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupEditingData) return object;
    var message = new $root.BackupEditingData();
    if (object.pathLineDataArray) {
      if (!Array.isArray(object.pathLineDataArray))
        throw TypeError(".BackupEditingData.pathLineDataArray: array expected");
      message.pathLineDataArray = [];
      for (var i = 0; i < object.pathLineDataArray.length; ++i) {
        if (typeof object.pathLineDataArray[i] !== "object")
          throw TypeError(
            ".BackupEditingData.pathLineDataArray: object expected"
          );
        message.pathLineDataArray[i] = $root.BackupDrawData.fromObject(
          object.pathLineDataArray[i]
        );
      }
    }
    if (object.texts) {
      if (!Array.isArray(object.texts))
        throw TypeError(".BackupEditingData.texts: array expected");
      message.texts = [];
      for (var i = 0; i < object.texts.length; ++i) {
        if (typeof object.texts[i] !== "object")
          throw TypeError(".BackupEditingData.texts: object expected");
        message.texts[i] = $root.BackupTextData.fromObject(object.texts[i]);
      }
    }
    if (object.signatures) {
      if (!Array.isArray(object.signatures))
        throw TypeError(".BackupEditingData.signatures: array expected");
      message.signatures = [];
      for (var i = 0; i < object.signatures.length; ++i) {
        if (typeof object.signatures[i] !== "object")
          throw TypeError(".BackupEditingData.signatures: object expected");
        message.signatures[i] = $root.BackupSignatureData.fromObject(
          object.signatures[i]
        );
      }
    }
    return message;
  };

  /**
   * Creates a plain object from a BackupEditingData message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupEditingData
   * @static
   * @param {BackupEditingData} message BackupEditingData
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupEditingData.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) {
      object.pathLineDataArray = [];
      object.texts = [];
      object.signatures = [];
    }
    if (message.pathLineDataArray && message.pathLineDataArray.length) {
      object.pathLineDataArray = [];
      for (var j = 0; j < message.pathLineDataArray.length; ++j)
        object.pathLineDataArray[j] = $root.BackupDrawData.toObject(
          message.pathLineDataArray[j],
          options
        );
    }
    if (message.texts && message.texts.length) {
      object.texts = [];
      for (var j = 0; j < message.texts.length; ++j)
        object.texts[j] = $root.BackupTextData.toObject(
          message.texts[j],
          options
        );
    }
    if (message.signatures && message.signatures.length) {
      object.signatures = [];
      for (var j = 0; j < message.signatures.length; ++j)
        object.signatures[j] = $root.BackupSignatureData.toObject(
          message.signatures[j],
          options
        );
    }
    return object;
  };

  /**
   * Converts this BackupEditingData to JSON.
   * @function toJSON
   * @memberof BackupEditingData
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupEditingData.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupEditingData;
})();

$root.BackupTextline = (function () {
  /**
   * Properties of a BackupTextline.
   * @exports IBackupTextline
   * @interface IBackupTextline
   * @property {Array.<number>|null} [backgroundColorRGBA] BackupTextline backgroundColorRGBA
   * @property {Array.<number>|null} [textColorRGBA] BackupTextline textColorRGBA
   * @property {number|Long|null} [blockNum] BackupTextline blockNum
   * @property {number|Long|null} [parNum] BackupTextline parNum
   * @property {Array.<number>|null} [srcRectValue] BackupTextline srcRectValue
   * @property {Array.<number>|null} [rectValue] BackupTextline rectValue
   * @property {string|null} [text] BackupTextline text
   * @property {number|null} [fontSize] BackupTextline fontSize
   * @property {Uint8Array|null} [fontDescriptorData] BackupTextline fontDescriptorData
   * @property {string|null} [fontString] BackupTextline fontString
   */

  /**
   * Constructs a new BackupTextline.
   * @exports BackupTextline
   * @classdesc Represents a BackupTextline.
   * @implements IBackupTextline
   * @constructor
   * @param {IBackupTextline=} [properties] Properties to set
   */
  function BackupTextline(properties) {
    this.backgroundColorRGBA = [];
    this.textColorRGBA = [];
    this.srcRectValue = [];
    this.rectValue = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupTextline backgroundColorRGBA.
   * @member {Array.<number>} backgroundColorRGBA
   * @memberof BackupTextline
   * @instance
   */
  BackupTextline.prototype.backgroundColorRGBA = $util.emptyArray;

  /**
   * BackupTextline textColorRGBA.
   * @member {Array.<number>} textColorRGBA
   * @memberof BackupTextline
   * @instance
   */
  BackupTextline.prototype.textColorRGBA = $util.emptyArray;

  /**
   * BackupTextline blockNum.
   * @member {number|Long} blockNum
   * @memberof BackupTextline
   * @instance
   */
  BackupTextline.prototype.blockNum = $util.Long
    ? $util.Long.fromBits(0, 0, false)
    : 0;

  /**
   * BackupTextline parNum.
   * @member {number|Long} parNum
   * @memberof BackupTextline
   * @instance
   */
  BackupTextline.prototype.parNum = $util.Long
    ? $util.Long.fromBits(0, 0, false)
    : 0;

  /**
   * BackupTextline srcRectValue.
   * @member {Array.<number>} srcRectValue
   * @memberof BackupTextline
   * @instance
   */
  BackupTextline.prototype.srcRectValue = $util.emptyArray;

  /**
   * BackupTextline rectValue.
   * @member {Array.<number>} rectValue
   * @memberof BackupTextline
   * @instance
   */
  BackupTextline.prototype.rectValue = $util.emptyArray;

  /**
   * BackupTextline text.
   * @member {string} text
   * @memberof BackupTextline
   * @instance
   */
  BackupTextline.prototype.text = "";

  /**
   * BackupTextline fontSize.
   * @member {number} fontSize
   * @memberof BackupTextline
   * @instance
   */
  BackupTextline.prototype.fontSize = 0;

  /**
   * BackupTextline fontDescriptorData.
   * @member {Uint8Array} fontDescriptorData
   * @memberof BackupTextline
   * @instance
   */
  BackupTextline.prototype.fontDescriptorData = $util.newBuffer([]);

  /**
   * BackupTextline fontString.
   * @member {string} fontString
   * @memberof BackupTextline
   * @instance
   */
  BackupTextline.prototype.fontString = "";

  /**
   * Creates a new BackupTextline instance using the specified properties.
   * @function create
   * @memberof BackupTextline
   * @static
   * @param {IBackupTextline=} [properties] Properties to set
   * @returns {BackupTextline} BackupTextline instance
   */
  BackupTextline.create = function create(properties) {
    return new BackupTextline(properties);
  };

  /**
   * Encodes the specified BackupTextline message. Does not implicitly {@link BackupTextline.verify|verify} messages.
   * @function encode
   * @memberof BackupTextline
   * @static
   * @param {IBackupTextline} message BackupTextline message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupTextline.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.backgroundColorRGBA != null &&
      message.backgroundColorRGBA.length
    ) {
      writer.uint32(/* id 1, wireType 2 =*/ 10).fork();
      for (var i = 0; i < message.backgroundColorRGBA.length; ++i)
        writer.double(message.backgroundColorRGBA[i]);
      writer.ldelim();
    }
    if (message.textColorRGBA != null && message.textColorRGBA.length) {
      writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
      for (var i = 0; i < message.textColorRGBA.length; ++i)
        writer.double(message.textColorRGBA[i]);
      writer.ldelim();
    }
    if (
      message.blockNum != null &&
      Object.hasOwnProperty.call(message, "blockNum")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.blockNum);
    if (message.parNum != null && Object.hasOwnProperty.call(message, "parNum"))
      writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.parNum);
    if (message.srcRectValue != null && message.srcRectValue.length) {
      writer.uint32(/* id 5, wireType 2 =*/ 42).fork();
      for (var i = 0; i < message.srcRectValue.length; ++i)
        writer.double(message.srcRectValue[i]);
      writer.ldelim();
    }
    if (message.rectValue != null && message.rectValue.length) {
      writer.uint32(/* id 6, wireType 2 =*/ 50).fork();
      for (var i = 0; i < message.rectValue.length; ++i)
        writer.double(message.rectValue[i]);
      writer.ldelim();
    }
    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
      writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.text);
    if (
      message.fontSize != null &&
      Object.hasOwnProperty.call(message, "fontSize")
    )
      writer.uint32(/* id 8, wireType 1 =*/ 65).double(message.fontSize);
    if (
      message.fontDescriptorData != null &&
      Object.hasOwnProperty.call(message, "fontDescriptorData")
    )
      writer
        .uint32(/* id 9, wireType 2 =*/ 74)
        .bytes(message.fontDescriptorData);
    if (
      message.fontString != null &&
      Object.hasOwnProperty.call(message, "fontString")
    )
      writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.fontString);
    return writer;
  };

  /**
   * Encodes the specified BackupTextline message, length delimited. Does not implicitly {@link BackupTextline.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupTextline
   * @static
   * @param {IBackupTextline} message BackupTextline message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupTextline.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupTextline message from the specified reader or buffer.
   * @function decode
   * @memberof BackupTextline
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupTextline} BackupTextline
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupTextline.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupTextline();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (
            !(message.backgroundColorRGBA && message.backgroundColorRGBA.length)
          )
            message.backgroundColorRGBA = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2)
              message.backgroundColorRGBA.push(reader.double());
          } else message.backgroundColorRGBA.push(reader.double());
          break;
        case 2:
          if (!(message.textColorRGBA && message.textColorRGBA.length))
            message.textColorRGBA = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2)
              message.textColorRGBA.push(reader.double());
          } else message.textColorRGBA.push(reader.double());
          break;
        case 3:
          message.blockNum = reader.int64();
          break;
        case 4:
          message.parNum = reader.int64();
          break;
        case 5:
          if (!(message.srcRectValue && message.srcRectValue.length))
            message.srcRectValue = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2)
              message.srcRectValue.push(reader.double());
          } else message.srcRectValue.push(reader.double());
          break;
        case 6:
          if (!(message.rectValue && message.rectValue.length))
            message.rectValue = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) message.rectValue.push(reader.double());
          } else message.rectValue.push(reader.double());
          break;
        case 7:
          message.text = reader.string();
          break;
        case 8:
          message.fontSize = reader.double();
          break;
        case 9:
          message.fontDescriptorData = reader.bytes();
          break;
        case 10:
          message.fontString = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupTextline message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupTextline
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupTextline} BackupTextline
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupTextline.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupTextline message.
   * @function verify
   * @memberof BackupTextline
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupTextline.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (
      message.backgroundColorRGBA != null &&
      message.hasOwnProperty("backgroundColorRGBA")
    ) {
      if (!Array.isArray(message.backgroundColorRGBA))
        return "backgroundColorRGBA: array expected";
      for (var i = 0; i < message.backgroundColorRGBA.length; ++i)
        if (typeof message.backgroundColorRGBA[i] !== "number")
          return "backgroundColorRGBA: number[] expected";
    }
    if (
      message.textColorRGBA != null &&
      message.hasOwnProperty("textColorRGBA")
    ) {
      if (!Array.isArray(message.textColorRGBA))
        return "textColorRGBA: array expected";
      for (var i = 0; i < message.textColorRGBA.length; ++i)
        if (typeof message.textColorRGBA[i] !== "number")
          return "textColorRGBA: number[] expected";
    }
    if (message.blockNum != null && message.hasOwnProperty("blockNum"))
      if (
        !$util.isInteger(message.blockNum) &&
        !(
          message.blockNum &&
          $util.isInteger(message.blockNum.low) &&
          $util.isInteger(message.blockNum.high)
        )
      )
        return "blockNum: integer|Long expected";
    if (message.parNum != null && message.hasOwnProperty("parNum"))
      if (
        !$util.isInteger(message.parNum) &&
        !(
          message.parNum &&
          $util.isInteger(message.parNum.low) &&
          $util.isInteger(message.parNum.high)
        )
      )
        return "parNum: integer|Long expected";
    if (
      message.srcRectValue != null &&
      message.hasOwnProperty("srcRectValue")
    ) {
      if (!Array.isArray(message.srcRectValue))
        return "srcRectValue: array expected";
      for (var i = 0; i < message.srcRectValue.length; ++i)
        if (typeof message.srcRectValue[i] !== "number")
          return "srcRectValue: number[] expected";
    }
    if (message.rectValue != null && message.hasOwnProperty("rectValue")) {
      if (!Array.isArray(message.rectValue)) return "rectValue: array expected";
      for (var i = 0; i < message.rectValue.length; ++i)
        if (typeof message.rectValue[i] !== "number")
          return "rectValue: number[] expected";
    }
    if (message.text != null && message.hasOwnProperty("text"))
      if (!$util.isString(message.text)) return "text: string expected";
    if (message.fontSize != null && message.hasOwnProperty("fontSize"))
      if (typeof message.fontSize !== "number")
        return "fontSize: number expected";
    if (
      message.fontDescriptorData != null &&
      message.hasOwnProperty("fontDescriptorData")
    )
      if (
        !(
          (message.fontDescriptorData &&
            typeof message.fontDescriptorData.length === "number") ||
          $util.isString(message.fontDescriptorData)
        )
      )
        return "fontDescriptorData: buffer expected";
    if (message.fontString != null && message.hasOwnProperty("fontString"))
      if (!$util.isString(message.fontString))
        return "fontString: string expected";
    return null;
  };

  /**
   * Creates a BackupTextline message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupTextline
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupTextline} BackupTextline
   */
  BackupTextline.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupTextline) return object;
    var message = new $root.BackupTextline();
    if (object.backgroundColorRGBA) {
      if (!Array.isArray(object.backgroundColorRGBA))
        throw TypeError(".BackupTextline.backgroundColorRGBA: array expected");
      message.backgroundColorRGBA = [];
      for (var i = 0; i < object.backgroundColorRGBA.length; ++i)
        message.backgroundColorRGBA[i] = Number(object.backgroundColorRGBA[i]);
    }
    if (object.textColorRGBA) {
      if (!Array.isArray(object.textColorRGBA))
        throw TypeError(".BackupTextline.textColorRGBA: array expected");
      message.textColorRGBA = [];
      for (var i = 0; i < object.textColorRGBA.length; ++i)
        message.textColorRGBA[i] = Number(object.textColorRGBA[i]);
    }
    if (object.blockNum != null)
      if ($util.Long)
        (message.blockNum = $util.Long.fromValue(
          object.blockNum
        )).unsigned = false;
      else if (typeof object.blockNum === "string")
        message.blockNum = parseInt(object.blockNum, 10);
      else if (typeof object.blockNum === "number")
        message.blockNum = object.blockNum;
      else if (typeof object.blockNum === "object")
        message.blockNum = new $util.LongBits(
          object.blockNum.low >>> 0,
          object.blockNum.high >>> 0
        ).toNumber();
    if (object.parNum != null)
      if ($util.Long)
        (message.parNum = $util.Long.fromValue(object.parNum)).unsigned = false;
      else if (typeof object.parNum === "string")
        message.parNum = parseInt(object.parNum, 10);
      else if (typeof object.parNum === "number")
        message.parNum = object.parNum;
      else if (typeof object.parNum === "object")
        message.parNum = new $util.LongBits(
          object.parNum.low >>> 0,
          object.parNum.high >>> 0
        ).toNumber();
    if (object.srcRectValue) {
      if (!Array.isArray(object.srcRectValue))
        throw TypeError(".BackupTextline.srcRectValue: array expected");
      message.srcRectValue = [];
      for (var i = 0; i < object.srcRectValue.length; ++i)
        message.srcRectValue[i] = Number(object.srcRectValue[i]);
    }
    if (object.rectValue) {
      if (!Array.isArray(object.rectValue))
        throw TypeError(".BackupTextline.rectValue: array expected");
      message.rectValue = [];
      for (var i = 0; i < object.rectValue.length; ++i)
        message.rectValue[i] = Number(object.rectValue[i]);
    }
    if (object.text != null) message.text = String(object.text);
    if (object.fontSize != null) message.fontSize = Number(object.fontSize);
    if (object.fontDescriptorData != null)
      if (typeof object.fontDescriptorData === "string")
        $util.base64.decode(
          object.fontDescriptorData,
          (message.fontDescriptorData = $util.newBuffer(
            $util.base64.length(object.fontDescriptorData)
          )),
          0
        );
      else if (object.fontDescriptorData.length)
        message.fontDescriptorData = object.fontDescriptorData;
    if (object.fontString != null)
      message.fontString = String(object.fontString);
    return message;
  };

  /**
   * Creates a plain object from a BackupTextline message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupTextline
   * @static
   * @param {BackupTextline} message BackupTextline
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupTextline.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) {
      object.backgroundColorRGBA = [];
      object.textColorRGBA = [];
      object.srcRectValue = [];
      object.rectValue = [];
    }
    if (options.defaults) {
      if ($util.Long) {
        var long = new $util.Long(0, 0, false);
        object.blockNum =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.blockNum = options.longs === String ? "0" : 0;
      if ($util.Long) {
        var long = new $util.Long(0, 0, false);
        object.parNum =
          options.longs === String
            ? long.toString()
            : options.longs === Number
            ? long.toNumber()
            : long;
      } else object.parNum = options.longs === String ? "0" : 0;
      object.text = "";
      object.fontSize = 0;
      if (options.bytes === String) object.fontDescriptorData = "";
      else {
        object.fontDescriptorData = [];
        if (options.bytes !== Array)
          object.fontDescriptorData = $util.newBuffer(
            object.fontDescriptorData
          );
      }
      object.fontString = "";
    }
    if (message.backgroundColorRGBA && message.backgroundColorRGBA.length) {
      object.backgroundColorRGBA = [];
      for (var j = 0; j < message.backgroundColorRGBA.length; ++j)
        object.backgroundColorRGBA[j] =
          options.json && !isFinite(message.backgroundColorRGBA[j])
            ? String(message.backgroundColorRGBA[j])
            : message.backgroundColorRGBA[j];
    }
    if (message.textColorRGBA && message.textColorRGBA.length) {
      object.textColorRGBA = [];
      for (var j = 0; j < message.textColorRGBA.length; ++j)
        object.textColorRGBA[j] =
          options.json && !isFinite(message.textColorRGBA[j])
            ? String(message.textColorRGBA[j])
            : message.textColorRGBA[j];
    }
    if (message.blockNum != null && message.hasOwnProperty("blockNum"))
      if (typeof message.blockNum === "number")
        object.blockNum =
          options.longs === String
            ? String(message.blockNum)
            : message.blockNum;
      else
        object.blockNum =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.blockNum)
            : options.longs === Number
            ? new $util.LongBits(
                message.blockNum.low >>> 0,
                message.blockNum.high >>> 0
              ).toNumber()
            : message.blockNum;
    if (message.parNum != null && message.hasOwnProperty("parNum"))
      if (typeof message.parNum === "number")
        object.parNum =
          options.longs === String ? String(message.parNum) : message.parNum;
      else
        object.parNum =
          options.longs === String
            ? $util.Long.prototype.toString.call(message.parNum)
            : options.longs === Number
            ? new $util.LongBits(
                message.parNum.low >>> 0,
                message.parNum.high >>> 0
              ).toNumber()
            : message.parNum;
    if (message.srcRectValue && message.srcRectValue.length) {
      object.srcRectValue = [];
      for (var j = 0; j < message.srcRectValue.length; ++j)
        object.srcRectValue[j] =
          options.json && !isFinite(message.srcRectValue[j])
            ? String(message.srcRectValue[j])
            : message.srcRectValue[j];
    }
    if (message.rectValue && message.rectValue.length) {
      object.rectValue = [];
      for (var j = 0; j < message.rectValue.length; ++j)
        object.rectValue[j] =
          options.json && !isFinite(message.rectValue[j])
            ? String(message.rectValue[j])
            : message.rectValue[j];
    }
    if (message.text != null && message.hasOwnProperty("text"))
      object.text = message.text;
    if (message.fontSize != null && message.hasOwnProperty("fontSize"))
      object.fontSize =
        options.json && !isFinite(message.fontSize)
          ? String(message.fontSize)
          : message.fontSize;
    if (
      message.fontDescriptorData != null &&
      message.hasOwnProperty("fontDescriptorData")
    )
      object.fontDescriptorData =
        options.bytes === String
          ? $util.base64.encode(
              message.fontDescriptorData,
              0,
              message.fontDescriptorData.length
            )
          : options.bytes === Array
          ? Array.prototype.slice.call(message.fontDescriptorData)
          : message.fontDescriptorData;
    if (message.fontString != null && message.hasOwnProperty("fontString"))
      object.fontString = message.fontString;
    return object;
  };

  /**
   * Converts this BackupTextline to JSON.
   * @function toJSON
   * @memberof BackupTextline
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupTextline.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupTextline;
})();

$root.BackupTextbox = (function () {
  /**
   * Properties of a BackupTextbox.
   * @exports IBackupTextbox
   * @interface IBackupTextbox
   * @property {Array.<number>|null} [backgroundColorRGBA] BackupTextbox backgroundColorRGBA
   * @property {Array.<number>|null} [textColorRGBA] BackupTextbox textColorRGBA
   * @property {Array.<number>|null} [rectValue] BackupTextbox rectValue
   * @property {string|null} [text] BackupTextbox text
   * @property {number|null} [fontSize] BackupTextbox fontSize
   * @property {Uint8Array|null} [fontDescriptorData] BackupTextbox fontDescriptorData
   * @property {string|null} [fontString] BackupTextbox fontString
   */

  /**
   * Constructs a new BackupTextbox.
   * @exports BackupTextbox
   * @classdesc Represents a BackupTextbox.
   * @implements IBackupTextbox
   * @constructor
   * @param {IBackupTextbox=} [properties] Properties to set
   */
  function BackupTextbox(properties) {
    this.backgroundColorRGBA = [];
    this.textColorRGBA = [];
    this.rectValue = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupTextbox backgroundColorRGBA.
   * @member {Array.<number>} backgroundColorRGBA
   * @memberof BackupTextbox
   * @instance
   */
  BackupTextbox.prototype.backgroundColorRGBA = $util.emptyArray;

  /**
   * BackupTextbox textColorRGBA.
   * @member {Array.<number>} textColorRGBA
   * @memberof BackupTextbox
   * @instance
   */
  BackupTextbox.prototype.textColorRGBA = $util.emptyArray;

  /**
   * BackupTextbox rectValue.
   * @member {Array.<number>} rectValue
   * @memberof BackupTextbox
   * @instance
   */
  BackupTextbox.prototype.rectValue = $util.emptyArray;

  /**
   * BackupTextbox text.
   * @member {string} text
   * @memberof BackupTextbox
   * @instance
   */
  BackupTextbox.prototype.text = "";

  /**
   * BackupTextbox fontSize.
   * @member {number} fontSize
   * @memberof BackupTextbox
   * @instance
   */
  BackupTextbox.prototype.fontSize = 0;

  /**
   * BackupTextbox fontDescriptorData.
   * @member {Uint8Array} fontDescriptorData
   * @memberof BackupTextbox
   * @instance
   */
  BackupTextbox.prototype.fontDescriptorData = $util.newBuffer([]);

  /**
   * BackupTextbox fontString.
   * @member {string} fontString
   * @memberof BackupTextbox
   * @instance
   */
  BackupTextbox.prototype.fontString = "";

  /**
   * Creates a new BackupTextbox instance using the specified properties.
   * @function create
   * @memberof BackupTextbox
   * @static
   * @param {IBackupTextbox=} [properties] Properties to set
   * @returns {BackupTextbox} BackupTextbox instance
   */
  BackupTextbox.create = function create(properties) {
    return new BackupTextbox(properties);
  };

  /**
   * Encodes the specified BackupTextbox message. Does not implicitly {@link BackupTextbox.verify|verify} messages.
   * @function encode
   * @memberof BackupTextbox
   * @static
   * @param {IBackupTextbox} message BackupTextbox message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupTextbox.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.backgroundColorRGBA != null &&
      message.backgroundColorRGBA.length
    ) {
      writer.uint32(/* id 1, wireType 2 =*/ 10).fork();
      for (var i = 0; i < message.backgroundColorRGBA.length; ++i)
        writer.double(message.backgroundColorRGBA[i]);
      writer.ldelim();
    }
    if (message.textColorRGBA != null && message.textColorRGBA.length) {
      writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
      for (var i = 0; i < message.textColorRGBA.length; ++i)
        writer.double(message.textColorRGBA[i]);
      writer.ldelim();
    }
    if (message.rectValue != null && message.rectValue.length) {
      writer.uint32(/* id 3, wireType 2 =*/ 26).fork();
      for (var i = 0; i < message.rectValue.length; ++i)
        writer.double(message.rectValue[i]);
      writer.ldelim();
    }
    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
      writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.text);
    if (
      message.fontSize != null &&
      Object.hasOwnProperty.call(message, "fontSize")
    )
      writer.uint32(/* id 5, wireType 1 =*/ 41).double(message.fontSize);
    if (
      message.fontDescriptorData != null &&
      Object.hasOwnProperty.call(message, "fontDescriptorData")
    )
      writer
        .uint32(/* id 6, wireType 2 =*/ 50)
        .bytes(message.fontDescriptorData);
    if (
      message.fontString != null &&
      Object.hasOwnProperty.call(message, "fontString")
    )
      writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.fontString);
    return writer;
  };

  /**
   * Encodes the specified BackupTextbox message, length delimited. Does not implicitly {@link BackupTextbox.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupTextbox
   * @static
   * @param {IBackupTextbox} message BackupTextbox message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupTextbox.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupTextbox message from the specified reader or buffer.
   * @function decode
   * @memberof BackupTextbox
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupTextbox} BackupTextbox
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupTextbox.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupTextbox();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (
            !(message.backgroundColorRGBA && message.backgroundColorRGBA.length)
          )
            message.backgroundColorRGBA = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2)
              message.backgroundColorRGBA.push(reader.double());
          } else message.backgroundColorRGBA.push(reader.double());
          break;
        case 2:
          if (!(message.textColorRGBA && message.textColorRGBA.length))
            message.textColorRGBA = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2)
              message.textColorRGBA.push(reader.double());
          } else message.textColorRGBA.push(reader.double());
          break;
        case 3:
          if (!(message.rectValue && message.rectValue.length))
            message.rectValue = [];
          if ((tag & 7) === 2) {
            var end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) message.rectValue.push(reader.double());
          } else message.rectValue.push(reader.double());
          break;
        case 4:
          message.text = reader.string();
          break;
        case 5:
          message.fontSize = reader.double();
          break;
        case 6:
          message.fontDescriptorData = reader.bytes();
          break;
        case 7:
          message.fontString = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupTextbox message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupTextbox
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupTextbox} BackupTextbox
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupTextbox.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupTextbox message.
   * @function verify
   * @memberof BackupTextbox
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupTextbox.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (
      message.backgroundColorRGBA != null &&
      message.hasOwnProperty("backgroundColorRGBA")
    ) {
      if (!Array.isArray(message.backgroundColorRGBA))
        return "backgroundColorRGBA: array expected";
      for (var i = 0; i < message.backgroundColorRGBA.length; ++i)
        if (typeof message.backgroundColorRGBA[i] !== "number")
          return "backgroundColorRGBA: number[] expected";
    }
    if (
      message.textColorRGBA != null &&
      message.hasOwnProperty("textColorRGBA")
    ) {
      if (!Array.isArray(message.textColorRGBA))
        return "textColorRGBA: array expected";
      for (var i = 0; i < message.textColorRGBA.length; ++i)
        if (typeof message.textColorRGBA[i] !== "number")
          return "textColorRGBA: number[] expected";
    }
    if (message.rectValue != null && message.hasOwnProperty("rectValue")) {
      if (!Array.isArray(message.rectValue)) return "rectValue: array expected";
      for (var i = 0; i < message.rectValue.length; ++i)
        if (typeof message.rectValue[i] !== "number")
          return "rectValue: number[] expected";
    }
    if (message.text != null && message.hasOwnProperty("text"))
      if (!$util.isString(message.text)) return "text: string expected";
    if (message.fontSize != null && message.hasOwnProperty("fontSize"))
      if (typeof message.fontSize !== "number")
        return "fontSize: number expected";
    if (
      message.fontDescriptorData != null &&
      message.hasOwnProperty("fontDescriptorData")
    )
      if (
        !(
          (message.fontDescriptorData &&
            typeof message.fontDescriptorData.length === "number") ||
          $util.isString(message.fontDescriptorData)
        )
      )
        return "fontDescriptorData: buffer expected";
    if (message.fontString != null && message.hasOwnProperty("fontString"))
      if (!$util.isString(message.fontString))
        return "fontString: string expected";
    return null;
  };

  /**
   * Creates a BackupTextbox message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupTextbox
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupTextbox} BackupTextbox
   */
  BackupTextbox.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupTextbox) return object;
    var message = new $root.BackupTextbox();
    if (object.backgroundColorRGBA) {
      if (!Array.isArray(object.backgroundColorRGBA))
        throw TypeError(".BackupTextbox.backgroundColorRGBA: array expected");
      message.backgroundColorRGBA = [];
      for (var i = 0; i < object.backgroundColorRGBA.length; ++i)
        message.backgroundColorRGBA[i] = Number(object.backgroundColorRGBA[i]);
    }
    if (object.textColorRGBA) {
      if (!Array.isArray(object.textColorRGBA))
        throw TypeError(".BackupTextbox.textColorRGBA: array expected");
      message.textColorRGBA = [];
      for (var i = 0; i < object.textColorRGBA.length; ++i)
        message.textColorRGBA[i] = Number(object.textColorRGBA[i]);
    }
    if (object.rectValue) {
      if (!Array.isArray(object.rectValue))
        throw TypeError(".BackupTextbox.rectValue: array expected");
      message.rectValue = [];
      for (var i = 0; i < object.rectValue.length; ++i)
        message.rectValue[i] = Number(object.rectValue[i]);
    }
    if (object.text != null) message.text = String(object.text);
    if (object.fontSize != null) message.fontSize = Number(object.fontSize);
    if (object.fontDescriptorData != null)
      if (typeof object.fontDescriptorData === "string")
        $util.base64.decode(
          object.fontDescriptorData,
          (message.fontDescriptorData = $util.newBuffer(
            $util.base64.length(object.fontDescriptorData)
          )),
          0
        );
      else if (object.fontDescriptorData.length)
        message.fontDescriptorData = object.fontDescriptorData;
    if (object.fontString != null)
      message.fontString = String(object.fontString);
    return message;
  };

  /**
   * Creates a plain object from a BackupTextbox message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupTextbox
   * @static
   * @param {BackupTextbox} message BackupTextbox
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupTextbox.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) {
      object.backgroundColorRGBA = [];
      object.textColorRGBA = [];
      object.rectValue = [];
    }
    if (options.defaults) {
      object.text = "";
      object.fontSize = 0;
      if (options.bytes === String) object.fontDescriptorData = "";
      else {
        object.fontDescriptorData = [];
        if (options.bytes !== Array)
          object.fontDescriptorData = $util.newBuffer(
            object.fontDescriptorData
          );
      }
      object.fontString = "";
    }
    if (message.backgroundColorRGBA && message.backgroundColorRGBA.length) {
      object.backgroundColorRGBA = [];
      for (var j = 0; j < message.backgroundColorRGBA.length; ++j)
        object.backgroundColorRGBA[j] =
          options.json && !isFinite(message.backgroundColorRGBA[j])
            ? String(message.backgroundColorRGBA[j])
            : message.backgroundColorRGBA[j];
    }
    if (message.textColorRGBA && message.textColorRGBA.length) {
      object.textColorRGBA = [];
      for (var j = 0; j < message.textColorRGBA.length; ++j)
        object.textColorRGBA[j] =
          options.json && !isFinite(message.textColorRGBA[j])
            ? String(message.textColorRGBA[j])
            : message.textColorRGBA[j];
    }
    if (message.rectValue && message.rectValue.length) {
      object.rectValue = [];
      for (var j = 0; j < message.rectValue.length; ++j)
        object.rectValue[j] =
          options.json && !isFinite(message.rectValue[j])
            ? String(message.rectValue[j])
            : message.rectValue[j];
    }
    if (message.text != null && message.hasOwnProperty("text"))
      object.text = message.text;
    if (message.fontSize != null && message.hasOwnProperty("fontSize"))
      object.fontSize =
        options.json && !isFinite(message.fontSize)
          ? String(message.fontSize)
          : message.fontSize;
    if (
      message.fontDescriptorData != null &&
      message.hasOwnProperty("fontDescriptorData")
    )
      object.fontDescriptorData =
        options.bytes === String
          ? $util.base64.encode(
              message.fontDescriptorData,
              0,
              message.fontDescriptorData.length
            )
          : options.bytes === Array
          ? Array.prototype.slice.call(message.fontDescriptorData)
          : message.fontDescriptorData;
    if (message.fontString != null && message.hasOwnProperty("fontString"))
      object.fontString = message.fontString;
    return object;
  };

  /**
   * Converts this BackupTextbox to JSON.
   * @function toJSON
   * @memberof BackupTextbox
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupTextbox.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupTextbox;
})();

$root.BackupTextlineData = (function () {
  /**
   * Properties of a BackupTextlineData.
   * @exports IBackupTextlineData
   * @interface IBackupTextlineData
   * @property {Array.<IBackupTextline>|null} [textlines] BackupTextlineData textlines
   */

  /**
   * Constructs a new BackupTextlineData.
   * @exports BackupTextlineData
   * @classdesc Represents a BackupTextlineData.
   * @implements IBackupTextlineData
   * @constructor
   * @param {IBackupTextlineData=} [properties] Properties to set
   */
  function BackupTextlineData(properties) {
    this.textlines = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupTextlineData textlines.
   * @member {Array.<IBackupTextline>} textlines
   * @memberof BackupTextlineData
   * @instance
   */
  BackupTextlineData.prototype.textlines = $util.emptyArray;

  /**
   * Creates a new BackupTextlineData instance using the specified properties.
   * @function create
   * @memberof BackupTextlineData
   * @static
   * @param {IBackupTextlineData=} [properties] Properties to set
   * @returns {BackupTextlineData} BackupTextlineData instance
   */
  BackupTextlineData.create = function create(properties) {
    return new BackupTextlineData(properties);
  };

  /**
   * Encodes the specified BackupTextlineData message. Does not implicitly {@link BackupTextlineData.verify|verify} messages.
   * @function encode
   * @memberof BackupTextlineData
   * @static
   * @param {IBackupTextlineData} message BackupTextlineData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupTextlineData.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.textlines != null && message.textlines.length)
      for (var i = 0; i < message.textlines.length; ++i)
        $root.BackupTextline.encode(
          message.textlines[i],
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified BackupTextlineData message, length delimited. Does not implicitly {@link BackupTextlineData.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupTextlineData
   * @static
   * @param {IBackupTextlineData} message BackupTextlineData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupTextlineData.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupTextlineData message from the specified reader or buffer.
   * @function decode
   * @memberof BackupTextlineData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupTextlineData} BackupTextlineData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupTextlineData.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupTextlineData();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (!(message.textlines && message.textlines.length))
            message.textlines = [];
          message.textlines.push(
            $root.BackupTextline.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupTextlineData message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupTextlineData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupTextlineData} BackupTextlineData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupTextlineData.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupTextlineData message.
   * @function verify
   * @memberof BackupTextlineData
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupTextlineData.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.textlines != null && message.hasOwnProperty("textlines")) {
      if (!Array.isArray(message.textlines)) return "textlines: array expected";
      for (var i = 0; i < message.textlines.length; ++i) {
        var error = $root.BackupTextline.verify(message.textlines[i]);
        if (error) return "textlines." + error;
      }
    }
    return null;
  };

  /**
   * Creates a BackupTextlineData message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupTextlineData
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupTextlineData} BackupTextlineData
   */
  BackupTextlineData.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupTextlineData) return object;
    var message = new $root.BackupTextlineData();
    if (object.textlines) {
      if (!Array.isArray(object.textlines))
        throw TypeError(".BackupTextlineData.textlines: array expected");
      message.textlines = [];
      for (var i = 0; i < object.textlines.length; ++i) {
        if (typeof object.textlines[i] !== "object")
          throw TypeError(".BackupTextlineData.textlines: object expected");
        message.textlines[i] = $root.BackupTextline.fromObject(
          object.textlines[i]
        );
      }
    }
    return message;
  };

  /**
   * Creates a plain object from a BackupTextlineData message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupTextlineData
   * @static
   * @param {BackupTextlineData} message BackupTextlineData
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupTextlineData.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.textlines = [];
    if (message.textlines && message.textlines.length) {
      object.textlines = [];
      for (var j = 0; j < message.textlines.length; ++j)
        object.textlines[j] = $root.BackupTextline.toObject(
          message.textlines[j],
          options
        );
    }
    return object;
  };

  /**
   * Converts this BackupTextlineData to JSON.
   * @function toJSON
   * @memberof BackupTextlineData
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupTextlineData.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupTextlineData;
})();

$root.BackupTextboxData = (function () {
  /**
   * Properties of a BackupTextboxData.
   * @exports IBackupTextboxData
   * @interface IBackupTextboxData
   * @property {Array.<IBackupTextbox>|null} [textboxes] BackupTextboxData textboxes
   */

  /**
   * Constructs a new BackupTextboxData.
   * @exports BackupTextboxData
   * @classdesc Represents a BackupTextboxData.
   * @implements IBackupTextboxData
   * @constructor
   * @param {IBackupTextboxData=} [properties] Properties to set
   */
  function BackupTextboxData(properties) {
    this.textboxes = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * BackupTextboxData textboxes.
   * @member {Array.<IBackupTextbox>} textboxes
   * @memberof BackupTextboxData
   * @instance
   */
  BackupTextboxData.prototype.textboxes = $util.emptyArray;

  /**
   * Creates a new BackupTextboxData instance using the specified properties.
   * @function create
   * @memberof BackupTextboxData
   * @static
   * @param {IBackupTextboxData=} [properties] Properties to set
   * @returns {BackupTextboxData} BackupTextboxData instance
   */
  BackupTextboxData.create = function create(properties) {
    return new BackupTextboxData(properties);
  };

  /**
   * Encodes the specified BackupTextboxData message. Does not implicitly {@link BackupTextboxData.verify|verify} messages.
   * @function encode
   * @memberof BackupTextboxData
   * @static
   * @param {IBackupTextboxData} message BackupTextboxData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupTextboxData.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.textboxes != null && message.textboxes.length)
      for (var i = 0; i < message.textboxes.length; ++i)
        $root.BackupTextbox.encode(
          message.textboxes[i],
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified BackupTextboxData message, length delimited. Does not implicitly {@link BackupTextboxData.verify|verify} messages.
   * @function encodeDelimited
   * @memberof BackupTextboxData
   * @static
   * @param {IBackupTextboxData} message BackupTextboxData message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  BackupTextboxData.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a BackupTextboxData message from the specified reader or buffer.
   * @function decode
   * @memberof BackupTextboxData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {BackupTextboxData} BackupTextboxData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupTextboxData.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.BackupTextboxData();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (!(message.textboxes && message.textboxes.length))
            message.textboxes = [];
          message.textboxes.push(
            $root.BackupTextbox.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a BackupTextboxData message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof BackupTextboxData
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {BackupTextboxData} BackupTextboxData
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  BackupTextboxData.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a BackupTextboxData message.
   * @function verify
   * @memberof BackupTextboxData
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  BackupTextboxData.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.textboxes != null && message.hasOwnProperty("textboxes")) {
      if (!Array.isArray(message.textboxes)) return "textboxes: array expected";
      for (var i = 0; i < message.textboxes.length; ++i) {
        var error = $root.BackupTextbox.verify(message.textboxes[i]);
        if (error) return "textboxes." + error;
      }
    }
    return null;
  };

  /**
   * Creates a BackupTextboxData message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof BackupTextboxData
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {BackupTextboxData} BackupTextboxData
   */
  BackupTextboxData.fromObject = function fromObject(object) {
    if (object instanceof $root.BackupTextboxData) return object;
    var message = new $root.BackupTextboxData();
    if (object.textboxes) {
      if (!Array.isArray(object.textboxes))
        throw TypeError(".BackupTextboxData.textboxes: array expected");
      message.textboxes = [];
      for (var i = 0; i < object.textboxes.length; ++i) {
        if (typeof object.textboxes[i] !== "object")
          throw TypeError(".BackupTextboxData.textboxes: object expected");
        message.textboxes[i] = $root.BackupTextbox.fromObject(
          object.textboxes[i]
        );
      }
    }
    return message;
  };

  /**
   * Creates a plain object from a BackupTextboxData message. Also converts values to other types if specified.
   * @function toObject
   * @memberof BackupTextboxData
   * @static
   * @param {BackupTextboxData} message BackupTextboxData
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  BackupTextboxData.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.textboxes = [];
    if (message.textboxes && message.textboxes.length) {
      object.textboxes = [];
      for (var j = 0; j < message.textboxes.length; ++j)
        object.textboxes[j] = $root.BackupTextbox.toObject(
          message.textboxes[j],
          options
        );
    }
    return object;
  };

  /**
   * Converts this BackupTextboxData to JSON.
   * @function toJSON
   * @memberof BackupTextboxData
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  BackupTextboxData.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, util.toJSONOptions);
  };

  return BackupTextboxData;
})();

export default $root;
